.projectContainer{
height: 60vh;
overflow-y: auto;
}


.newProjectCard{
    display: flex;
    justify-content: space-between;
    padding: 5px 0px 5px 10px;
    background: #fff;
    cursor: pointer;
    border-radius: 5px;
}

.newProjectCard:hover{
    box-shadow: 0 3px 8px #00000053;
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
    transition: .3s ease-in-out;
}

.newProjectCard>:nth-child(1){
   display: flex;
   flex-direction: column;
   gap: 10px;
   padding: 10px 0px 10px 10px;
}
.newProjectCard>:nth-child(2){
    background-color: #F3F3F3;
    border-radius: 10px;
    width: 30%;
    display: flex;
    margin-right: 5px;

}
.newProjectCard>:nth-child(2)>:nth-child(1){
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
   width: 90%;

}
.newProjectCard>:nth-child(2)>:nth-child(2){
    padding-top: 5px;
}
.projectHeader{
font-size: 1rem;
font-weight: 700;
line-height: 33px;
letter-spacing: 0em;
text-align: left;
color: #000;
}
.title1{
font-size: 13px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: #878787;
}
.title2{
font-size: 14px;
font-weight: 300;

line-height: 24px;
letter-spacing: 0em;
text-align: left;
text-align: left;

}
.taskTitle{
    font-size: 1.0rem;
    font-weight: 500;
    letter-spacing: 0em; 
    line-height: 20px;


}

.projectDetailsMain{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 20px;
}

.projectDetailsMain>*{
    display: flex;
    flex-direction: column;
    gap: 16px;
    
}
.heading1{

    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
/* line-height: 27px; */
color: #162432;
}

.graphContainer{
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    height: 360px;
    padding: 0vh 4vw;
}
.graphMain{
box-sizing: border-box;
background-color: #FFF;
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
border-radius: 15px;
padding: 20px;
gap: 20px;
}
.ListConatiner{
        background-color: #fff;
        height: 6rem;
        width: 98.5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        /* border:1px solid green; */
        margin: 0.5rem;
        padding: 0.5rem;
        
}
.ListConatiner>*{
    width: 10%;
    height: 5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
        /* border:1px solid black */
}

.ListConatiner>* >:nth-child(1){
    font-size: 14px;
    color: #878787;
    /* font-weight: 600; */
}
.ListConatiner>* >:nth-child(2){
    font-size:14px;
    color:#162432 ;
    /* border: 2px solid red; */
}
.tabContainer{
    display: flex;
    /* justify-content: center; */
    /* margin-top: 20px; */
    /* margin-bottom: 20px; */
    gap: 24px;
    color: #999999;
    font-size:1rem ;
    font-weight: 600;
    /* border:1px solid black */
}
.tabContainer>*{
    cursor: pointer;
}
.activeTab{
    color: #0086FF;
    border-bottom: 5px solid #0086FF;
    font-size: 1rem;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    
}
.projectCardContainer{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.5rem;
    row-gap: 3vh;
    padding: 5px;
}

.workingHours{
    display: flex;
    flex-direction: column;
    margin: 0 0 2rem 0;
}

.workingHours>:nth-child(2){
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    margin-top: 0.5rem;
}
.workingHours>:nth-child(2)>*:nth-child(1), .workingHours>:nth-child(2)>*:nth-child(3){
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    border-radius: 10px;
    border: 1px solid #DFDFDF;
    background: #FFF;
}
.workingHours>:nth-child(2)>:nth-child(2)
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
}
.workingHours>:nth-child(2)>:nth-child(4)
{
    display: flex;
    padding: 0.4rem 1.5rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    background: #0086FF;
    color: #fff;
}
.weekend{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: nowrap;
}
.weekend>*{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0.5rem;
   width: 50%;
   border-radius: 10px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    height: 2.2rem;
}
.ant-pagination-item {
    border-radius: 2px; /* Change this to any radius you like */
    /* border: 1px solid #d9d9d9; */
  }
  
  /* For the active page item */
.ant-pagination-item ant-pagination-item-1 ant-pagination-item-active{
  border-radius: 2px; /* Match the same border radius */
    /* background-color: #1890ff;
    border-color: #1890ff; */
  }
  /* <li title="1" class="ant-pagination-item ant-pagination-item-1 ant-pagination-item-active" tabindex="0"><a rel="nofollow">1</a></li> */
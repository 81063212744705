.attendance{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 0.5rem;
}

.AttendenceModal{
    /* position: absolute; */
    right:0;
    top:0;
    border-radius:20px 0px 0px 20px;
    /* width:30rem; */
}

.header{
    width: 100%;
}

.header h3{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    color:#0FB3FF;
}

.attendance>*>*>*:nth-child(2)>*>*>*>*>*:nth-child(1){
    font-size: 0.9rem;
}

.attendance>*:nth-child(1)>*>*:nth-child(2)>*>*>*>*>*{
    font-size: 0.9rem;
}

.attendance>*:nth-child(5)>*>*:nth-child(2)>*>*>* {
    font-size: 1rem;
}
.buttonDiv{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 1.3rem;
}

.itemTitle{
    font-size: 0.9rem;
    font-weight: 600;
}

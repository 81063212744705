
.Joined{
    color: rgba(0, 134, 255, 1) !important;
    border-radius: 15px;
    padding: 5px 10px;
    width: fit-content;
}
.Training{
    color: rgb(192, 79, 202) !important;
    border-radius: 15px;
    padding: 5px 10px;
    width: fit-content;

}
/* on job training */
.onJobTraining{
    color: rgba(255, 207, 33, 1) !important;
    border-radius: 15px;
    padding: 5px 10px;
    width: fit-content;

    /* border: 2px solid red; */
}
.Deployed{
    color: rgba(57, 204, 124, 1) !important;
    border-radius: 15px;
    padding: 5px 10px;
    width: fit-content;

}
.Released{
    color: rgba(155, 157, 162, 1) !important;
    border-radius: 15px;
    padding: 5px 10px;
    /* width: fit-content; */

}
.onNotice{
    color: rgba(253, 134, 0, 1) !important;
    border-radius: 15px;
    padding: 5px 10px;
    /* width: fit-content; */

}
.waiting-for-approval{
    padding-left: 0.5rem;
}
.Exit{
 color: rgba(255, 52, 52, 1) !important;
 border-radius: 15px;
 padding: 5px 10px;
 /* width: fit-content; */

}

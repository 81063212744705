.container{
    /* padding: 0.5rem; */
    width: 81.5%;
    height: 86vh;
    position: fixed;
}
.summaryContain{
    padding: 0.2rem 0.6rem 0.5rem 0.6rem;
    width: 81%;
    height: 85vh;
    position: fixed;
}
.summaryContain>*:nth-child(2) button{
    border-radius: 8px;
    display: flex;
    align-items: center;
}
.summaryContain>*:nth-child(2) button img{
    margin-right: 0.5rem;
}
.summaryContain>*:nth-child(3){
    /* margin-top: 1rem; */
}
.summaryContain>*:nth-child(4){
    margin-top: 0.2rem;
}
.TapDiv{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    /* border: 2px solid; */
    height: 47VH;
}
.TapDiv>*:nth-child(1){
    overflow-y: scroll;
    height: 43vh;
    flex: 0 1 55%;
    /* border: 2px solid; */
}
.TapDiv>*:nth-child(2){
    flex: 1 1 45%;
}
.TapDiv2{
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}
.TapDiv2>*:nth-child(1){
    overflow-y: scroll;
    height: 19rem;
    flex: 0 1 65%;

}
.TapDiv2>*:nth-child(2){
    flex: 1 1 45%;
}
.searchdivScroll::-webkit-scrollbar{
    display: none;
}
.scrollHoliday::-webkit-scrollbar{
    display: none;
}
@media screen and (max-width:700px){
    .container{
        width: 100%;
        padding: 0;
        background-color: #FFFFFF;
    }
    .summaryContain{
        padding: 0;
        width: 100vw;
        overflow-y: auto;
        height: 99vh;
        display:flex;
        flex-direction:column;
        background-color: #FFFFFF;
    }
}

.container>*:nth-child(1){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border: 2px solid; */
    width: 100%;
    padding: 0.4rem 1rem 0 1rem;
}
.container>*:nth-child(1) button{
    border: none;
    background: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    font-size: 0.9rem;
}
.container>*:nth-child(1) button img{
    height: 1rem;
    width: 0.9rem;
}
.container>*:nth-child(3){
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    /* margin-top: 1rem; */
    /* border: 2px solid; */
}
.extraContentTag{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;
    justify-content: space-between;
}
.extraContentTag>*:nth-child(3){
    width: 7rem;
    height: 1.8rem;
}
.extraContentTag input{
    width: 19rem;
    font-size: 0.9rem;
}
.dateTag{
    width: 8.5rem;
}
.container>*:nth-child(4){
    margin-top: 1rem;
}
.ant-table td { 
    white-space: nowrap; 
}
.nameRow{
    display: flex;
    flex-direction: row;
    color: #0086FF;
    gap: 0.1rem;
    cursor: pointer;
}
.nameRow>*:nth-child(2){
    display: flex;
    flex-direction: column;
}
.modalHeader{
    /* display: flex; */
    /* flex-direction: row; */
    /* width: 100%; */
    /* justify-content: flex-start; */
}
.modalHeader>*:nth-child(1){
    color: #0086FF;
}
.modalBody{
    /* display: flex;
    border: 2px solid;
    align-items: center;
    flex-direction: column;
    justify-content: center; */
    /* margin-left: 1-rem; */
}
.modalBody>*:nth-child(1){
  /* margin-left: -1vw; */
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  /* color: #0086FF; */
}
.formBody{
    overflow-y: scroll;
    height: 61vh;
    margin-bottom: 1rem;
    width: 57vw;
}
.NoteInput{
    height: 5rem;
}
.buttonDesign{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 1rem;
    margin-bottom: 1rem;
}
.buttonDesign>*:nth-child(1){
    width: 5.5rem;
    height: 2rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.buttonDesign>*:nth-child(2){
    background-color: white;
    width: 5.5rem;
    height: 2rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.label{
    font-weight: 600;
    font-size: 0.9rem;
}
.tabtag{
    /* color: #999999; */
    font-weight: 600;
    font-family: poppins ;
}
.tabHeader{
    text-align: center;
}
.tabBody{
    text-align: center;
}
.cardsDiv{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 0.5rem;
}
.cardList{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: nowrap;
    max-width: 78vw;
    overflow-x:auto;
    scroll-behavior: smooth;
}
.cardList::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
    height: 3px;
    display: none;
  }
.cardList::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white; 
    border-radius: 10px;
  }
.cardList::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
    height: 3px;
  }
.buttonsDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-right: 1rem; */
}
.buttonsDiv>*:nth-child(1){
    border: none;
    background: none;
}
.cardList button{
    border: 0;
}
.cards{
    display: flex;
    height: 14vh;
    margin-bottom: 0.2rem;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.cards>*:nth-child(1){
    width: 17vw;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
    background-color: #FFFFFF;
    margin-top: 0.5rem;
}
.cards>*:nth-child(1)>*:nth-child(2)>*:nth-child(1){
    /* margin-top: 1rem; */
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.cards>*:nth-child(1)>*:nth-child(2)>*:nth-child(1)>*:nth-child(1){
    font-size: 0.96rem;
    font-weight: 590;
    color: #000000;
}
.cards>*:nth-child(1)>*:nth-child(2)>*:nth-child(1)>*:nth-child(2){
    font-weight: 550;
    font-size: 0.9rem;
}
.cards>*:nth-child(1)>*:nth-child(2)>*:nth-child(1)>*:nth-child(3){
    font-weight: 600;
    font-size: 0.9rem;
}
.cardsTag{
    /* height: 8vh; */
    margin-top: 1rem;
}
.associateCard{
    display: flex;
    flex-direction: row;
    padding: 0;
    width: 100%;
    gap: 2rem;
    justify-content: space-between;
}
.associateCard>*{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.associateCard>*:nth-child(1){
    display: flex;
    flex: 0 1 30%;
    margin-left: 1rem;
    /* border: 2px solid; */
}
.associateCard>*:nth-child(2){
    display: flex;
    flex: 0 1 50%;
    margin-right: 1rem;
    /* border: 2px solid; */
}
.associateCard2{
    display: flex;
    flex-direction: row;
    padding: 0;
    width: 100%;
    gap: 2rem;
    justify-content: space-between;
}
.associateCard2>*{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.associateCard2>*:nth-child(1){
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}
.associateCard2>*:nth-child(2){
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}
.associateCard2>*:nth-child(3){
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}
.associateCard2 button{
    border-radius: 60px;
    border: none;
    width: 2.3rem;
    height: 2.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F1F1F1;
    margin-top: -0.3rem;
}
.detailLeaves{
    /* border: 2px solid blue; */
}
.detailLeaves>*:nth-child(1){
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.detailLeaves>*:nth-child(1) img{
    height: 22.5vh;
    width: 11vw;
}
.detailLeaves>*:nth-child(1)>*:nth-child(2){
    display: flex;
    flex-direction: column;
    gap: 0.8vh;
}
.detailLeaves>*:nth-child(2){
    display: flex;
    flex-direction: column;
    gap: 1vh;
    margin:  0.6rem 0.3rem 0 0.2rem;
}
.detailLeaves>*:nth-child(2)>*{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.95rem;
}
.selectDiv{
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-right: 36rem;
}
.selectDiv>*:nth-child(1){
    width: 9.5rem;
    height: 2.4rem;
}
.ListBodyContainer::-webkit-scrollbar{
    display: none;
  }
  .ListBodyContainer{
    width: 100%;
  }
  .rowContainer{
    display: flex;
    width: 100%;
    justify-content: center;
    /* background-color: white */
    margin-bottom: 0.6rem;
    border-radius: 5px;
    /* padding-left: 1rem; */
    /* padding-right: 1rem; */
    height: 4rem;
    box-shadow: 0 2px 6px rgba(0, 0,0,0.2);
  }
  .rowContainer>*{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ListHeader{
    width: 100%;
    /* padding-left: 1rem; */
  }
  .ListHeader>*{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ListContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* border: 2px solid; */
    padding: 0 0.4rem;
    /* margin-top: 0.5rem; */
    background-color: #F9F9F9;
  }
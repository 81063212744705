.addTeamModal{
    display: flex;
    justify-content: center;
    flex: 1;
    flex-direction: column; 
    margin: 0rem;
    width: 97%;
    overflow-y: auto;
    max-height: 80vh;
}

.addTeamModal>*>*>*>*:nth-child(2)>*>*:nth-child(1)>*>*:nth-child(1){
    border: 1.3px solid #ECECEC;
}

.buttonDiv{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 1rem;


}

.modalHeader{

    display: flex;
    width: 100%;
    flex-direction: row;
   
    color:#01070c;

    font-size: 1.1rem;
}
.addTeamModal{
    display: flex;
    justify-content: center;

    flex-direction: column; 
    width: 100%;
    /* padding: 0rem 2rem 1rem 2rem; */
    /* overflow-y: scroll;
    max-height: 84vh; */
}

.buttonDiv{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 1rem;

}

.modalHeader{
    width: 100%;
    font-size: 1.1rem;
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
    color: #000509;
}

@media(max-height:700px){
    .addTeamModal{
      
    }

}
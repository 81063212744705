.mainThankYoupage{
    position: fixed;
    height: 100%;
    width: 100%;
    padding: 40px;
}
.ThankYouContainer{
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
@media screen and (width>=700px) {
  .modal{
    box-sizing: border-box;
    background: white;
    display: flex;
    align-items: center;
    transition: 0.5s ease-out;
    position: relative;
    top: 100px;
    margin: 0 auto;
    padding: 15px 10px;
    flex-direction: column;
    border-radius: 10px;
  }
}
@media screen and (width<700px) {
  .modal{
    box-sizing: border-box;
    background: white;
    display: flex;
    align-items: center;
    transition: 0.5s ease-out;
    position: relative;
    top: 50px;
    bottom: 50px;
    margin: 0 auto;
    padding: 15px 10px;
    flex-direction: column;
    border-radius: 10px;
  }
}
.maskModal{
    background-color: rgba(0,0,0,.45);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
}
.ModalWrap{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

  .modalContent{
    padding: 20px;
    width: 100%;
    justify-content: center;
    display: flex;
  }
  .closeBtn{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    cursor: pointer;
  }

  .header{
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .header>*:nth-child(1){
    flex: 1 1 5%;
    display: flex;
    align-items: center;
    width: 1.1rem;
  }

  .header>*:nth-child(1) img{
   width: 1rem;
   height: 1rem;
  cursor: pointer;
  }

  .header>*:nth-child(1)>*{
   display: flex;
   align-items: center;
   }

   .header>*:nth-child(3)>*{
    display: flex;
    align-items: center;
    width: 1.1rem;
    }

  .header>*:nth-child(2){
    flex: 1 1 88%;
    display: flex;
    justify-content: center;
  }

  .header>*:nth-child(2) h2{
 font-size: 23px;
 font-weight: 600;
  }



  .header>*:nth-child(3)
  {
    display: flex;
    flex-direction: 1 1 8%;
    align-items: center;
  }
  .header>*:nth-child(3) img{
    width: 1rem;
    height: 1rem;
    cursor: pointer;
   }
.container{
    display: flex;
    width: 100%;
    height: 90vh;
}
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
.sider{
    width: 18%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 90vh;
    gap: 3rem;
    align-items: center;
    /* justify-content: space-between; */
    padding: 1.5rem 0;
    border-right: 1px solid #DFDFDF;
}
.sider>*:nth-child(1){
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 90%;
}

.sider>*:nth-child(1)>*:nth-child(1), .sider>*:nth-child(1)>*:nth-child(2){
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    padding: 0.7rem 1rem;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    border-radius:10px ;
    width: 100%;
  
}
.activeModule{
    background-color: #DBF4FF;
    color: #0086FF;
    border-right:5px solid #0086FF ;
}

.sider>*:nth-child(2){
  margin-top: auto;
}

.sider>*:nth-child(1) img{
    width: 2rem;
    height: 2rem;
}



.hideModules{
    display: none;
}
.showModules{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: 2rem;
}
.showModules>:nth-child(1){
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.showModules>:nth-child(1)>*{
    cursor: pointer;
    font-size: 0.85rem;
    font-weight: 500;
}
.activeSubModule{
    padding: 0.3rem 1rem;
    background-color: #DBF4FF;
    color: #0086FF;
}


.header{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #dfdfdf;
}
.header>:nth-child(1){
    font-size: 1.3rem;
    font-weight: 600;
}

.tabDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 2.5rem;
    margin-right: 1rem;
    gap: 1rem;
}
.tabDiv>:nth-child(1)
{
    order: 1;
}
.tabDiv>:nth-child(2)
{
    order: 2;
}
.tab{
    /* border: 2px solid red; */
    display: flex;
    gap: 1rem;
    font-weight: 600;
    color: #ABABAB;
    padding: 0;
    height: 2rem;
}
.tab>*{
    cursor: pointer;
}
.activeTab{
    color: #0086FF;
    font-weight: 700;
    font-size: 1.1rem;
    border-bottom: 2px solid #0086FF;
}


.tabDiv>*:nth-child(2){
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    /* border: 2px solid red; */
}
.tabDiv>*:nth-child(2){
    display: flex;
    flex-direction: row;
    gap: 0rem;
    justify-content: center;
    align-items: center;
   height: 2.4rem;
   padding: 0 1rem;
    border-radius: 5px;
    border: 1px solid #BEBEBE;
    background: #FFF;
    width: 20rem; 
}



.mainContent{
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
}
.inputDiv input:hover{
    border: none;
    outline: none;
    box-shadow: none
}
.inputDiv input:focus {
    border: none;
    outline: none;
    box-shadow: none
}

.des>:nth-child(1){
    font-size: 1.2rem;
     color: #525252;
     font-weight: 600;
}

.des p{
    font-size: 1.1rem;
    font-family: 'Lato';
    color: #525252;
    line-height: 1.5rem;
}

.cards{
    height: 3rem;
    margin:1rem 2rem 1rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    background-color: #f8f8f8;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1rem;
}




.footer span{
    font-size: 14px;
    font-weight: 400;
}
/* 
@media(max-height:700px) and (min-height:600px)
{
    .des p{
        font-size: 0.7rem; 
    }
    .cards span{
        font-size: 0.7rem; 
    }
    .footer span{
        font-size: 0.7rem;
    }
    .cards{
        height: 2.5rem;
    }
    
}

@media(max-height:800px) and (min-height:700px)
{
    .des p{
        font-size: 0.8rem;
    }
    .cards span{
        font-size: 0.8rem; 
    }
    .footer span{
        font-size: 0.8rem;
    }
    .cards{
        height: 2.5rem;
    }

    
} */

.forward{
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 600;
}

.forward:hover{
    color: #0086FF;
    animation: forwards-animate 0.4s linear 1 forwards ;
}

@keyframes forwards-animate {
    0%{
        transform: scale(1);
    }
    100%{
        transform: scale(1.08);
    }
}

.socialMedia{
    width: 1rem;
    height: 1rem;
    cursor: pointer;
}

.socialMedia:hover{
    animation: social-icon 0.5s linear  1 forwards ;
}
@keyframes social-icon {
    0%{
        transform: scale(1);
    }
    100%{
        transform: scale(1.5);
    }
    
}

.socialDiv{
    height: 2rem;
    width: 15vw;
    display: flex;
    margin-right: 3rem;
}

.socialDiv>*{
    width: 25%;
    display: flex;
    justify-content: right;
    align-items: flex-end;
}

.thanks{
    display: flex;
    flex-direction: column;
    font-size: 15px;
    /* font-weight: 500; */
    font-family: 'Lato';
    margin:1rem 0.5rem;
}

.ceoinfo{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    margin-bottom: 1.5rem;
    background-color: #f8f8f8;
}

.textcontent{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
    gap: 1rem;
    padding: 1rem;
}

.image{
    position: relative;
    width: 30%;
    display: flex;
}
.footerDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}

.ceoImage:hover{
    animation: ceoImage-animate 0.8s linear  1 forwards ;
}
@keyframes ceoImage-animate {
    0%{
        transform:scale(0.98);  
    }
   100%{
        transform:scale(1);
    }
    
}


@media(max-width:800px)
{
    .sider{
        width: 100%;
        border: 0;
        height: 80vh;
    }
    .mainContent
    {
        width: 100%;
    }
    .tabDiv{
        flex-direction: column;
        height: auto;
    }
    .tabDiv>:nth-child(1)
    {
        order: 2;
    }
    .tabDiv>:nth-child(2)
    {
        order: 1;
    }
    .ceoinfo{
        flex-direction: column-reverse;
    }
    .textcontent{
        width: 100%;
    }
    .image{
        width: 100%;
        padding:1rem;
    }
    .cards{
        height: auto;
        margin:1rem;
        flex-direction: column;
        padding: 1rem;
    }
    .cards>*:nth-child(1){
    min-width: 10rem;
    }
    .footerDiv{
        flex-direction: column;
    }
    .footer{
        display: flex;
        justify-content: space-between;
        margin: 0 1rem;
    }
    .socialDiv{
        width: 100%;
        margin-top: 1rem;
    }
    .header{
        justify-content: center;
        flex-direction: column;
    }
    .header>*{
        width: 100%;
        padding: 0;
    }
    .header>*:nth-child(1){
        text-align: center;
    }
}
.dashboard{
    display: flex;
    flex-direction: column;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    height: 100%;
}
.header{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-bottom: 1px solid #dfdfdf;
    background-color: #fff;
}
.header>:nth-child(1){
    font-size: 1.4rem;
    font-weight: 600;
}
.tab{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}
.tab>*:nth-child(1){
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    color: #A0A0A0;
    font-weight: 550;
    height: 2.2rem;
}
.tab>*:nth-child(1)>*{
    cursor: pointer;
}
.activeTab{
    color: #0086ff;
    border-bottom: 2px solid #0086ff;
}

.tab>*:nth-child(2){
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.deptScroll{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1rem; 
    width: 100%;
    margin-top: 1rem;
}
.deptScroll>:nth-child(1){
    flex: 1 1 2%;
    display: flex;
    justify-content: right;
    cursor: pointer;  
}
.deptScroll>:nth-child(2){
    flex: 1 1 96%;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: nowrap;
    padding: 0.5rem;
    /* border: 2px solid red; */
}

.deptScroll>:nth-child(2)::-webkit-scrollbar{
    display: none;
}
.deptScroll>:nth-child(3){
    flex: 1 1 2%;
    cursor: pointer;
}
.card{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #18181812;
    border-radius: 10px;
    gap: 0.3rem;
    min-width: 16rem;
    max-width: 16rem;
    height: 7rem;
    /* border: 2px solid red; */
}
.card>:nth-child(1){
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    color: #9B9B9B;
    justify-content: space-between;
}
.selected{
    border: 1.5px solid #0086ff;
}

.members{
    font-size: 1.5rem;
    font-weight: 600;
}
.extraInfo{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.extraInfo>*{
    display: flex;
    align-items: center;

}
.contents{
    display: flex;
   padding: 1rem;
   width: 100%;
   overflow-y: auto;
   height: 55vh;
   box-sizing: border-box;
}

.graphDiv{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(20rem,1fr));
    gap: 1rem;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
}

.eachGraph{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.spin{
    display: flex;
    height: 80vh;
    align-items: center;
    justify-content: center;
}

.spin2{
    display: flex;
    height: 40vh;
    align-items: center;
    justify-content: center;
}

@media(max-width:700px)
{
    .tab{
        flex-direction: column;
    }
    .tab>:nth-child(1){
        width: 100%;
    }
    .card{
        width: 72vw;
        min-width: 72vw;
        /* border: 2px solid red; */
    }
}
.feed{
    width: 100vw;
    height: 90vh;
    display: flex;
    overflow-y: auto;
  flex-direction: column;
    padding: 4vmin 4vmin 1vmin 4vmin;
   
}
.feed>:nth-child(1){
    display: flex;
    gap: 2vw;
}
.section1{
    display: flex;
    width: 65%;
    height: min-content;
    flex-direction: column;
    gap: 1vmin;
}

.section2{
    display: flex;
    width: 35%;
    height: min-content;
    flex-direction: column;
}
.section1>:nth-child(1){
    font-size: 1.4rem;
    font-weight: 600;
    color: #191919;
}

.section1>:nth-child(2){
    font-size: 0.8rem;
    font-weight: 500;
    color: #3D3D3D;
    margin-bottom: 2vmin;
}

.cultureEvents{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1vw;
    align-items: center;
    /* border: 2px solid red; */
}

.cultureEvents div{
    background-color: #fff;
    padding: 2vmin;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 2vmin;
   cursor: pointer;
}
.cultureEvents div>:nth-child(2){
    font-size: 0.9rem;
    font-weight: 600;
}

.cultureEvents div img{
    width: 100%;
    height: 10rem;
    object-fit: cover;
}

.viewAll{
    width: 9rem;
    height: 2.5rem;
    color: #fff;
    background-color: #1089FF;
    cursor: pointer;
    border-radius: 0.4rem;
    border: 0;
    outline: 0;
    margin: 2vmin auto;
}

.proficiency{
    background-color: #fff;
    padding: 2vmin;
    /* align-items: center; */
    display: flex;
    gap: 1vmin;
    flex-direction: column;
    border-radius: 0.5rem;
}

.proficiency>:nth-child(1){
    font-size: 1rem;
    font-weight: 600;
}

.proficiency>:nth-child(2){
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5vw;
}
.proficiencyItem{
    display: flex;
    flex-direction: column;
}
.proficiencyItem>:nth-child(1){
    width: 100%;
    height: 12rem;
}

.visitWebButton{
    width: 15rem;
    height: 2.5rem;
    color: #fff;
    background-color: #1089FF;
    cursor: pointer;
    border-radius: 0.4rem;
    border: 0;
    outline: 0;
    font-size: 0.9rem;
    margin: 2vmin auto;
}

.shorts{
    display: flex;
    flex-direction: column;
    gap: 2vmin;
    margin-top: 4vmin;
}
.shorts>:nth-child(1){
    display: flex;
    gap: 0.7vw;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 600;
    color: #191919;
}

.shorts>:nth-child(1) img{
    width: 4vmin;
    height: 4vmin;
}

.shortItems{
    display: flex;
    gap: 2vw;
    align-items: center;
    overflow-x: scroll;
    max-width: 60vw;
}

.eachShort{
    height: 45vmin;
   min-width: 19vw;
   max-width: 19vw;
    position: relative;
    display: flex;
    flex-direction: column;
    /* background: #969696 0% 0% no-repeat padding-box; */
    border-radius: 6px;
    cursor: pointer;
    overflow-x: hidden;
}

.eachShort img{
    height: 100%;
    width: 100%;
    filter: brightness(70%);
    border-radius: 0.5rem;
    object-fit: cover;
}
.eachShort>:nth-child(2){
    position:absolute;
    bottom: 2vmin;
    display: flex;
    flex-direction: column;
    gap: 1vmin;
    font-weight: 600;
    font-size: 1.8vmin;
    color: #fff;
    padding: 0 1vw;
    /* background-color: rgba(54, 45, 45, 0.2); */
    
}
.eachShort>:nth-child(2)>:nth-child(2){
    font-size: 1.5vmin;  
}
.eachShort>:nth-child(2)>*{
    text-shadow:0px 4px 8px rgb(7, 7, 7)
}

.newsletter{
    display: flex;
    flex-direction: column;
    gap: 1vmin;
    margin-top: 3vmin;
}
.newsletter>:nth-child(1){
    font-size: 1.4rem;
    font-weight: 600;

}

.newsletterItems{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2vw;  
}

.eachNews{
    display: flex;
    flex-direction: column;
    gap: 1vmin;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    padding: 2vmin;
    height:100%;
    cursor: pointer;
}
.eachNews>:nth-child(1){
    display: flex;
    height: 20vmin;
}
.eachNews>:nth-child(1) img{
    display: flex;
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
}
.eachNews>:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.6rem;
    color: #808080;
    font-weight: 550;
}

.eachNews>:nth-child(3){
    color: #191919;
    font-size: 0.9rem;
    font-weight: 600;
   
}
.eachNews>:nth-last-child(1){
    font-size: 0.7rem;
    text-align: justify;

}

.videos{
    display: flex;
    flex-direction: column;
    gap: 1vmin;
}
.videos>:nth-child(1){
    display: flex;
    align-items: center;
    gap: 0.6vw;
    font-size: 1.4rem;
    font-weight: 600;
    color: #191919;
}
.videos>:nth-child(1) img{
    width: 4vmin;
    height: 4vmin;
}

.videoItems{
    display: flex;
    flex-direction: column;
    gap: 2vmin;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 0.5rem;
    padding: 2vmin;
    max-height: 22rem;
    overflow-y: auto;
}

.eachVideo{
    display: flex;
    gap: 2vw;
    align-items: center;
    cursor: pointer;
}
.eachVideo>:nth-child(1){
    display: flex;
    width: 50%;
    height: 6rem;
    border-radius: 0.5rem;
}
.eachVideo>:nth-child(1) img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
}
.eachVideo>:nth-child(2){
    display: flex;
    width: 50%;
    flex-direction: column;
}
.eachVideo>:nth-child(2)>:nth-child(1){
    font-size: 1.8vmin;
    font-weight: 600;
}
.eachVideo>:nth-child(2)>:nth-child(2){
    font-size: 0.7rem;
    font-weight: 600;
    display: flex;
    align-items: center;
  gap: 2vw;
    color: #808080;
}

.ceoMessage{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    padding: 2vw;
    gap: 2vw;
    margin-top: 2vmin;
    cursor: pointer;
}
.lidarCard{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vw;
    margin-top: 2vmin;
}
.ceoMessage>:nth-child(1){
    display: flex;
    width: 35%;

}

.ceoMessage>:nth-child(1) img{
    width: 100%; 
    object-fit: cover;
}
.ceoMessage>:nth-child(2){
    display: flex;
    width: 65%;
    flex-direction: column;
    gap: 1vmin;
}
.ceoMessage>:nth-child(2)>:nth-child(1){
    font-size: 1rem;
    font-weight: 600;
}
.ceoMessage>:nth-child(2)>:nth-child(2){
    font-size: 0.7rem;
    font-weight: 500;
    color: #3D3D3D;
    text-align: justify;

}

.magazine{
    display: flex;
    flex-direction: column;
    gap: 2vmin;
    margin-top: 4vmin;
}
.magazine>:nth-child(1){
    font-size: 1.4rem;
    font-weight: 600;
}
.magazine>:nth-child(2){
    display: flex;
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 2vmin 5vmin;
    
}
.magazine>:nth-child(2) img{
   width: 100%;
   border-radius: 0.5rem;
   /* height: 40vmin; */
   cursor: pointer;
}
.signup{
    display: flex;
    align-items: center;
    gap: 2vw;
    background: #EAF4FF 0% 0% no-repeat padding-box;
    border-radius: 0.5rem;
    padding: 2vmin;
    margin-top: 4vmin;
}
.signup>:nth-child(1){
    display: flex;
    width: 35%;
}
.signup>:nth-child(1) img{
   width: 100%;
}
.signup>:nth-child(2){
    display: flex;
    width: 65%;
  /* align-items: center; */
 flex-direction: column;
    gap: 1vmin;
}
.signup>:nth-child(2)>:nth-child(1){
    font-size: 1rem;
    font-weight: 600;
}

.signup>:nth-child(2):nth-child(2){
    font-size: 0.85rem;
    font-weight: 500;
    color: #3D3D3D;
}

.spin{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
}
.hiringStatus{
    display: flex;
    flex-direction: column;
    gap: 1vmin;
    font-size: 0.9rem;
    font-weight: 500;
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 2vmin;
    margin-bottom: 4vmin;
}
.hiringStatus>:nth-child(1){
    display: flex;
    align-items: center;
    gap: 0.5vw;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1vmin;
}
.hiringStatus>:nth-child(1) img{
    width: 1.5rem;
    height: 1.5rem;
}
.hiringStatus>:nth-child(2){
    display: flex;
    gap: 0.5vw;
    max-height: 20rem;
    overflow-y: auto;
    padding-right: 0.5rem;
}
.status{
    display: flex;
    flex-direction: column;
}
.status>*>:nth-child(1), .status>*>:nth-child(8)
{
    color: #808080;
    font-size: 0.9rem;
}
.status>*>:nth-child(1){
    color: #26DE81;
}
.status>*>:nth-child(2){
    color: #3D3D3D;
    font-size: 0.8rem;
    margin-bottom: 1vmin;
}

/* .statusBar{
    display: flex;
    width: 10vmin;
    height: min-content;
    border: 2px solid red;
} */
.hiringStatus>:nth-child(2)>*:nth-child(1){
  justify-content: center;
    width: 10vmin;
    align-items: center;
}
.hiringStatus>:nth-child(2)>*:nth-child(1)>*{
    margin: 0 auto;
}

.footer{
    display:flex;
    align-items: center;
    width: 100%;
    margin-top: 2vmin;
    justify-content: space-between;
    border-top: 1px solid #dfdfdf;
    padding: 2vmin 0;
}
.footer>:nth-child(1){
    display: flex;
    align-items: center;
    gap: 0.8rem;
}
.footer>:nth-child(1) img{
    width: 2vmin;
    height: 2vmin;
    cursor: pointer;
}
.footer>:nth-child(1) span{
    font-size: 1.8vmin;
    font-weight: 500;
    color: #3D3D3D;

}
.footer>:nth-child(2){
    font-size: 1.8vmin;
    font-weight: 500;
    color: #3D3D3D;
}


@media(max-width:700px)
{
    .footer>:nth-child(1) span{
        font-size: 3vmin;
        font-weight: 500;
        color: #3D3D3D;
    
    }
    .footer>:nth-child(1) img{
        width: 3.5vmin;
        height: 3.5vmin;
        cursor: pointer;
        object-fit: cover;
    }
    .feed>:nth-child(1){
        flex-direction: column;
        gap: 2vw;
    }
    .section1{
        width: 100%;
    }
    
    .section2{
        width: 100%;
    }
    .cultureEvents{
        grid-template-columns: repeat(1,1fr);
    }
    .proficiency>:nth-child(2){
       flex-direction: column;
       gap: 2rem;
       margin-top: 2rem;
    }
    .proficiency{
        margin-top: 2rem;
    }
    .shorts{
        margin-top: 2rem;
    }
    .shortItems{
     max-width: 100%;
     gap: 1rem;
    }
    .eachShort{
        height: 50vh;
       min-width: 100%;
       max-width: 100%;
    }
    .eachShort>:nth-child(2){ 
        font-size: 1.1rem;     
    }
    .eachShort>:nth-child(2)>:nth-child(2){
        font-size: 0.85rem;  
    }

    .newsletter{
       margin: 2rem 0;
    }
    
    .newsletterItems{
        grid-template-columns: repeat(1,1fr);
        gap: 1rem;  
    }
    .eachNews>:nth-child(1){
        height: 12rem;
    }
    .eachNews>:nth-child(2){
        font-size: 0.8rem;
    }
    
    .eachNews>:nth-child(3){
        font-size: 1rem;
    }
    .eachNews>:nth-last-child(1){
        font-size: 0.8rem;
    }
    .videos{
        margin: 2rem 0;
        gap: 1vmin;
    }

    .magazine{
      margin: 2rem 0;
    }
    .magazine>:nth-child(2) img{
       /* height: 60vmin; */
       object-fit: contain;
    }
    

}
.autheticationTable ::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.autheticationTable ::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}

/* Handle */
.autheticationTable ::-webkit-scrollbar-thumb {
  background: rgb(192, 190, 190);
  border-radius: 10px;
}

/* Handle on hover */
.autheticationTable ::-webkit-scrollbar-thumb:hover {
  background: #747474;
}

.divBtnStyle {
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  font: normal 400 16px/21px Poppins;
  align-items: center;
}
.tableAction {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 140px;
  gap: 10px;
  cursor: pointer;
}
.tableRow {
  background-color: #fcfcfc;
  width: 98%;
}

.confirmationModalMainDiv {
    width: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  padding: 0;
  margin: 0;
  /* justify-content: center; */
  /* align-items: center; */
  /* gap: 1rem; */
  /* border:1px solid black */
}

h5 {
  font-weight: 200;
}

.tableRowWhite{
  background-color: #fff;
  color: #162432;
  font-weight: 500;
  font-size: 13px;
}

.tableRow{
  background-color: #F9F9F9;
  color: #162432;
  font-weight: 500;
  font-size: 13px;
} 
  .ListHeader{
    width: 100%;
  }
  .ListHeader>*{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* color: #162432; */
    /* font: normal 600 16px/22px lato; */
}
.ListContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-right: 0.6rem;
    padding-left: 0.6rem;
    background-color: #F9F9F9;
    margin-top: 0.5rem;
  }
  .ListBodyContainer::-webkit-scrollbar{
    display: none;
  }
  .ListBodyContainer{
    width: 100%;
  }
  .rowContainer{
    display: flex;
    width: 100%;
    /* justify-content: center; */
    padding-left:5px ;
    background-color: white;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    height: 5rem;
    box-shadow: 0 2px 6px rgba(0, 0,0,0.2);
  }
  .rowContainer>*{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* font: normal 600 16px/22px lato; */
  }
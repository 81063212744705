.modalContainer{
    display: flex;
    flex-direction: column;
    width: 47vw;
    max-height: 75vh;
    overflow-y: auto;
    gap: 1rem;
    margin: 1rem 1rem 1rem 1rem;
    min-height: 53vh;
}

.infoDiv{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 1rem;
}

.nameDiv{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font: normal 600 16px/22px lato;
}
.nameDiv:not(.nameDiv>:nth-child(1)){
    font-size: 0.9rem;
    font-weight: 500;
}
.nameDiv>:nth-child(2){
    color: #696969;
}
.nameDiv>:nth-child(3){
    color: #222222;
}
.nameDiv>:nth-child(1){
   font-size: 1.3rem;
   font-weight: 600;
}
.basicInfo{
    display: flex;
    flex-direction: column;
    width: 60%;
    gap: 0.5rem;
  
}
.eachInfo{
    display: flex;
    width: 100%;
    font-size: 0.9rem;
    font-weight: 500;
}
.eachInfo>:nth-child(1){
    flex: 1 1 40%;
    color: #696969;
}
.eachInfo>:nth-child(2){
    flex: 1 1 60%;
    color: #222222;
    word-break: break-word;
}
.datePicker{
    display: flex;
    justify-content: right;
}

.questionDiv{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: #f8f8f8;
}

.eachQuestion{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #18181812;
    border-radius: 10px;
    padding: 1rem;
    flex-direction: column;
    gap: 0.5rem;
    display: flex;
    color: #222222;
    font-size: 0.9rem;
    font-weight: 500;
}

@media(max-width:700px)
{
    .modalContainer{
        width: 83vw;
        margin: 0;
    }
    .infoDiv{
        flex-direction: column;
    }
    .basicInfo{
        width: 100%;
    }
    .nameDiv{
        width: 100%;
    }
}




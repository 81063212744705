.container{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 1.5rem;
    max-height: 70vh;
    overflow-y: auto;
    width: 55vw;
}

.buttonDiv{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
}

.itemRender{
    display: flex;
    justify-content: space-between;
    min-width: 20rem;
    flex-grow: 1;
    gap:2rem;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    border-radius: 10px;
    background: #DBF3FF;
}
.itemRender>*:nth-child(1){
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}

.itemRender>*:nth-child(1)>*:nth-child(2){
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.itemRender>*:nth-child(1)>*:nth-child(2)>*:nth-child(2){
    font-size: 10px;
}

.itemRender>*:nth-child(2){
    display: flex;
    align-items: center;
}


@media(max-width:800px){
    .container{
        width: 78vw;
    }
}

.card_banner{
    width: 100%;
    /* height: 25vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding:16px 20px;
}

.associateStatus{
  display: flex;
  justify-content: space-between;
  padding: 10px;

}


.cards_container{
    display: flex;
    gap: 2rem;
    width: 80vw;
    overflow-x: auto;
    padding: 5px 0px;
    scroll-behavior: smooth;
}

.cards_container::-webkit-scrollbar {
    width: 5px;
    height: 0%;
  }
  
  .cards_container::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  

  .cards_container::-webkit-scrollbar-thumb {
    background: rgb(192, 190, 190); 
    border-radius: 10px;
  }
  
  .cards_container::-webkit-scrollbar-thumb:hover {
    background: #747474; 
  }




.dashBody ::-webkit-scrollbar{
  width: 5px !important;
  border-radius: 10px;
}

@media screen and (width>=700px) {
  .circleContainer{
    padding: 16px;
  }
  .recruitmentHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    /* margin-bottom: -3rem; */
  }
  .statusHeader{
    display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
  }
  .cards_container>*{
    min-width: 16.3vw;
    /* border: 2px solid; */
}
.associateStatus{
  display: flex;
  justify-content: space-between;
  padding: 10px;

}
}
@media screen and (width<700px) {
  .circleContainer{
    padding: 10px;
  }
  .recruitmentHeader{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    /* margin-bottom: -1rem; */
  }
  .cards_container>*{
    min-width: 70vw;
    /* border: 2px solid; */
}
.statusHeader{
  display: flex;
  flex-direction: column;
  gap: 10px;

}
.associateStatus{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  padding: 10px;
  row-gap: 10px;
  column-gap: 10px;

}
}

@media screen and (width<300px) {
  .circleContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
  }
}
@media screen and ( height>=900px) and (height<1100px) {
  .GraphHeight{
    height: calc(45vh + 100px);
  }
  .statusGraphHeight{
    height: calc(45vh + 40px);
  }
  .dashBody{
    height: calc(80vh + 50px);
    overflow-y: scroll;
    background-color: #f8f8f8;
  }
}
@media screen and ( height>=750px) and (height<900px) {
  .GraphHeight{

    height: calc(50vh + 100px);
  }
  .statusGraphHeight{
    height: calc(45vh + 40px);
  }
  .dashBody{
    height: calc(71vh + 70px);
    overflow-y: scroll;
    background-color: #f8f8f8;
  }
}
@media screen and ( height>=600px) and (height<750px) {
  .GraphHeight{

    height: calc(65vh + 100px);
  }
  .statusGraphHeight{
    height: calc(45vh + 40px);
  }
  .dashBody{
    height: calc(70vh + 60px);
    overflow-y: scroll;
    background-color: #f8f8f8;
  }
}
@media screen and (height<600px) {
  .GraphHeight{

    height: calc(70vh + 100px);
  }
  .statusGraphHeight{
    height: calc(45vh + 40px);
  }
  .dashBody{
    height: calc(70vh );
    overflow-y: scroll;
    background-color: #f8f8f8;
  }
}

  



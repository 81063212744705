.LableConatiner{

    margin-top: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    font:normal 400 16px/20px Lato;
    padding-bottom: 1.5rem;
}
.LableConatinerFile{
    margin-top: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    font:normal 400 16px/20px Lato;
    /* background: #f8f8f8; */
}
.FileCard{
display: flex;
flex-direction: column;
align-items: center;
padding: 10px;
/* background: #fff; */
}
.FilePDF{
cursor: pointer;
height: 120px;
width: 120px;
background: #e8e8e8;
}
.FileImage{
    height: 120px;
    width: 120px;
}
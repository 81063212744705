.header{
   display: flex;
   justify-content: space-between;
   align-items: center;
   height: 10vh;
   box-sizing: border-box;
   border-bottom: 1px solid #dfdfdf;
   background-color: #fff;
   padding: 0 3vw;
   box-shadow: 2px 2px 16px #00000014;
   /* margin-bottom: 1px; */
}

.header>:nth-child(1){
   width: 16vmin;
   height: 8vmin;
   
}

.header>:nth-child(2){
   height: max-content;
   /* border: 2px solid red; */
   display: flex;
   align-items: center;
   gap: 2vw;
}
.header>:nth-child(2)>*{
   display: flex;
   gap: 0.3vw;
   align-items: center;
   cursor: pointer;
}

.header>:nth-child(2)>*>:nth-child(1){
  width: 2vmin;
  height: 2vmin;
}
.header>:nth-child(2)>*>:nth-child(2){
   font-size: 2vmin;
   color: #3D3D3D;
   font-weight: 500;
 }

 .header>:nth-child(2)>* .activeTab{
   color: #1089FF;
 }

 .mobHeader{
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin: 1rem;
   height: 10vh;
   /* margin-bottom: 10vh; */
 }
 .mobHeader img{
   width: 5rem;
   height: 3rem;
 }

 .sider{
   width: 100%;
   display: flex;
   flex-direction: column;
   background-color: #fff;
   height: 70vh;
   gap: 3rem;
   /* align-items: center; */
   /* justify-content: space-between; */
   padding: 1.5rem 0;
   border-right: 1px solid #DFDFDF;
   padding:1rem 1rem 0 1rem;
  
}

.sider>*:nth-child(1){
   display: flex;
   flex-direction: column;
   gap: 1rem;
}
.sider>*:nth-child(1)>*{
   /* border: 2px solid red; */
   display: flex;
   align-items: center;
   gap: 0.4rem;
 
   color: #3d3d3d;
}
.sider>*:nth-child(1)>* .activeTab{
   color: #1089FF;
}

.sider>*:nth-child(2){
 margin: auto auto 0 auto ;
}

.sider>*:nth-child(1) img{
   width: 1.5rem;
   height: 1.5rem;
}

.login{
    display: flex;
    background: url('./../../assets/login_backgroud.png');
    background-size: cover;
    height: 90vh;
    box-sizing: border-box;
    width: 100%;
  
}
.login>*{
   flex: 1 1 50%;
}
.imageSection{
    display: flex;
    width: 50%;
    height: 100%;
    background-color: #fff;
    /* border-radius: 0px 0px 20px 0px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3vh;
}

/* .imageSection>:nth-child(1){
    width: 30vw;
    height: 12vh;
} */

.imageSection>:nth-child(1){
    width: 28rem;
    height: 28rem;
}
.integrityText
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5vh;
}

.integrityText>:nth-child(1){
    font-size: 1.1rem;
    font-weight: 600;
}


.loginWrapper{
    display: flex;
    align-items: center;
    justify-content: center;  
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.loginContainer{
    background-color: #fff;
    border-radius: 0.4rem;
    width: 27rem;
    height: 30rem;
    display: flex;
    box-shadow: 0px 3px 6px #00000029;
}
.page1{
    margin: 5vmin;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    /* justify-content: center; */
    gap: 2vmin;
    width: 100%;
}
.page1>:nth-child(1){
    color: #191919;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 3vmin;
}

.page1ButtonDiv{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2vmin;
    margin-top: 10vh;
}
.page1ButtonDiv button{
    width: 14rem;
    height:2.4rem ;
    border-radius: 6px;
    outline: 0;
    border: 1px solid #CACACA;
    background-color: #fff;
    cursor: pointer;
    font-weight: 500;
}

.page1ButtonDiv>:nth-child(1)
{
    background-color: #1089FF;
    color: #fff;
    border: 1px solid #1089FF;
}

.socialIcon{
    display: flex;
    align-items: center;
    gap: 2vw;
}

.page1>:nth-child(3){
    color: #CACACA;
    font-size: 1.5vmin;
}
.continueAsGeust{
    color: #1089FF;
    font-size: 1.8vmin;
    display: flex;
    align-items: center;
    gap: 0.5vw;
}

.page1>:nth-child(5){
   height: 1px;
    background-color:#CACACA ;
    width: 5vw;
    margin:1.5vh 0 ;
}

.page1>:nth-child(6){
 
    color:#3D3D3D ;
    font-size: 1.7vmin;
 }

 .backDiv{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 55%;
    margin-bottom: 2vmin;
    color: #fff;
 }

 .backDiv>:nth-child(1){
    /* border: 1.3px solid #fff; */
    padding: 0.3vmin;
    width: 3.6vmin;
    /* height: 3.6vmin; */
    /* border-radius: 50%; */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
 }
 .backDiv>:nth-child(2){
    cursor: pointer;
 }

 .page2{
    display: flex;
    flex-direction: column;
    margin: 1.5rem;
    width: 100%;
   
    /* border: 2px solid red; */
 }
 .page2>:nth-child(1){
    font-weight: 600;
    font-size: 1.1rem;
 }
 .checkbox{
   display: flex;
 }
 .checkbox>:nth-child(1){
   margin-bottom: auto;
   margin-top: 0.3rem;
 }
 .checkbox>:nth-child(2){
   font-size: 0.7rem;
   color: #636363;
 }
 .terms{
   color: #1089FF;
   font-weight: 600;
 text-decoration: underline;
 text-decoration-style: solid;
 cursor: pointer;
 }
 .passReq{
   display: flex;
   flex-direction: column;
   margin-top: 0.5rem;
   gap: 0.3rem;
   font-size: 0.8rem;
   color: #808080;
   font-weight: 400;
 }
 .passReq>:nth-child(n+1){
   display: flex;
   align-items: center;
   gap: 0.4rem;
 }
 .passReq>:nth-child(n+1)>:nth-child(1){
   width: 1rem;
   height: 1rem;
 }
 .passReq>:nth-child(n+1)>:nth-child(2)>:nth-child(1){
   font-weight: 500;
   color: #000;
 }

 .signBy{
   display: flex;
   margin-top: auto;
   align-items: center;
   justify-content: center;
   gap: 0.5rem;
   color: #161616;
   cursor: pointer;
   font-weight: 500;
   font-size: 0.85rem;
   margin-top: 4rem;
 }
 .signBy span{
   color: #1089FF;
   margin-left: 0.1rem;
 }

 .page2>:nth-child(2){
   margin: 0.8vmin 0 3vmin 0;
   color: #191919;
   font-size: 0.8rem;
   font-weight: 500;
 }
 .page2>:nth-child(3){
   margin: 0.8vmin 0 3vmin 0;
   color: #191919;
   font-size: 0.8rem;
   font-weight: 500;
 }
 .page2button{
  margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 2px solid red; */
    margin-top: auto;
    flex-direction: column;
   padding-top: 1rem;
 }

 .page2button button{
   margin-top: 1rem;
    background-color: #1089FF;
    color: #fff;
    height: 2.4rem;
    width:100%;
    align-self: center;
    outline: 0;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
 }

 .page2button button:disabled{
    opacity: 0.4;
    cursor:not-allowed;
 }

 .page2Form{
    display: flex;
    flex-direction: column;
    height: 100%;
     /* height: 53vmin;  */
    /* border: 2px solid red; */
 }

 .page2Form>:nth-last-child(1){
    display: flex;
    align-items:flex-end;
    margin-top: 0;
 }

 .page2Form>:nth-last-child(1)>*{
    margin-top: auto;
 }
 .page2Form>:nth-last-child(2){
    margin-bottom: 0;
 }



 .page2Form>*>*:nth-child(1)>:nth-child(1){
    padding: 0;
    margin-bottom: 0.6vmin;
 }

 .page2Form>*>*:nth-child(1)>:nth-child(1) span{
   font-size: 0.9rem;
 }

 /* .page2Form>*>*:nth-child(1)>:nth-child(2)>*>*>*{
    font-size: 1.8vmin;
  } */


  .page3Form{
    display: flex;
    flex-direction: column;
    height: 100%;
 }

 .page3Form>:nth-last-child(1){
    display: flex;
   gap: 1rem;
    margin-top: 0;
 }

 .page3Form>:nth-last-child(1)>*:nth-child(1){
    margin-top: auto;
 }
 .page3Form>:nth-last-child(2){
    margin-bottom: 0;
 }



 .page3Form>*>*:nth-child(1)>:nth-child(1){
    padding: 0;
    margin-bottom: 0.6vmin;
 }

 .page3Form>*>*:nth-child(1)>:nth-child(1) span{
   font-size: 0.85rem;
 }

 .page3Form>*>*:nth-child(1)>:nth-child(2)>*>*>*{
    font-size: 0.85rem;
  }

  .page3button{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
 }

 .page3button button{
    background-color: #1089FF;
    color: #fff;
    height: 2.4rem;
    width: 9rem;
    align-self: center;
    outline: 0;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
 }

 .page3button button:disabled{
    opacity: 0.4;
    cursor:not-allowed;
 }

 .page3button>:nth-child(1){
    font-size: 1.8vmin;
    display: flex;
    gap: 0.4vw;
    color: #808080;
    /* border: 2px solid red; */
 }

 .page3button>:nth-child(1)>:nth-child(2){
    color:#1089FF ;
    cursor: pointer;
 }


 .signIn{
   background-color: #1089FF;
   color: #fff;
   height: 5vmin;
   width: 10vw;
   align-self: center;
   outline: 0;
   border: 0;
   border-radius: 6px;
   cursor: pointer;
}

.signIn:disabled{
   opacity: 0.4;
   cursor:not-allowed;
}


.page5{
   display: flex;
   flex-direction: column;
   margin: 1.5rem;
   /* width: 100%; */
  
   /* border: 2px solid red; */
}
.page5>:nth-child(1){
   font-weight: 600;
   font-size: 1.1rem;
}
.page5>:nth-child(2){
   margin: 0.8vmin 0 3vmin 0;
   color: #191919;
   font-size: 0.8rem;
   font-weight: 500;
 }
 .page5>:nth-child(3){
   margin: 0.8vmin 0 3vmin 0;
   color: #191919;
   font-size: 0.8rem;
   font-weight: 500;
 }


@media(max-width:800px)
{
   .header>:nth-child(2)>*>:nth-child(1){
      width: 0.9rem;
      height: 0.9rem;
    }
    .header>:nth-child(2)>*>:nth-child(2){
      font-size: 1rem;
    }
    .header>:nth-child(2)>*{
      gap: 0.5rem;
   }
   .signIn{
      height: 2.4rem;
      width: 10rem;
   }
   .login{
      flex-direction: column;
      background: none;
  }
  .login>*{
     flex: 1 1 100%;
  }
  .imageSection{
   width: 95%;
   margin-left: 0.5rem;
   margin-right: 0.5rem;
}
  
.imageSection>:nth-child(1){
   width: 80vw;
   height: 80vw;
}
.integrityText
{
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 0.5vh;
}

.integrityText>:nth-child(1){
   font-size: 1.1rem;
   font-weight: 600;
}

.backDiv{
   color: #000;
   padding: 1rem;
   width: 100%;
}
.loginContainer{
   width:95%;
   height: 90vh;
}
}



.jobsearch{
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

}
.disabled{
    cursor: not-allowed !important;
    opacity: 0.5 !important;
    pointer-events: none;

}

.jdPdf{
    /* border-top: 2px solid rgba(87, 87, 87, 1); */
    position: relative;
    width: 100%;
    padding: 5px;
    padding-right: 0px;
    height: 600px;
    display: flex;
    align-self: center;
    background-color:rgb(82, 86, 89);
    overflow-y: scroll;
}

.jdPdf .react-pdf__Page__canvas{
    width: 100% !important;
    height: 100% !important;
   margin-bottom: 5px;

}
.jdPdf .react-pdf__Page__annotations{
    display: none !important;

}


@media screen and (width>=700px){
    .shareJob{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 1.5rem;
        background-color: #EAF4FF;
        border: 1px solid #6BAFFF;
        border-radius: 6px;
        height: max-content;
        margin-top: 2rem;
    }
    .shareJob>:nth-child(1){
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        margin-bottom: 1rem;
    }
    .shareJob>*:nth-child(2){
        color: #161616;
        font-size: 1.1rem;
        font-weight: 600;
    }
    .shareJob>*:nth-child(3){
        color: #3D3D3D;
        font-size: 0.85rem;
        margin-bottom: 1rem;
    }
    .shareJob button
    {
        background-color: #1089FF;
        color: #fff;
        height: 2.3rem;
        outline: 0;
        border: 0;
        cursor: pointer;
        border-radius: 6px;
    }
    .shareJob button:hover
    {
        background-color: #0883ff;
    }
.Container1{
padding: 16px;
width: 50vw;
}
.applyJobWrapper{
    display: flex;
    gap: 1rem;
    width: 75%;
}
.searchConatiner{
    width: 40vw;
}
.ShareModal{
    width: 40vw;
}
.applyButton{
    height: 40px;
   display: flex;
   justify-content: flex-end;
   margin-top: -60px;
}


.ApplyJobcontainer{
    width: 50vw;
    display: flex;
    flex-direction: column;
}

.pdfContainer{
    width: 50vw;
}

.JobFormContainer{
    width: 50vw;
    padding: 16px;
}
.jobcontainer{
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: white;
    box-shadow: 1px 6px 12px #1e0a3a0a;
    border-radius: 12px;
    padding: 12px;
}
.UploadModal{
    width: 30vw;
}
.cnfmModal{
    width: 40vw;
}
.saveChooseBtn{
    display: flex;
    gap: 1vw;
}
.SuccessPopup{
    width: 40vw;
    top: 10vh;
}
.header1{
    font: normal normal 600 24px/30px Figtree;
}
.header2{
    font:normal 600 16px/22px figtree;
}
.title1{
    font:normal normal normal 14x/18px Figtree;
}
.title2{
    font:normal normal normal 12px/15px Figtree;
}
.locationText{
    max-width:500px;
}
.addImage{
    width: 60px;
        height: 60px;
}
}
@media screen and (width<700px) {

    .shareJob{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 1rem;
        background-color: #EAF4FF;
        border: 1px solid #6BAFFF;
        border-radius: 6px;
        height: max-content;
        
    }
    .shareJob>:nth-child(1){
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        margin-bottom: 1rem;
    }
    .shareJob>*:nth-child(2){
        color: #161616;
        font-size: 1.1rem;
        font-weight: 600;
    }
    .shareJob>*:nth-child(3){
        color: #3D3D3D;
        font-size: 0.85rem;
        margin-bottom: 1rem;
    }
    .shareJob button
    {
        background-color: #1089FF;
        color: #fff;
        height: 2.3rem;
        outline: 0;
        border: 0;
        cursor: pointer;
        border-radius: 6px;
    }
    .shareJob button:hover
    {
        background-color: #0883ff;
    }

    .applyJobWrapper{
        display: flex;
        flex-direction: column;
        /* gap: 1rem; */
        /* width: 75%; */
    }
    .locationText{
        width:16.5rem;
    }
    .ShareModal{
        width: 95vw;
    }
    .addImage{
        width: 50px;
        height: 60px;
    }
    .header1{
        font: normal normal 600 26px/32px Figtree;
    }
    .header2{
        font:normal 600 18px/22px figtree;
    }
    .title1{
        font:normal normal normal 18px/22px Figtree;
    }
    .title2{
        font:normal normal normal 14px/19px Figtree;
    }
  .Container1{
    padding: 12px;
    width: 97vw;
  }
  .searchConatiner{
    width: 90vw;
  } 
  .applyButton{
    height: 30px;
    /* margin-top: -15%; */
    /* margin-right: 3%; */
} 


.ApplyJobcontainer{
    width: 97vw;
    display: flex;
    flex-direction: column;
}
.pdfContainer{
    width: 97vw;
}

.JobFormContainer{
    width: 97vw;
    padding: 12px;
}
.jobcontainer{
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: white;
    box-shadow: 1px 6px 12px #1e0a3a0a;
    border-radius: 12px;
    padding: 12px;
}
.UploadModal{
    width: 90vw;
}
.cnfmModal{
    width: 90vw;
}
.SuccessPopup{
    width: 90vw;
}
.saveChooseBtn{
    display:flex;
    flex-direction: column;
    gap: 12px;
}
}
@media screen and (height>=700px) {
    .SuccessPopup{
        top: 20vh !important; 
    }
}
@media screen and (height<700px) {
    .SuccessPopup{
        top: 10vh !important; 
    }
}
@media screen and (height<600px) {
    .SuccessPopup{
        top: 5vh !important;
        
    }
}

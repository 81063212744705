.notification{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* border: 2px solid red; */
    margin: 1rem;
    box-sizing: border-box;
    height: 100%;
}
.notification> :nth-child(1){
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.tab{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    color: #ABABAB;
    cursor: pointer;
    font-size: 1rem;
}
.activeTab{
    color: #0086ff;
    border-bottom: 2px solid #0086ff;
    font-weight: 600;
}

.notificationTabDetails{
    display: flex;
    flex-direction: column;
    height: 65vh;
    /* border: 2px solid red; */
    overflow-y: auto;
    gap: 1rem;
    margin-top: 1rem;
}
.requestInfoTab{
    display: flex;
    flex-direction: column;
    /* border: 2px solid red; */
    height: 65vh;
    margin-top: 1rem;
    overflow-y: auto;
}
.seeRequest{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0086ff;
    color: #fff;
    cursor: pointer;
    padding: 0.5rem 1rem;
    width: 15rem;
    border-radius: 6px;
    margin: 1.5rem auto;
}

.eachRequest{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding-bottom: 0.5rem;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
}

@media(max-height:700px)
{
    .notificationTabDetails{
        height: 62vh;
    }

    .requestInfoTab{
        height: 62vh;
    }
}

@media(min-height:701px) and (max-height:800px)
{
    .notificationTabDetails{
        height: 64vh;
    }
    .requestInfoTab{
        height: 64vh;
    }
}
@media(max-width:800px)
{
    .eachRequest{
        flex-direction: column;
    }
}
.recommendation{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100%;
    width: 100%;
}

.card{
    display: flex;
    flex-direction: column;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #18181812;
    border-radius: 10px;
    padding: 1rem;
    width: 100%;
}
.card>*:nth-child(1){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;  
}
.card>*:nth-child(1)>*:nth-child(1){
    display: flex;
    flex-direction: column;
   
}
.card>*:nth-child(1)>*:nth-child(2){
    display: flex;
    flex-direction: row;
   gap: 0.4rem;
}

.rcomModal{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 0rem;
    width: 100%;
}
.rcomModal>*:nth-child(1){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
}
.rcomModal>*:nth-child(1)>*:nth-child(1){
    display: flex;
    flex-direction: column;
}
.rcomModal>*:nth-child(1)>*:nth-child(2){
    display: flex;
    flex-direction: row;
   gap: 0.4rem;
}
.header{
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: space-between;
    margin-bottom: 0.7rem;
    border-bottom: 1px solid #dfdfdf;
}

.header>*:nth-child(1){
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}
.header>*:nth-child(1)>:nth-child(2){
    color: #1818187A;
    font-size: 13px;
}
.header>*:nth-child(2){
    display: flex;
    flex-direction: row;
    gap: 0.4rem;
}
.header>*:nth-child(2)>:nth-last-child(1){
    font-weight: 600;
}
.spin{
    display: flex;
    width: 100%;
    height: 40vh;
    align-items: center;
    justify-content: center;
}

@media(max-width:800px)
{
   .card>:nth-child(1){
    flex-direction: column;
   }

   .card>*:nth-child(1)>*:nth-child(2){
    margin-top: 0.5rem;
}
.rcomModal>*:nth-child(1){
  flex-direction: column;
}
.rcomModal>*:nth-child(1)>*:nth-child(2){
    margin-top: 0.5rem;
}
}

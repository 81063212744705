.newsLetter{
    display: flex;
    width: 100%;
    height: 90vh;
}
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Lato';
}
.sider{
    width: 18%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 90vh;
    gap: 3rem;
    align-items: center;
    /* justify-content: space-between; */
    padding: 1.5rem 0;
    border-right: 1px solid #DFDFDF;
}

.sider>*:nth-child(1){
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    padding: 0.9rem 1rem;
    background-color: #DBF4FF;
    color: #0086FF;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    border-radius:10px ;
    border-right: 5px solid #0086FF;
    width: 90%;
}

.sider>*:nth-child(2){
  margin-top: auto;
}

.sider>*:nth-child(1) img{
    width: 2rem;
    height: 2rem;
}

.newsLetter>:nth-child(2){
    width: 82%;
}
.continue{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
   height: 86vh;
  padding: 0 1rem;
  overflow-y: auto;
  margin: 1rem 0;
}
*{
    box-sizing: border-box;
}
.backDiv{
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 1rem;
}
.backDiv>*:nth-child(1){
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.7rem;
}

.backDiv>*:nth-child(1)>*:nth-child(2){
   font-weight: 500;
}

.backDiv>*:nth-child(2){
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    overflow-y: auto;
    border-radius: 10px;
    background: #FFF;
    /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #fff; */
    border-radius: 10px 10px 0 0;
    overflow-y: auto;
    padding-bottom: 1rem;
  
 }

.backDiv>*:nth-child(2)>*:nth-child(1){
   width: 100%;
   min-height: 20rem;
   display: flex;
   justify-content: center;
   border-radius: 15px 15px 0 0;
}

.backDiv>*:nth-child(2)>*:nth-child(3){
    padding: 0 1rem;
    line-height: 1.8rem;
 }


.backDiv>*:nth-child(2) img{
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 15px;
    animation: animate 1s ease-out forwards;
}
/* 
@keyframes animate{
    0%{
        width: 100%;
        max-width: 100%;
        height: 50%;
        transform:rotateX(0deg) rotateY(-10deg) rotateZ(5deg);
    }
    100%{
        width: 100%;
        max-width: 100%;
        height: 100%;
        transform:rotateX(360deg) rotateZ(0deg);
    }
} */
.backDiv>*:nth-child(2)>*:nth-child(2){
    display: flex;
    margin: 0 auto;
    color: #596172;
    font-size: 0.8rem;
 }
 .backDiv>*:nth-child(2)>*:nth-child(3){
    display: flex;
    justify-content: center;
    align-items: center;
 }

 .backDiv>*:nth-child(2)>*:nth-child(4){
    margin: 2rem 2rem 0 0;
    align-self: flex-end;
    font-weight: 700;
 }

.recentPost{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 30%;
    padding:0 1rem 1rem 1rem;
}
.recentPost>:nth-child(1){
    color:#454360;
    font-weight: 600;
}

.recentPost>:nth-child(2){
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 15px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
/* .recentPost>:nth-child(2)>:nth-child(1)>*{
    border: 2px solid red;
    display: flex;
    flex-direction: row;
    height: auto;

} */

.post{
    display: flex;
    flex-direction: row;
    max-height: 7rem;
    gap: 1rem;
    border-bottom: 1.2px solid #D5D5D5;
    padding-bottom: 0.5rem;
    cursor: pointer;
}

.post:hover{
    animation: post-card-animate 0.5s linear  1 forwards ;
}

@keyframes post-card-animate {
    0%{
        transform: scale(1);
    }
    100%{
        transform: scale(1.02);
    }
}

.post>:nth-child(1)
{
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    display: flex;
    position: relative;
}

.post>:nth-child(1) img
{
    /* position: absolute; */
   animation: Rpost 1s ease-in-out forwards;
}
@keyframes Rpost{
    0%{
        width: 50%;
        height: 50%;
    }
    100%{
        width: 100%;
        height: 100%;
        /* border-radius: 50%; */
    }
}
.post>:nth-child(2)
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.post>:nth-child(2)>:nth-child(2)
{
    font-size: 0.8rem;
}

@media(max-width:700px)
    {
        .header{
            flex-direction: column;
        }
        .header>:nth-child(2){
            flex-direction: column;
        }
        .eachNewsCard>*:nth-child(1){
            flex-direction: column;
        }

        .eachNewsCard{
            box-shadow: none;
            border-radius: 0;
            margin-bottom: 2rem;
            border-bottom: 1px solid #dfdfdf;
        }

        .newsLetter{
            background-color: #fff;
            height: 100%;
        }
        .newsLetter>*{
            height: 100%;
        }

        .sider{
            width: 100%;
            height: 80vh;
            border-right: 0;
        }

        .sider>*:nth-child(1){
            padding: 0rem 1rem;
            border-right:5px solid #0086FF ;
        }
        .continue{
            flex-direction: column;
            gap: 1rem;
           height: auto;
          padding: 0 1rem;
          margin: 1rem 0;
        }
        .backDiv{
            width: 100%;
        }
        .recentPost{
            width: 100%;
        }
    }
.addTeamModal {
    display: flex;
    justify-content: center;
    flex: 1;
    flex-direction: column;
    margin:0 0rem;
    overflow-y: auto;
}


.buttonDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    margin-top: 3rem;
}

.modalHeader {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    width: 100%;
    color: #162432;
    font-weight: 500;
 
}


.addTeamModal>*>*>*>*:nth-child(1) span{
    font-weight: 600;
}

.addTeamModal>*>*:nth-child(5)>*>*:nth-child(2)>*>*>*, .addTeamModal>*>*:nth-child(6)>*>*:nth-child(2)>*>*>*{
    height: 2.5rem;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #E6E6E6;
}

.addTeamModal>*>*:nth-child(1)>*>*:nth-child(2)>*>*>*>*:nth-child(1){
    border-radius: 10px;
    border: 1px solid #E6E6E6;
}

.addTeamModal>*>*:nth-child(2)>*>*:nth-child(2)>*>*>*>*:nth-child(1){
    border-radius: 10px;
    border: 1px solid #E6E6E6;
}
.addTeamModal>*>*:nth-child(3)>*>*:nth-child(2)>*>*>*>*:nth-child(1){
    border-radius: 10px;
    border: 1px solid #E6E6E6;
}
.addTeamModal>*>*:nth-child(4)>*>*:nth-child(2)>*>*>*>*:nth-child(1){
    border-radius: 10px;
    border: 1px solid #E6E6E6;
}

.addTeamModal2{
    display: flex;
    flex-direction: column;
    height: 85vh;
    overflow-y: auto;
}

@media  (min-height:650px ) and (max-height: 800px) {
   .addTeamModal{
    max-height: 625px;
    padding-top: 0;
   }

   .buttonDiv {
   
    margin-top: 1.5rem;
}
  }

  @media  (max-height: 650px) {
    .addTeamModal{
     max-height: 530px;
     padding-top: 5rem;
    }
 
    .buttonDiv {
     margin-top: 1rem;
 }
   }






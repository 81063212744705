
  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@400;500;600;700;800&display=swap');


.divBtnStyle{
    display: flex;
    justify-content: center;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    font: normal 400 16px/21px Poppins;
   align-items: center;
}
.tableAction{
    display: flex;
    align-items: center;
    width: 140px;
    gap: 8px;
    cursor: pointer;
}
.tableRow{
    background-color:#F9F9F9 ;
    width: 98%;
    font: normal 400 14px/18px Poppins;
    color: #162432;
    border: 0;
}
*{
    box-sizing: border-box;
    font-family: 'Lato';
}
.DepartmentApprovalContainer{
 background-color: #fff;
 height: 85vh;
 width: 99%;
 /* border: 2px solid red; */
 /* position: fixed; */
}
.mainDiv{
display: flex;
flex-direction: column;
gap: 1rem;
}
.confirmationModalMainDiv{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 1rem;
width: 100%;
}
.disabled{
        pointer-events: none;
        opacity: 0.4;
}
.tableTitle{
    color: rgba(22, 36, 50, 0.50);
}




.departmentApprove>*>*>*:nth-child(2)
{
    padding-right: 2rem;
}


.departmentApprove .ant-table-thead {
    background-color: red;
    font-weight: bold;
  }

  .tableRowWhite{
    background-color: #fff;
    color: #162432;
  }
  .tableRowWhite>:hover{
    color: inherit;
  }

  .tableRow{
    background-color: #F9F9F9;
    color: #162432;
  }
  td{
    border-bottom: none !important;
  }
  tr{
    border-bottom:0  !important;
  }

  @media screen and (width<=2000px) and (width>1700px) {
    .header1Font{
      font: normal 600 20px/27px Lato;
      color: rgba(22, 36, 50, 1);
      }
      
      .header2Font{
        font: normal 500 20px/27px Lato;
        color: rgba(22, 36, 50, 1);
      
      }
      .header3Font{
        font: normal 400 20px/27px Lato;
        color: rgba(22, 36, 50, 1);
      }
      .header4Font{
        font: normal 400 20px/27px Lato;
        color: rgba(22, 36, 50, 0.5);
      } 
  }
  
  @media screen and (width<=1700px) and (width>1500px) {
    .header1Font{
      font: normal 600 18px/24px Lato;
      color: rgba(22, 36, 50, 1);
      }
      
      .header2Font{
        font: normal 500 18px/24px Lato;
        color: rgba(22, 36, 50, 1);
      
      }
      .header3Font{
        font: normal 400 18px/24px Lato;
        color: rgba(22, 36, 50, 1);
      }
      .header4Font{
        font: normal 400 18px/24px Lato;
        color: rgba(22, 36, 50, 0.5);
      } 
  }
  @media screen and (width<=1500px) and (width>1300px) {
    .header1Font{
      font: normal 600 16px/21px Lato;
      color: rgba(22, 36, 50, 1);
      }
      
      .header2Font{
        font: normal 500 16px/21px Lato;
        color: rgba(22, 36, 50, 1);
      
      }
      .header3Font{
        font: normal 400 16px/21px Lato;
        color: rgba(22, 36, 50, 1);
      }
      .header4Font{
        font: normal 400 16px/21px Lato;
        color: rgba(22, 36, 50, 0.5);
      } 
  }

  .ListContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 0rem 2rem;
  }
  .ListHeader{
    padding:  1rem 0rem 1rem 0;
    width: 100%;

  }
  .ListHeader>*{

    display: flex;
    justify-content: center;
    align-items: center;
    color: #162432;
    font: normal 600 16px/22px lato;
  }
  .ListBodyContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .rowContainer{
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    height: 5rem;
    box-shadow: 0 2px 6px rgba(0, 0,0,0.2);
  }
  .rowContainer:hover{
    box-shadow: 0 8px 12px rgba(146, 153, 151, 0.2);
    /* background-color: rgba(150, 244, 213,0.6); */
    transform: scale(0.99);
    transition: .6s ease-in-out;
    padding: 0rem 0.5rem;
  }
  .rowContainer>*{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #16243280;
    font: normal 600 16px/22px lato;
  }
  .ListBodyContainer::-webkit-scrollbar{
    display: none;
  }


  @media screen and (width>=700px) {
    .approveModal{
      width: 80vw;
      padding: 10px;
      top: 25vh;
    }
    .MobViewList{
      display: none;
    }
    .header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding: 1rem 2rem 0 1rem;
  }
  
  .header>:nth-child(1){
  color: #162432;
  font-family: "Lato";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  }
  
  .header>*:nth-child(2){
      display: flex;
      gap: 1rem;
      align-items: center;
  }
  .header>*:nth-child(2):last-child{
  width: 400px;
}
  }
  @media screen  and (width<700px) {
    .approveModal{
      width: 95vw;
      padding: 10px;
      top: 25vh;
    }
    .header{
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 2rem 0 1rem;
  }
  
  .header>:nth-child(1){
  color: #162432;
  font-family: "Lato";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  }
  
  .header>*:nth-child(2){
      display: flex;
      gap: 1rem;
      align-items: center;
      width: 100%;
  }
    .WebViewList{
      display: none;
    }
    .MobViewList{
      background-color: #f8f8f8;
      display: flex;
      flex-direction: column;
      height: 85vh;
     
    }
    .ListContainerMob{
      padding: 1rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
    }
    .MobListBody{
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1rem;
      border-radius: 10px;
      background-color: #fff;
    }
    .listLine{
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }
  @media screen and (height>900px) {
    .ListScroll{
      max-height: 77vh;
      overflow-y: scroll;
      width: 100%; 
     }
     .ListScrollMob{
      max-height: 78vh;
      overflow-y: scroll;
      width: 100%; 
     }
  }
  @media screen and (height<900px) and (height>=750px) {
    .ListScroll{
      max-height: 75vh;
      overflow-y: scroll;
      width: 100%; 
     }
     .ListScrollMob{
      max-height: 73vh;
      overflow-y: scroll;
      width: 100%; 
     }
  }
  @media screen and (height<750px) and (height>=600px) {
    .ListScroll{
      max-height: 68vh;
      overflow-y: scroll;
      width: 100%; 
     }
     .ListScrollMob{
      max-height: 68vh;
      overflow-y: scroll;
      width: 100%; 
     }
  }
  @media screen and (height<600px) {
    .ListScroll{
      max-height: 63vh;
      overflow-y: scroll;
      width: 100%; 
     }
     .ListScrollMob{
      max-height: 63vh;
      overflow-y: scroll;
      width: 100%; 
     }
  }
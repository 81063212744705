
.Modal{
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    /* align-items: center; */
    /* justify-content:center; */
    /* padding: 1.5rem; */
}
.header{
    margin: 1rem 0;
    padding-inline: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding-bottom: 0.7rem;
    border-bottom: 1.3px solid #F1F1F1;
    /* border:1px solid black */
}
.header>:nth-child(2){
    display: flex;
    justify-content: center;
    padding: 0;
    align-self: center;
}

*{
    padding: 0;
    margin: 0;
}
.header>:nth-child(1), .header>:nth-child(3){
    width: 2.1rem;
    display: flex;
    align-items: center;
    justify-content: right;
    align-self: right;
}
.header>:nth-child(1) img, .header>:nth-child(3) img{
    width: 2rem;
    height: 1rem;
    cursor: pointer;
}


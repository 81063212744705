h5{
    margin: 0;
    padding: 0;
}

.card {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    background-color: bisque;
    width: 16rem;
    height: fit-content;
    padding: 0 2rem 2rem 2rem;
    border-radius: 10px;
  }
  
  .card img {
    margin-top: -20%;
    width: 100%;
    border-radius: 20px;
  }
  
  .card h2 {
    margin: 0;
    margin-top: 1rem;
  }
  
  .card p {
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .card .btnn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
.btn {
  background-color: #0094ff;
  color: #fff;
  border: none;
  outline: none;
  font-size: 1.2rem;
  border-radius: 10px;
  padding: 11px 1rem;
  /* margin: 0 0.5rem ; */
  width: 7.5rem;
}  
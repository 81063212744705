.layout{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.sider{
    display: flex;
    flex-direction: column;
    width: 20%;
    background-color: #fff;
    border-right: 1px solid #dfdfdf;
    padding-top: 2rem;
    padding:2rem 1rem 1rem 1rem;
}
.main-content{
    display: flex;
    flex-direction: column;
    width: 80%;
}

.nav-common{
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}
.nav-common>*>*{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    font-weight: 600;
}
.nav-common>*>*>:nth-child(2){
    white-space: nowrap;
}


.module-active{
    padding: 0.3rem 1rem;
    background-color: #DBF4FF;
    color: #0086FF;
    border-right: 5px solid #0086FF;
    border-radius: 8px;
}

@keyframes fillBackground {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  
  .module-active {
    width: 0;
    background-color: #DBF4FF; 
    animation: fillBackground 0.5s ease-out  forwards;
  }
.module-inactive{
    color: #a0a0a0;
    padding: 0.3rem 1rem;
}


.sub-module-active{
    padding: 0.3rem 1rem;
    color: #0086FF;
    border-right: 5px solid #0086FF;
    border-radius: 8px;
}

@keyframes fillBackground2 {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  
  .sub-module-active {
    width: 0;
    background-color: #DBF4FF;
    animation: fillBackground2 0.5s ease-out  forwards;
  }
.sub-module-inactive{
    color: #a0a0a0;
    padding: 0.3rem 1rem;
}

.visible{
    margin-left: 1rem;
   animation: visible-animate 0.5s ease-out  1 forwards;
}

@keyframes visible-animate {
    0%{
        transform: translateY(-50rem);
    }
    100%{
        transform: translateY(0);
    } 
}
.show-not{
    display: none;
}
.hide{
    transform: translateY(-50rem);
    animation: hide-animate 0.5s linear  1 forwards;
}

@keyframes hide-animate {
    0%{
        transform: translateY(0);
        opacity: 0;
    }
    100%{
        transform: translateY(-50rem);
        display: none;
        opacity: 1;
    } 
}

@media(max-width:800px)
{
    .sider{
        width: 100%;
        height: 80vh;
    }
    .main-content{
        width: 100%;
    }

    .module-active{
        padding: 0rem 1rem;
    }
    .sub-module-inactive{
        padding: 0rem 1rem;
    }
    .sub-module-active{
        padding: 0rem 1rem;
    }
}
@font-face {
  font-family: "Poppins",Figtree;
  src: url("./assets//fonts/Poppins-Regular.ttf");
}
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins',Figtree;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9f9f9;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',Figtree,
    monospace;
}

@media screen and (width<=2000px) and (width>1700px) {
  .header1Font{
    font: normal 700 30px/40px Lato;
    color: rgba(22, 36, 50, 1);
    }
    
    .header2Font{
      font: normal 500 20px/27px Lato;
      color: rgba(22, 36, 50, 1);
    
    }
    .header3Font{
      font: normal 400 20px/27px Lato;
      color: rgba(22, 36, 50, 1);
    }
    .header4Font{
      font: normal 400 20px/27px Lato;
      color: rgba(22, 36, 50, 0.5);
    } 
}

@media screen and (width<=1700px) and (width>1500px) {
  .header1Font{
    font: normal 700 26px/36px Lato;
    color: rgba(22, 36, 50, 1);
    }
    
    .header2Font{
      font: normal 500 18px/24px Lato;
      color: rgba(22, 36, 50, 1);
    
    }
    .header3Font{
      font: normal 400 18px/24px Lato;
      color: rgba(22, 36, 50, 1);
    }
    .header4Font{
      font: normal 400 18px/24px Lato;
      color: rgba(22, 36, 50, 0.5);
    } 
}
@media screen and (width<=1500px) and (width>1300px) {
  .header1Font{
    font: normal 700 24px/32px Lato;
    color: rgba(22, 36, 50, 1);
    }
    
    .header2Font{
      font: normal 500 16px/21px Lato;
      color: rgba(22, 36, 50, 1);
    
    }
    .header3Font{
      font: normal 400 16px/21px Lato;
      color: rgba(22, 36, 50, 1);
    }
    .header4Font{
      font: normal 400 16px/21px Lato;
      color: rgba(22, 36, 50, 0.5);
    } 
}
@media screen and (width<=1300px)  {
  .header1Font{
    font: normal 700 24px/32px Lato;
    color: rgba(22, 36, 50, 1);
    }
    
    .header2Font{
      font: normal 500 16px/21px Lato;
      color: rgba(22, 36, 50, 1);
    
    }
    .header3Font{
      font: normal 400 16px/21px Lato;
      color: rgba(22, 36, 50, 1);
    }
    .header4Font{
      font: normal 400 16px/21px Lato;
      color: rgba(22, 36, 50, 0.5);
    } 
}



@media(max-height:800px)
{
  .ant-picker-dropdown {
    max-height: 300px; /* Set a maximum height for the dropdown */
    overflow-y: auto; /* Enable vertical scroll when content overflows */
    box-shadow: 0 2px 6px rgba(0,0,0,0.25);
  }
}


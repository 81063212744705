

.donut-chart {
    width: 100%;
    /* height: 40vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    /* border:1px solid black */
}

svg > circle:hover {
  transition: stroke-width 0.3s;
  stroke-width: '3.5rem';
}

.items-names {
  width: 25rem;
  height: 58%;
  display: flex;
flex-direction: column;

  padding: 10;
  list-style: none;
}
.item-name {
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 1rem;
}

h1 {
  margin-top: 10px;
}

h4{
    margin: 0;
    padding: 0;
}

.container{
  width: 98.5%;
  height:100%;
  /* padding: 1rem; */
  /* border:1px solid green; */
  margin-top: 1rem;
  margin-left: 0.5rem;
}

.titleBlock{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border:1px solid blue */
}

.title{
  font-weight: 700;
  color: #000000;
  font-family: Lato;
  margin-left: 0.5rem;
  /* border: 1px solid red; */
}

.tab_Container{
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: flex-end;
  overflow-x: scroll;
  /* border: 1px solid red; */
}

.tab_Container::-webkit-scrollbar{
  display: none;
}

.action_container{
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  /* border: 1px solid red; */
}

.searchVal {
    width: 15rem;
    height: 2.5rem;
    background-color: #FFFFFF;
    color: black;
    font-size: 1.2rem;
    
  }
  .searchVal.input input:focus,
  .searchVal.input input:active{
    outline:none;
        border: 0;
    
    /* border-bottom: 3px solid #4DAAFF; */
  }



.container{
    width: 98.5%;
    height:100%;
    /* padding: 1rem; */
    /* border:1px solid green; */
    margin-top: 1rem;
    margin-left: 0.5rem;
}

.titleBlock{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border:1px solid blue */
}

.title{
    font-weight: 700;
    color: #000000;
    font-family: Lato;
    padding-left: 0.3rem;
    /* border: 1px solid red; */
}

.tab_Container{
    width: 100%;
    height: 100%;
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: flex-end;
    overflow-x: scroll;
    /* border: 1px solid red; */
}

.tab_Container::-webkit-scrollbar{
    display: none;
}

.action_container{
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    /* border: 1px solid red; */
}
.Backdrop {
    display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999; 
}

.Center{
    justify-content: center;
    align-items: center;
}

.Right{
    justify-content: right;
}


.BackdropOpen {
    display: flex;
}

.BackdropClosed {
    display: none;
}
.recommend{
    display: flex;
    flex-direction: column;
    width: 45vw;
    padding:0;
}
.header{
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: space-between;
    margin-bottom: 0.7rem;
    border-bottom: 1px solid #dfdfdf;
}

.header>*:nth-child(1){
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}
.header>*:nth-child(1)>:nth-child(2){
    color: #1818187A;
    font-size: 13px;
}
.header>*:nth-child(2){
    display: flex;
    flex-direction: row;
    gap: 0.4rem;
}
.header>*:nth-child(2)>:nth-last-child(1){
    font-weight: 600;
}
.content{
    line-height: 2rem;
}

@media(max-width:700px)
{
    .recommend{
        width: 90vw;
        padding:0;
    }
    .header{ 
        flex-direction: column;
    }

    .header>:nth-child(2){
        margin: 1rem 0 0 0;
    }
    
}
.inputSearch{
    font-family: Poppins;
    font-size: 16px;
    line-height: 1.5;
    border: none;
    background: #FFFFFF;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%23838D99' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
    background-repeat: no-repeat;
    background-position: 10px 10px;
    background-size: 20px 20px;
    /* box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08); */
    border-radius: 5px;
    width: 100%;
    padding: .5em 1em .5em 2.5em;
    } 
.input-elevated::placeholder{
      color: #B6B6B6;

    }   
  .ListContainer{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      padding: 0rem 1.4rem;
      /* margin-top: 0.5rem; */
      background-color: #F9F9F9;
    }
    
.input-elevated:focus {
      border: none;
      outline: none;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16);
    }
    input:focus-visible{
        outline: none;
        border: none;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16);
    }

    .disabled{
        pointer-events: none;
        opacity: 0.4 !important;
}

.tableRowWhite{
    background-color: #fff;
    color: #162432;
    font-weight: 500;
    font-size: 13px;
  }

  .tableRow{
    background-color: #F9F9F9;
    color: #162432;
    font-weight: 500;
    font-size: 13px;
  }
  .tableRowWhite{
    background-color: #fff;
    color: #162432;
    font-weight: 500;
    font-size: 13px;
  }

  .tableRow{
    background-color: #F9F9F9;
    color: #162432;
    font-weight: 500;
    font-size: 13px;
  }
  .ListBodyContainer::-webkit-scrollbar{
    display: none;
    /* border: 1px solid #F9F9F9; */
  }
  .SelectScroll::-webkit-scrollbar{
    height:1px
  }
  .SelectScroll:focus{
    outline: none;
    border: none;
  }
  .SelectScroll:active{
    outline: none;
    border: none;
  }
  .ListBodyContainer{
    width: 100%;
  }
  .rowContainer{
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: white;
    margin-bottom:0.8rem;
    border-radius: 5px;
    /* padding-left: 1rem; */
    /* padding-right: 1rem; */
    height: 5rem;
    box-shadow: 0 2px 6px rgba(0, 0,0,0.2);
  }
  .rowContainer>*{
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
  .ListHeader{
    /* padding: 1rem ; */
    padding-bottom: 0.6rem;
    width: 100%;
  }
  .ListHeader>*{
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.ListContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 0rem 1rem;
    padding-bottom: 0.5rem;
    padding-top: 1rem;
    /* margin-top: 1rem; */
    background-color: #F9F9F9;
  }
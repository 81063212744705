.magDocs{
    
    height: 100vh;
}

/* @media screen and (height<590px) {
    .magDocs{
    
        height: 60vh;
    } 
}
@media screen and (height>=590px) and (height<700){
    .magDocs{
    
        height: 70vh;
    } 
}
@media screen and (height>=700px) and (height<1000){
    .magDocs{
    
        height: 80vh;
    }   
}
@media screen and (height>=1000px) and (height<1300){
    .magDocs{
    
        height: 90vh;
    } 
}
@media screen and (height>=1300px){
      
} */





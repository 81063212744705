/* .Links{
    display:flex;
    /* justify-content: center; */
    /* align-items: center; */
    /* width: 100%; */
/* } */
.settingPage{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2vh 0;
}
.settingH3{
    display: flex;
    width:100%;
    font-size: 1.2rem;
    justify-content: flex-start;
    margin:0.7vh 0 1vh 0 ;
}
.settingBackIcon{
    display:flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    font-size: 0.9rem;
}
.Links{
    display: flex;
    flex-direction: row;
    flex-direction: space-between;
    /* align-items: center; */
    padding: 0 1vw;
    gap: 10vw;
    margin: 1vh 0;
    width: 100%;
}
.Links>*{
    display:flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8rem;
    cursor: pointer;
}
.Links>* img{
    height: 2rem;
    width: 2rem;
    background-color: #FFF5EE;
    border-radius: 15%;
}
.settingList{
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 1.1vh;
    cursor: pointer;
    padding: 1.4vh 0.3vw 1vh 0.8vw;
    gap: 0.5vw;
    align-items: center;
}
.settingList img{
    height: 2.5vh;
}
.activeSetting{
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5vh;
    cursor: pointer;
    padding: 1.4vh 0.3vw 1vh 0.8vw;
    gap: 0.5vw;
    align-items: center;
    background-color: #EAF4FF;
}
.activeSetting img{
    height: 2.5vh;
}
.settingPageDivide{
    background-color: #FFFFFF;
    width: 35%;
    padding: 4vh 1vw;
    overflow: auto;
    height: 78vh;
    border-radius: 5px;
}
.settingPageDivide2{
    background-color: #FFFFFF;
    padding: 4vh 1vw;
    overflow: auto;
    height: 78vh;
    border-radius: 5px;
}
.settingLogout{
    width: 100%;
    height:100%;
    padding: 0 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.settingLogout>*:nth-child(2){
    display:flex;
    width: 100%;
    justify-content: center;
}
.settingChangePassword{
    padding: 1vh 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.settingChangePassword>*:nth-child(2){
    display:flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 2.2vh;
}
.settingDelete{
    padding: 0 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.settingDelete>*:nth-child(2){
    display:flex;
    width: 100%;
    justify-content: center;
}
.container{
    display: flex;
   align-items: center;
   gap: 0.5vw;
   border: 1px solid #CACACA;
   border-radius: 0.8vh;
   padding-left: 0.5vw;
   height: 2.3rem;
}
.container:active, .container:focus{
    border: 1px solid #0086ff;
}
.code{
    display: flex;
    align-items: center;
    gap: 0.3vw;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.8vmin;
}
.dropdown{
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
    height: 15vw;
    overflow-y: auto;
    width: 20vw;  
}
.dropdown>:nth-child(1){
    padding-left: 0.2vw;
}
.dropdown>:nth-child(1)>*{
  margin:1vh 0;
  width: 95%;
  height: 4.4vh;
}
.passReq{
    display: flex;
    flex-direction: column;
    margin-top: -0.8rem;
    margin-bottom: 0.5rem;
    gap: 0.3rem;
    font-size: 0.8rem;
    color: #808080;
    font-weight: 400;
    padding: 0 0.2vw;
  }
  .passReq>:nth-child(n+1){
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
  .passReq>:nth-child(n+1)>:nth-child(1){
    width: 1rem;
    height: 1rem;
  }
  .passReq>:nth-child(n+1)>:nth-child(2)>:nth-child(1){
    font-weight: 500;
    color: #000;
  }
 
.disabled{
    opacity: 0.5 !important;

}

@media screen and (width>=700px) {
    .SavedJobsHeader{
        width: 50vw;
    }
}
@media screen and (width<700px) {
    .SavedJobsHeader{
        width: 90vw;
    }
}
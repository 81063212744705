.calender{
    display: flex;
    flex-direction: column;
    width:100%;
    height: 88vh;
    padding: 1rem; 
    overflow-y: auto;
}

* h3,h2,h1,h4,span{
    font-family: "poppins";
    margin: 0;
    padding: 0;
}

.calender header{
    display: flex;
    flex-direction: row;
}


.calender header div{
    display: flex;
    flex: 1;
    justify-content: center;
}

.nameDiv{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    margin: 0.7rem 0 1rem 0;
}


.nameDiv>*:nth-child(2){
   display: flex;
   flex-direction: row;
   gap: 2rem;
}


.hours{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows:7rem;
    gap: 5rem;
    padding: 0.1rem;
}

.hours>*{
background: #FFFFFF;
border-radius: 5px;
padding: 0.8rem 1.5rem;
display: flex;
flex-direction: row;
gap: 1.5rem;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.hours>*>*{
    display: flex;
    flex-direction: column;
    font-size: 13px;
    gap: 1rem;
    font-weight: 600;
}

.hours>*>*:nth-child(1)>*{
    color: #878787;
}

.calender>:nth-child(3)>*:nth-child(2){
    margin-top: 2rem;
    display:flex;
    gap: 5rem;
    flex-direction: row;
}

.calender>:nth-child(3)>*:nth-child(2)>*:nth-child(1)>*:nth-child(2){
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0.5rem;
}


.calenderPart{
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #FCFCFC;
    border-radius: 5px;
    justify-content: flex-start;
}

.calenderPart>*:nth-child(1){
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem 0;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.calenderPart>*:nth-child(1)>:nth-child(2){
    color: #0086FF;
    font-size: 15px;
}
.calenderPart>*:nth-child(1)>:nth-child(1), .calenderPart>*:nth-child(1)>:nth-child(3)
{
    font-size: 1.2rem;
    color: #979797;
}


.desciption{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    font-size: 13px;
}

.weekDays{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: #0086FF;
    margin-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #EAEAEA;
    font-size: 13px;
    font-weight: 600;
}

.daySlot{
    display: grid;
    grid-template-columns: repeat(7,1fr);
    row-gap: 1rem;
    overflow-y: auto;
    max-height: 30vh;
    margin-top: 1rem;
    padding-right: 0.8rem;
}

.daySlot>*{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 1rem; 
}

.daySlot>*>*:nth-child(1){
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* background-color: #B9C0CC; */
    font-size: 13px;
    color: #ffffff;
    font-weight: 600;
}

.upcoming{
    border: 1.5px solid #0086FF;
}

.daySlot>*>*:nth-child(2){
   font-size: 12px;
   font-weight: 600;
}


.daySlot::-webkit-scrollbar {
  width: 5px;
}

.daySlot::-webkit-scrollbar {
  height: 1em;
}
 
.daySlot::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
 
.daySlot::-webkit-scrollbar-thumb {
  background-color: rgb(92, 88, 88);
  outline: 1px solid rgb(13, 14, 15);
  border-radius: 10px;
  height:3rem;
} 
.backDiv{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 55%;
    margin-bottom: 2vmin;
    color: #fff;
 }

 .backDiv>:nth-child(1){
    /* border: 1.3px solid #fff; */
    padding: 0.3vmin;
    width: 3.6vmin;
    /* height: 3.6vmin; */
    /* border-radius: 50%; */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
 }
 .backDiv>:nth-child(2){
    cursor: pointer;
 }
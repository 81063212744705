.container{
    display: flex;
    flex-direction: column;
    margin: 4vmin; 
    overflow-y: auto;
    height: 75vmin;
    gap: 1vmin;
    padding-right: 0.4vw;
}

.container>:nth-child(1){
    display: flex;
    height: 50vmin;
}
.container>:nth-child(1) img{
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
}
.container>:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.container>:nth-child(3){
   font-size: 1.1rem;
   font-weight: 600;
   margin-top: 1vmin;
}

.container>:nth-child(4){
    margin-top: 1rem;
    font-size: 0.9rem;
    line-height: 1.6rem;
    text-align: justify;
}


@media screen and (width>=700px) {
    .container{
        height: 60vh;
        padding-right: 16px;
    } 
}

@media screen and (width<700px) {
    .container{
        height: 80vh;
        padding-right: 6px;
    } 
}
.graph-content{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(20rem,1fr));
    gap: 2rem;
    row-gap: 3.5rem;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    /* grid-template-rows: 30rem; */
    grid-auto-rows: 31rem;
}
.employeeContainer{
border-radius: 10px;
height: 100%;
margin-top: 1rem;
}
.mainDiv{
display: flex;
flex-direction: column;
gap: 1rem;
}

.addAssoicateForm{
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    max-height: 70vh;
    overflow-y: scroll;
    padding: 0 0.2rem; 
}

@media screen and (width<=2000px) and (width>1700px) {
    .labelHeading{
        font-family: 'Lato';
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #878787;
    } 
    .headingAssociate{
        font-family: 'Lato';
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        color: #162432; 
    } 
}
@media screen and (width<=1700px) and (width>1500px) {
    .labelHeading{
        font-family: 'Lato';
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #878787;
    } 
    .headingAssociate{
        font-family: 'Lato';
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #162432; 
    }  
}
@media screen and (width<=1500px) and (width>1300px) {
    .labelHeading{
        font-family: 'Lato';
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #878787;
    }
    .headingAssociate{
        font-family: 'Lato';
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #162432; 
    }   
}
@media screen and (width<=1300px) and(width>800px) {
    .labelHeading{
        font-family: 'Lato';
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #878787;
    }  
    .headingAssociate{
        font-family: 'Lato';
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #162432; 
    } 
}
.tabContainer{
    display: flex;
    justify-content: center;
    gap: 24px;
    color: #999999;
    font-size: 17px;
    font-weight: 500;
}
.tabContainer>*{
    cursor: pointer;
}
.activeTab{
    color: #0086FF;
    border-bottom: 3px solid #0086FF;
    font-size: 17px;
    font-weight: 500;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

}
.historyContainer{
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;
    max-height: 60vh;
    overflow-y: scroll;
    padding-right: 1rem;
}
.headerblue{
    margin-top: 10px;
    color: #0086FF;
}
.headergrayH{
    color: #16243280;
    font-weight: 400;
}
.disabled{
    pointer-events: none;
    opacity: 0.4 !important;
}

.tableRowWhite{
    background-color: #fff;
    color: #162432;
  }

  .tableRow{
    background-color: #F9F9F9;
    color: #162432;
  }
  .ListContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 0rem 2rem;
  }
  .ListHeader{
    padding:  1rem 0rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2.2fr 0fr 1.1fr 1fr 1fr 1fr 1.1fr;
    /* grid-template-columns: repeat(8,1fr); */
    gap: 0.5rem;
  }
  .ListHeader2{
    padding:  1rem 0rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1.9fr 0fr 1.1fr 0.8fr 0.8fr 1fr 1.1fr;
  }
  .ListHeader>*, .ListHeader2>*{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #162432;
    font: normal 600 16px/22px lato;
  }
  .ListHeader>:nth-last-child(1), .ListHeader2>:nth-last-child(1){
    justify-content: center;
  }
  .ListHeader>:nth-child(1), .ListHeader2>:nth-child(1){
    padding-left: 0.7rem;
  }
  .ListHeader>:nth-child(4), .ListHeader2>:nth-child(4){
    padding-left: 0.5rem;
  }
  .ListBodyContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    
  }
  .rowContainer{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 2.2fr 0fr 1.1fr 1fr 1fr 1fr 1.1fr;
    /* grid-template-columns: repeat(8, 1fr); */
    gap: 0.5rem;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    height: 5rem;
    box-shadow: 0 2px 6px rgba(0, 0,0,0.2);
  }
  .rowContainer2{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 2.2fr 0fr 1.1fr 0.8fr 1fr 1fr 1.1fr;
    gap: 0.5rem;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    height: 5rem;
    box-shadow: 0 2px 6px rgba(0, 0,0,0.2);
  }

  .rowContainer:hover, .rowContainer2:hover{
    box-shadow: 0 8px 12px rgba(146, 153, 151, 0.2);
    /* background-color: rgba(150, 244, 213,0.6); */
    transform: scale(0.99);
    transition: .6s ease-in-out;
    padding: 0rem 0.5rem;
  }
  .rowContainer>*, .rowContainer2>*{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #16243280;
    height: 5rem;
    font: normal 600 16px/22px lato;
    justify-content: flex-start;
  }
  .rowContainer>:nth-last-child(1), .rowContainer2>:nth-last-child(1){
    justify-content: center;
  }
  .rowContainer>:nth-child(1), .rowContainer2>:nth-child(1){
    padding-left: 0.5rem;
  }

  .ListBodyContainer::-webkit-scrollbar{
    display: none;
  }


  
  @media screen and (width>=700px){
    .headerApprover{
      font:normal 600 20px/36px Poppins;
    }
    .contentBody{
      display: grid;
      grid-template-columns: repeat(2,1fr);
      row-gap: 8px;
      padding: 12px 0px;
      
    }
    .contentBody>*{
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .Hlist{
      border: 1px solid #1624321a;
      padding: 10px;
      border-radius: 10px;
      box-shadow: 0px 4px 6px rgba(0,0,0,0.15);
    }
    .Hlist:hover{
      border: 1px solid #16243230;
      transform: scale(0.98);
      transition: .6s ease-in-out;
    }
    .ActiveModal{
      top: 25vh;
      width: 70vw;
      padding: 12px;
    }
    .DeleteModal{
      top: 25vh;
      width: 70vw;
      padding: 12px;
    }
    .AssignLeaveModal{
      width: 70vw;
      padding: 10px;
    }
    .AssignDeptModal{
      width: 70vw;
      padding: 10px;
      top:10vh;
    }
    .addAssociateModal{
      width: 70vw;
      height: 82vh;
      top: 10vh;
    }
    .MobViewList{
      display: none;
    }
    .AssociateHeaderAndSearchAdd{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0rem 2rem;
    }
    .FilterLineMobView{
      display: none;
    }
    .FilterLineWebView{
      display: flex;
      gap: 10px;
      align-items: center;
    }
    .ModalAssociateHeader{
      display: flex;
      justify-content: flex-start;
      margin-left: -5rem;
      width: 100%;
    }
    .HistoryModal{
      width: 60vw;
      top: 20vh;
    }
  }
  @media screen and (width<700px) {
    .headerApprover{
      font:normal 600 16px/20px Poppins;
    }
    .contentBody{
      display: flex;
     flex-direction: column;
     gap: 8px;
      padding: 12px 0px;
      
    }
    .contentBody>*{
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .Hlist{
      border: 1px solid #1624321a;
      padding: 10px;
      border-radius: 10px;
      box-shadow: 0px 4px 6px rgba(0,0,0,0.15);
    }
    .Hlist:hover{
      border: 1px solid #16243230;
      transform: scale(0.98);
      transition: .6s ease-in-out;
    }
    .HistoryModal{
      width: 95vw;
      top: 10vh;
    }
    .addAssoicateForm::-webkit-scrollbar{
      display: none;
    }
    
    .ActiveModal{
      top: 15vh;
      width: 95vw;
      padding: 12px;
    }
    .DeleteModal{
      top: 15vh;
      width: 95vw;
      padding: 12px;
    }
    .AssignLeaveModal{
      width: 95vw;
      padding: 10px;
    }
    .AssignDeptModal{
      width: 95vw;
      padding: 10px;
     
    }
    .addAssociateModal{
      width: 95vw;
      height: 82vh;
      top: 10vh;
    }
    .SearchDiv{
      width: 80%;
    }
    .FilterLineMobView{

      display: flex;
      gap: 10px;
      align-items: center;
    }
    .AssociateHeaderAndSearchAdd{
      display: flex;
      flex-direction: column;
      padding: 0rem 1rem;
    }
    .FilterLineWebView{
      display: none;
    }
    .WebViewList{
       display: none; 
    }
    .MobViewList{
      background-color: #f8f8f8;
      display: flex;
      flex-direction: column;
      height: 85vh;
     
    }
    .ListContainerMob{
      padding: 1rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
    }
    .MobListBody{
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1rem;
      border-radius: 10px;
      background-color: #fff;
    }
    .listLine{
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
    .ModalAssociateHeader{
      display: flex;
      justify-content: flex-start;
      margin-left: -1rem;
      width: 100%;
    }
  }
  @media screen and (height>900px) {
    .ListScroll{
      max-height: 65vh;
      overflow-y: scroll;
      width: 100%; 
     }
     .ListScrollMob{
      max-height: 75vh;
      overflow-y: scroll;
      width: 100%; 
     }
  }
  @media screen and (height<900px) and (height>=750px) {
    .ListScroll{
      max-height: 58vh;
      overflow-y: scroll;
      width: 100%; 
     }
     .ListScrollMob{
      max-height: 70vh;
      overflow-y: scroll;
      width: 100%; 
     }
  }
  @media screen and (height<750px) and (height>=600px) {
    .ListScroll{
      max-height: 50vh;
      overflow-y: scroll;
      width: 100%; 
     }
     .ListScrollMob{
      max-height: 65vh;
      overflow-y: scroll;
      width: 100%; 
     }
  }
  @media screen and (height<600px) {
    .ListScroll{
      max-height: 45vh;
      overflow-y: scroll;
      width: 100%; 
     }
     .ListScrollMob{
      max-height: 60vh;
      overflow-y: scroll;
      width: 100%; 
     }
  }
@media screen and (width>=700px) {
  .primaryButton {
    background: #0086ff;
    border: 1.3px solid #0086ff;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    padding: 0.5rem 2rem;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .primaryButton:hover {
    background-color: #0880ea;
    border: 1.3px solid #0880ea;
  }
  .primaryButton:disabled{
    cursor: not-allowed;
    background-color: rgb(241, 240, 240);
    color: #A0A0A0;
    border: 1.3px solid #A0A0A0;
  }
  
  .secondaryButton {
    border-radius: 10px;
    padding: 0.5rem 2rem;
    border: 1.3px solid #0086ff;
    border-radius: 5px;
    outline: none;
    color: #0086ff;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .secondaryButton:hover {
    background-color: rgb(235, 234, 234);
  }
  
  .downloadButton {
    border-radius: 10px;
    padding: 0.5rem 1.5rem;
    border: 0;
    border-radius: 10px;
    outline: none;
    color: #ffffff;
    cursor: pointer;
    background: #21ba45;
  }
  
  .downloadButton:hover {
    background: #24d04c;
  }
  
  .resetButton {
    /* width: 5rem;
    height: 2rem; */
    padding: 0.5rem 1rem;
    background: #FF3232;
    border-radius: 10px;
    padding: 0.5rem 2rem;
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    font-size: 1rem;
  
  }
  .resetButton:hover{
    background-color: #FF1000;
  }
  
  .resetButton:disabled{
    cursor: not-allowed;
    background-color: rgb(241, 240, 240);
    color: #A0A0A0;
    border: 1.3px solid #A0A0A0;
  }
  
  .selectContainer {
    max-width: 15rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border: 1px solid #ececec;
    border-radius: 5px;
    height: 2rem;
    position: relative;
  }
  
  .selectBox {
    border: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    /* z-index: 999; */
  }
  
  .selectBox select:focus {
    border-color: none !important;
    /* z-index: 999; */
  }
  
  .iconContainer {
    position: absolute;
    right: 10px;
    pointer-events: none;
  }
  
  .selectBox > option {
      width: 10rem;
      /* background: blue; */
  }
  
  .backButton{
    padding: 0.5rem 1.5rem;
    background: #0FB3FF;
    cursor: pointer;
    border: 0;
    outline: 0;
    color: #fff;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    border-radius: 10px;
  }
}


@media screen and (width<700px) {
  .primaryButton {
    background: #0086ff;
    border: 1.3px solid #0086ff;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .primaryButton:hover {
    background-color: #0880ea;
    border: 1.3px solid #0880ea;
  }
  .primaryButton:disabled{
    cursor: not-allowed;
    background-color: rgb(241, 240, 240);
    color: #A0A0A0;
    border: 1.3px solid #A0A0A0;
  }
  
  .secondaryButton {
    border-radius: 10px;
    padding: 0.5rem 1rem;
    border: 1.3px solid #0086ff;
    border-radius: 5px;
    outline: none;
    color: #0086ff;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .secondaryButton:hover {
    background-color: rgb(235, 234, 234);
  }
  
  .downloadButton {
    border-radius: 10px;
    padding: 0.5rem 1rem;
    border: 0;
    border-radius: 10px;
    outline: none;
    color: #ffffff;
    cursor: pointer;
    background: #21ba45;
  }
  
  .downloadButton:hover {
    background: #24d04c;
  }
  
  .resetButton {
    /* width: 5rem;
    height: 2rem; */
    padding: 0.5rem 1rem;
    background: #FF3232;
    border-radius: 10px;
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    font-size: 1rem;
  
  }
  .resetButton:hover{
    background-color: #FF1000;
  }
  
  .resetButton:disabled{
    cursor: not-allowed;
    background-color: rgb(241, 240, 240);
    color: #A0A0A0;
    border: 1.3px solid #A0A0A0;
  }
  
  .selectContainer {
    max-width: 15rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border: 1px solid #ececec;
    border-radius: 5px;
    height: 2rem;
    position: relative;
  }
  
  .selectBox {
    border: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    /* z-index: 999; */
  }
  
  .selectBox select:focus {
    border-color: none !important;
    /* z-index: 999; */
  }
  
  .iconContainer {
    position: absolute;
    right: 10px;
    pointer-events: none;
  }
  
  .selectBox > option {
      width: 10rem;
      /* background: blue; */
  }
  
  .backButton{
    padding: 0.5rem 1.5rem;
    background: #0FB3FF;
    cursor: pointer;
    border: 0;
    outline: 0;
    color: #fff;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    border-radius: 10px;
  }
}



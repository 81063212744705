.container{
    display: flex;
    flex-direction: column;
    gap: 2vmin;
    overflow-y: auto;
    height: 70vh;
    padding-right: 0.5vw;
}
.container>:nth-child(1){
    font-size: 1.4rem;
    font-weight: 600;
}
.container>:nth-child(2){
    color: #3D3D3D;
    font-weight: 500;
    font-size: 0.9rem;
    text-align: justify;
}
.container>:nth-child(3){
  color: #808080;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 2vmin;
}



/* .images>* img{
    width: 100%;
    height: 100%;
    object-fit:cover;
    cursor: pointer;
} */



.videos>* video{
  width: 100%;
  height: 100%;
  object-fit:fill;
  cursor: pointer;
}

.container>:nth-child(5){
    color: #808080;
      font-weight: 500;
      font-size: 1rem;
      margin-top: 2vmin;
  }

  .backDiv{
    display: flex;
    align-items: center;
    gap: 1vw;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
  }
  .backDiv>:nth-child(1){
    width: 1rem;
    height: 1rem;

  }
  .file{
    overflow-y: auto;
    /* height: 70vh; */
    padding-right: 0.5vw;
    display: flex;
    flex-direction: column;
  }
 
  .file img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

 
  .file>:nth-child(3)>*{
    display: flex;
    align-items: center;
    gap: 0.6vw;
    cursor: pointer;
  }

  @media(max-width:700px)
  {
    
 
  .file>:nth-child(2){
    margin:0;
  }
 
  }

  @media screen and (width>=700px) {
    .file>:nth-child(2){
      display: flex;
      flex-direction: column;
      height: 55vmin;
    }
    .images{
     display: flex;
     height: min-content;
  }
  .videos{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 2vmin;
    grid-auto-rows: 12vmin;
   
  }
  .file>:nth-child(3){
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2vmin;
    color: #808080;
    font-weight: 500;
    font-size: 1rem;
  }
  .container{
    height: 70vh;
    padding-right: 12px;
}
.file{
  height: 70vh;
}
  }
  
  @media screen and (width<700px) {
    .file>:nth-child(2){
      display: flex;
      flex-direction: column;
      height: 55vmin;
    }
    .file{
      /* height: 80vh; */
    }

  

  .videos{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    row-gap: 10px;
      column-gap: 10px;
   
  }
  .file>:nth-child(3){
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2vw;
    margin-top: 2vmin;
    color: #808080;
    font-weight: 500;
    font-size: 1rem;
  }
  .container{
    height: 80vh;
    padding-right: 6px;
}
  }

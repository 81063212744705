.attendenceContainer{
    display: flex;
    height: 90vh;
    flex-direction: row;
}
.sider{
    width: 18%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 90vh;
    gap: 3rem;
    align-items: center;
    /* justify-content: space-between; */
    padding: 1.5rem 0;
    border-right: 1px solid #DFDFDF;
}

.sider>*:nth-child(1){
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    padding: 0.9rem 1rem;
    background-color: #DBF4FF;
    color: #0086FF;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    border-radius:10px ;
    width: 90%;
    border-right:5px solid #0086FF ;
}

.sider>*:nth-child(2){
  margin-top: auto;
}

.sider>*:nth-child(1) img{
    width: 2rem;
    height: 2rem;
}

.mainContent{
    width: 82%;
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
}
.cardContainer{
    display: flex;
    flex-direction: row;
    gap: 1rem;

}
.attendenceCard {
    box-sizing: border-box;
    /* height: 12vh;
    width: 11.2vw; */
    /* padding: 10px 0px; */
    display: flex;
    justify-content: center;
    padding-left: 1rem;
    font-size: 14px;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    gap: 0.4rem;
    /* padding: 40px; */
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 5px;
    border:1.3px solid #DFDFDF;
}
*{
    font-family: 'Lato';
}
.attendenceCard>:nth-child(1){
    color: rgba(22, 36, 50, 0.50);
    font-size: 14px;
}

.attendenceCard>:nth-child(2){
    font-size:1.1rem;
    color: #162432;
    font-weight: 600;
}
.ohdContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
    overflow-y: scroll;
    background-color: #fff;
    padding:16px;
    margin-right: 2rem;
    border-radius: 8px;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.ohdContainer>*{
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;
    height: 80px;
    /* width: 200px; */
    padding: 20px 0px;
}
.ohdContainer>*>*:nth-child(1){
    box-sizing: border-box;
    height: 80px;
    width: 100px;
    background-color: #F8F8F8;
}
.attendenceCalender{
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    /* border: 2px solid red; */
    border-radius: 10px;
    border: 1px solid #DFDFDF;
    background: #FFF;
}
.attendenceCalender>*:nth-child(1){
    padding:0.5rem 1rem;
    gap: 1.5rem;
    display: flex;
    border-bottom:1px solid #DFDFDF;
}

.attendenceCalender>*:nth-child(1)>*:nth-child(1), .attendenceCalender>*:nth-child(1)>*:nth-child(3){
    cursor: pointer;
    color: #979797;
    font-size: 1rem;
    font-weight: 500;
}
.attendenceCalender>*:nth-child(1)>*:nth-child(2){
    color: #0086FF;
    font-size: 1rem;
    font-weight: 500;
}

.attendenceCalender>*:nth-child(3){
    /* display: flex; */
    /* border: 2px solid red; */
   flex: 1 1 100%;
    height: 100%;

}
.attendenceCalender>*:nth-child(4){
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 40vw;
}
.weekDays{
    display: grid;
    margin-bottom: 1rem;
    grid-template-columns: repeat(7,1fr);
    background-color: #F9F9F9;
    padding: 0.5rem 1rem;
    color: rgba(22, 36, 50, 0.50);
}
.weekDays>*{
    display: flex;
    justify-content: center;
    align-items: center;
}
.calenderDays{
    display: grid;
   /* background-color: red; */
    grid-template-columns: repeat(7, 1fr);
    /* grid-row-gap:5px; */
    /* width: 100%; */
    flex: 1 1 100%;
    gap: 1.5vh;
    /* margin-left: 2rem; */
    /* border: 2px solid; */
    padding-left: 3vw;
}
.calenderDays>*{
    height: 30px;
    width: 30px;
    /* border: 2px solid red; */
    /* width: 35px; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
}
.tm{
    border: 2px solid;
}
.kk{
    border: 1px red;
}
.logCards{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    margin-top: 0.5rem;
    /* margin-bottom: 60px; */
    /* border: 2px solid; */
}
.logCards >*{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    height: 21vh;
    align-items: center;
    width: 9rem;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    /* border: 2px solid; */
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.16);
}
.disabled{
    pointer-events: none;
    opacity: 0.4 !important;
}

@media(max-width:800px)
{
    .mainContent{
        width: 100%;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        /* gap: 0.9rem; */
    }
    ::-webkit-scrollbar {
        width: 1px;
        height: 2px;
        border-radius: 10px;
        display: block;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgb(253, 252, 252); 
      }
      ::-webkit-scrollbar-thumb {
        background: rgb(160, 160, 160);
        height: 10px;
      }
    .sider{
        width: 100%;
        border: 0;
        height: 60vh;
    }
    .sider>*:nth-child(1){  
        padding: 0rem 1rem;  
        width: 100%;
    }
    .cardContainers2{
        display: grid;
        grid-template-columns: repeat(3, 1fr); 
        width: 100%; 
        background-color: #FFFFFF;
    }
    .cardContainers2>*{
        /* width: 6rem; */
        height: 5rem;
        border-radius: 0;
        display: flex;
        align-items: center;
    }
    .calenderDays>*{
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
        font-weight: 600;
    }
    .logCards{
        display: flex;
        /* justify-content: center; */
        align-items: center;
        flex-direction: column;
        gap: 1.5rem;
        margin-top: 0.5rem;
        overflow: auto;
        width: 100%;
        /* height: 40vh; */
        /* margin-bottom: 60px; */
        /* border: 2px solid; */
    }
    .logCards >*{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: center;
        height: 13vh;
        align-items: center;
        width: 10rem;
        border: 1px solid #E6E6E6;
        border-radius: 10px;
        /* border: 2px solid; */
        box-shadow: 0 4px 4px 0 rgba(0,0,0,0.16);
    }
}






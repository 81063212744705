* {
  box-sizing: border-box;
}

.p-2 {
  padding: 2px;
}

.p-3 {
  padding: 3px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-l-50 {
  padding-left: 50px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-t-7 {
  padding-top: 7px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-25 {
  padding-top: 25px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-t-40 {
  padding-top: 40px;
}

.p-t-50 {
  padding-top: 50px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-r-25 {
  padding-right: 25px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-r-50 {
  padding-right: 50px;
}

.p-b-3 {
  padding-bottom: 3px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-b-25 {
  padding-bottom: 25px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-b-50 {
  padding-bottom: 50px;
}

.r-jc {
  display: flex;
  justify-content: center;
}

.r-ac {
  display: flex;
  align-items: center;
}

.r-afs {
  display: flex;
  align-items: flex-start;
}

.r-jsb {
  display: flex;
  justify-content: space-between;
}

.r-c-se {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.r-c-fe {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.r-fe-fe {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.r-c-fs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.r-c-sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.r-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

.r-c-sa {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.r-c-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.r-c-sa-c {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.r-c-fs-c {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.r-jsb-fc {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.m-0 {
  margin: 0px;
}

.m-10 {
  margin: 10px;
}

.m-b-2 {
  margin-bottom: 2px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-t-2 {
  margin-top: 2px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-t-60 {
  margin-top: 60px;
}

.m-t-100 {
  margin-top: 100px;
}

.m-t-200 {
  margin-top: 200px;
}

.m-l-2 {
  margin-left: 2px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-l-25 {
  margin-left: 25px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-l-40 {
  margin-left: 40px;
}

.m-l-50 {
  margin-left: 50px;
}

.m-r-2 {
  margin-right: 2px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-50 {
  margin-right: 50px;
}

.b-g-card {
  background-color: #fff;
  border-radius: 10px;

}

.font-18 {
  font-size: 18px;
  font-weight: 600;
  font-family: poppins;
}

.font-14 {
  font-size: 14px;
  font-weight: 400;
  font-family: poppins;
}

.scroll-bar-universal {
  overflow: hidden;
}

.scroll-bar-universal:hover {
  overflow: auto;
}

/* Upload Button */

.uploadButton::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  padding: 0.5em;
  border: none;
  border-radius: 3px;
}

.ant-upload-list-item-container {
  text-overflow: ellipsis;
  width: 120px;
}

/* Logs Table Style */

.row-style {
  text-align: center;
  background-color:#f9f9f9 ;
  width: 98%;
}
.inputSearch{
    font-family: Poppins;
    font-size: 13px;
    line-height: 1.5;
    border: none;
    background: #FFFFFF;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20'  height='20' viewBox='0 0 20 20'><path fill='%23838D99' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
    background-repeat: no-repeat;
    background-position: 10px calc(50% + 1px);
    background-size: 20px 20px;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .5em 1em .5em 2.5em;
    border: 1px solid #BEBEBE;
    color: #BEBEBE;
} 
.input-elevated::placeholder{
    color: #BEBEBE;
    }   
    
.input-elevated:focus {
      border: none;
      outline: none;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16);
    }
    input:focus-visible{
        outline: none;
        border: none;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16);
    }


.searchContainer {
        position: relative;
      }
      
.inputSearch {
        width: 100%;
        padding-right: 2rem; /* Space for the clear button */
      }
      
.clearButton {
        background: none;
        border: none;
        cursor: pointer;
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.2rem; /* Adjust size as needed */
        color: #333;
        padding: 0;
        line-height: 1;
      }
      
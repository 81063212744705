.searchDiv{
    height: 2.1rem;
    width:17rem;
    gap:0.3rem;
    display: flex;
    border-radius: 5px;
    flex-direction: row;
    background-color: white;
    align-items: center;
    border: 1.5px solid #e0e0e0;
    }
    .searchDiv input{
      width:26rem;
      min-height: 30px;
      background: none;
      border: 0;
      outline: 0;
    }
    @media screen and (max-width: 700px){
      .searchDiv{
        /* height: 2.45rem; */
        /* width:60vw; */
        gap:0.3rem;
        display: flex;
        border-radius: 5px;
        flex-direction: row;
        background-color: white;
        align-items: center;
        border: 1.5px solid #e0e0e0;
        font-size: 17px;
      }
      .searchDiv input{
        /* width:50vw; */
        /* height: 32px; */
        background: none;
        border: 0;
        margin-left: 0.5rem;
        outline: 0;
        font-size: 17px;
      }
    }
    
    .searchDiv input:active,
    .searchDiv input:focus{
     outline: none;
     border:none;
     background: none;
     border-radius: 0;
     box-shadow: none;
    }
    
    .searchDiv img{
      width:20px;
      height:20px;
      margin-left: 1rem;
      opacity: 0.6;
    }
    .disabled{
      pointer-events: none;
      opacity: 0.4 !important;
}
.ListHeader{
  width: 100%;
}
.ListHeader>*{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* color: #162432; */
  /* font: normal 600 16px/22px lato; */
}
.ListContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-right: 0.6rem;
  padding-left: 0.6rem;
  background-color: #F9F9F9;
  margin-top: 0.5rem;
}
.ListBodyContainer::-webkit-scrollbar{
  display: none;
}
.ListBodyContainer{
  width: 100%;
}
.rowContainer{
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: white;
  margin-bottom: 1rem;
  border-radius: 5px;
  height: 4rem;
  box-shadow: 0 2px 6px rgba(0, 0,0,0.2);
}
.rowContainer>*{
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
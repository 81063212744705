.Cards{
    display:flex;
    flex-direction:row;
    width:100%;
    gap:0.8rem;
}
.Cards>*{
    background-color:#F4F4F4;
    border-radius:7px;
    color:#808080;
    height:4.5rem;
    width: 9rem;
    margin-top: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Slots{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 0.8rem;
    margin-top: 0.4rem;
    flex-direction: flex-start;
    font-size: 12;
}
.Slots>*:nth-child(1){
    display: flex;
    flex-direction: column;
}
.Slots>*{
    background-color: #F4F4F4;
    padding: 0.4rem 0.5rem;
    border-radius: 5px;
    /* margin-right: 0.1rem; */
    font-size: 12;
}

@media screen and (width>=700px) {
    .contactUsModal{
        width: 40vw;
    }
    .modalConatiner{
        padding: 10px 20px;
    }
    .header3{
        font: normal normal normal 16px/19px Figtree;
    }
    .title3{
        font: normal normal normal 16px/19px Figtree;
    }
    .header1{
        font: normal normal 600 22px/30px Figtree;
    }
    .header2{
        font:normal 600 16px/22px figtree;
    }
    .title1{
        font:normal normal normal 18px/22px Figtree;
    }
    .title2{
        font:normal normal normal 14px/19px Figtree;
    }
}
@media screen and (width<700px) {
    
.header1{
    font: normal normal 600 24px/30px Figtree;
}
.header2{
    font:normal 600 16px/22px figtree;
}
.title1{
    font:normal normal normal 14x/18px Figtree;
}
.title2{
    font:normal normal normal 12px/15px Figtree;
}
    .contactUsModal{
        width: 95vw;
    }
    .modalConatiner{
        padding: 5px 8px;
    }
    .header2{
        font: normal normal 600 20px/24px Figtree;
    }
    .header3{
        font: normal normal normal 12px/14px Figtree;
    }
    .title3{
        font: normal normal normal 14px/16px Figtree;
    }
}
.modalHeader {
    font-size: 1.2rem;
}

.detailsModal {
    width: 100%;
    padding: 1rem;
}


.detailsRow {
    border: 1px solid #EDEDED;
    margin-top: 15px;
    padding: 0.5rem 1rem;
}

.detailsRow div {
    display: flex;
    justify-content: space-between;
    padding: 2px 10px;
}

.footer {
    margin: 1.5rem auto 0 auto;
    width: 50%;
    display: flex;
    justify-content:center;
    padding: 10px 0px;
    gap: 0.5rem;
}

.infoRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logsTable>*>*>*:nth-child(1)>*>*:nth-child(1)>*>*>*>*>*{
    font-size: 0.85rem;
}
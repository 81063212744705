.deleteModal{
    display: flex;
    flex-direction: column;
    margin: 0rem 0 1rem 0;
    align-items: center;
    width: 55vw;
}

.deleteModal>*:nth-child(1){
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.deleteModal>*:nth-child(1)>*{
    color: #0086ff;
}

.header{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap:0.5rem;
}

.buttonDiv{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

@media(max-width:800px)
{
    .deleteModal{
        width: 85vw;
    }
}
.addnews{
    display: flex;
    flex-direction: column;
}


.modalHeader{
    display: flex;
   font-weight: 600;
    width: 100%;
    justify-content: left;
    font-size: 1.4rem;
}
.modalHeader h3{
    font-weight: 600;
    font-size: 1.4rem;
}

.addNewsForm{
    overflow-y: auto;
    height: 75vh;
    width: 60vw;
    margin:0 1rem 1rem 1rem;
    padding:0 0.5rem 1rem 0;
}

.addNewsForm::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    display: block;
  }
.addNewsForm::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(209, 205, 205); 
    border-radius: 10px;
  }
.addNewsForm::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
    height: 10px;
  }

.buttonDiv{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
}

.itemRender{
    width: 20rem;
    
}


.itemRender>*:nth-child(2){
    display: none;
}

.itemTitle{
    font-weight: 600;
}

/* .addNewsForm>*>*>*>*:nth-child(1)>*:nth-child(1)>*{
    border-radius: 10px;
    border: 1.33px solid #E6E6E6;
    background: #FFF
} */

.addNewsForm>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1)>*:nth-child(1)>*{
    border-radius: 10px;
    border: 1.33px solid #E6E6E6;
    background: #FFF;
}

.addNewsForm>*:nth-child(2)>*:nth-child(1)>*>*:nth-child(1)>*:nth-child(1)>*{
    border-radius: 10px;
    border: 1.33px solid #E6E6E6;
    background: #FFF;
}
.addNewsForm>*:nth-child(4)>*:nth-child(1)>*>*:nth-child(1)>*:nth-child(1)>*{
    border-radius: 10px;
    border: 1.33px solid #E6E6E6;
    background: #FFF;
}

@media(max-width:700px)
{
    .addNewsForm{
        height: 75vh;
        width: 85vw;
    }
}


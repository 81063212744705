
.container{
    display: flex;
    flex-direction: column;
    /* border: 2px solid red; */
    margin: 4vmin auto 2vmin auto;
    width: 70%;
}
.container>:nth-child(1){
    display: flex;
    align-items: center;
    gap: 1vw;
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
    cursor: pointer;
}
.container>:nth-child(2){
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1vmin;
}
.container>:nth-child(1) img{
    width: 1rem;
    height: 1rem;
}
.newsletterItems{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2vw;  
    height: 73vh;
    overflow-y: auto;
    padding-right: 0.5vw;
}

.eachNews{
    display: flex;
    flex-direction: column;
    /* gap: 1vmin; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    padding: 2vmin;
    height:100%;
    cursor: pointer;
}
.eachNews>:nth-child(1){
    display: flex;
    height: 20vmin;
}
.eachNews>:nth-child(1) img{
    display: flex;
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
}
.eachNews>:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.7rem;
    color: #808080;
    font-weight: 550;
    margin: 1vh 0;
    
}

.eachNews>:nth-child(3){
    color: #191919;
    font-size: 0.85rem;
    font-weight: 600;
}
.eachNews>:nth-last-child(1){
    font-size: 0.85rem;
    margin-top: 0.5rem;
}

@media(max-width:700px)
{
    .container{
        margin: 2rem 0;
        width: 100%;
        padding: 1rem;
    }
    .newsletterItems{
        grid-template-columns: repeat(1,1fr);
        height: auto;
        padding-right: 0;
        gap: 1rem;
    }
    .eachNews>:nth-child(1){
        height: 20vh;
    }
   
}

.present{
    color: white;
    /* background-color: #0086FF; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0AAD43;
}
.absent{
    color: white;
    background-color: #F64A3F;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.leave{
    /* color:white ; */
    /* background-color: #FD8600; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FD8600;
}
.advanced{
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #581845;
}
.holiday{
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(100deg, #FDDC7A 25%,red );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.compOff{
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, #0DAC45 30%, #0086FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.today{
    background-color: #0086FF;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.today{
    background-color: purple;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.weekend{
    color: #999999;
}
.S{
    color: #ABABAB;
}
.M,
.T,
.W,
.F{
    color:white;
    background-color: #0086ff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.half-day{
    /* color:white; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #CC6760;
}
/* .manual{ */
   
    /* border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FECC1B; */
/* } */
.outdoor{
    /* color:white; */
    /* background-color: #FB29FF; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: #FB29FF; */
}
.forgot{
    /* color:white; */
    /* background-color: #9747FF; */
    /* color: #9747FF; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.optional{
    /* color:white; */
    color: #34FFDA;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainHeader{
    width: 100%;
    height: 10vh;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    /* border: 2px solid red; */
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    padding: 0 3.5rem 0 2rem;
    border-bottom: 1px solid #dfdfdf;

}

/* style={{
    height: "10vh",
    background: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // borderRadius: "15px",
    padding: "0 3.5rem 0 2rem",
    margin: 0,
    borderBottom:"1px solid #DFDFDF",
    width:"100%",
    border:"2px solid red",
    
  }} */

  @media(max-width:700px)
  {
    .mainHeader{
        padding: 0.5rem 1rem;
    }
  }
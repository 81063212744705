.attendenceContainer{

    display: flex;
    height: 90vh;
    flex-direction: row;
}
.attendenceContainer >*:nth-child(1){
    width: 18%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    background-color: #fff;
    padding-bottom: 3%;
}
.attendenceContainer>*:nth-child(2){
    width: 82%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 2%;
}
.cardContainer{
    display: flex;
    justify-content: space-between;
}
.attendenceCard{

}
.attendenceCard {
    box-sizing: border-box;
    height: 110px;
    width: 170px;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 40px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.ohdContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 50vh;
    overflow-y: scroll;
    background-color: #fff;
    padding:16px;
}
.ohdContainer>*{
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;
    height: 100px;
    width: 300px;
    padding: 20px 0px;

}
.ohdContainer>*>*:nth-child(1){
    box-sizing: border-box;
    height: 100px;
    width: 100px;
    background-color: #F8F8F8; 
   
}
.attendenceCalender{
    box-sizing: border-box;
    height: 500px;
    width: 700px;
    display: flex;
    flex-direction: column;
    gap: 10px; 
}
.attendenceCalender>*:nth-child(1){
    display: flex;
    justify-content: space-evenly;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font:normal 600 20px/30px Poppins;
    padding: 5px 0px;
}
.attendenceCalender>*:nth-child(1)>*{
    cursor: pointer;
}
.attendenceCalender>*:nth-child(1)>*:nth-child(2){
    color: #0086FF;
}
.attendenceCalender>*:nth-child(2){
    /* height: 100%; */
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 40px;
}
.attendenceCalender>*:nth-child(3){
    display: flex;
    justify-content: center;
}
.calenderDays{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-row-gap:5px
}
.calenderDays>*{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal 600 18px/27px Poppins;
}
.tm{
    border: 2px solid;

}
.kk{
    border: 1px red;
}
.logCards{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-bottom: 60px;
}
.logCards >*{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    height: 70px;
    align-items: center;
    width: 140px;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
}

.disabled{
    pointer-events: none;
    opacity: 0.4 !important;
}

.appyLeaveCard{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    gap: 3vw;
    margin: 1.5rem 0 0.5rem 0;
    width: 100%;
}

.tableRowWhite{
    background-color: #fff;
    color: #162432;
    font-weight: 500;
    font-size: 13px;
  }

  .tableRow{
    background-color: #F9F9F9;
    color: #162432;
    font-weight: 500;
    font-size: 13px;
  }
  .ListContainer{
    width: 100%;
    /* padding: 0rem 1.4rem; */
    margin-top: 0.5rem;
    background-color: #F9F9F9;
    overflow-x: auto;
    overflow-y: auto;
    height: 79%;
    box-sizing: border-box;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}
.ListContainer>*{
    width: 12rem;
}
.ListHeader{
    width: 100%;
    display: flex;
    flex-direction: row;
    width: max-content;
    margin-top: 1rem;
    margin-bottom: 1rem;

}
.ListHeader>*{
    display: flex;

}
.rowContainer{
    display: flex;
    width: max-content;
    justify-content: center;
    background-color: white;
    margin-bottom: 1rem;
    border-radius: 5px;
    height: 4rem;
    box-shadow: 0 2px 6px rgba(0, 0,0,0.2);
}
.rowContainer>*{
    display: flex;
    flex-direction: row;
    align-items: center;
}
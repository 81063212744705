.videoModal{
    display: flex;
    margin: 3vmin;
    align-items: center;
    justify-content: center;
    height: 30rem;
    padding: 1rem;
}

@media(max-width:700px)
{
    .videoModal{
        height: 30vh;
        padding: 16px;
    }

}
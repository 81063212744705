.response{
    display: flex;
    flex-direction: column;
    /* border: 2px solid red; */
    background: #F8F8F8;
    padding: 1rem 0 1rem 1rem;
}

.header{
    display: grid;
    padding:0 1rem 1rem 0rem;
    grid-template-columns: 1fr 1fr 1.5fr 0.6fr;
    color: #162432;
    font-size: 15px;
    font-weight: 600;
    gap: 1rem;
}


.header>*:nth-child(1){
    padding-left: 1rem;
}
.header>*:nth-last-child(1){
    padding-right: 1rem;
}

.cardContainer{
    /* border:2px solid red; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 60vh;
    overflow-y: auto;
    padding: 0 0rem 0.5rem 0;
    margin-bottom: 1rem;
}
.cardItem{
    display: grid;
    border-radius: 10px;
    background: #FFF;
    padding: 1rem 0;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1.5fr 0.6fr;
    color: #162432;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
}
.cardItem>*:nth-child(1){
   padding-left: 1rem;
}
.contact{
    display: flex;
    flex-direction: column;
}
.contact>:nth-child(2){
    color: rgba(22, 36, 50, 0.50);
}

.profileDiv{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}
.profileDiv>*:nth-child(2){
    display: flex;
    flex-direction: column;
}
.profileDiv>*:nth-child(2)>*:nth-child(2){
    color: rgba(22, 36, 50, 0.50);
}

.noData{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-weight: 600;
   color: #ABABAB;
}
.mobProfile{
    display: flex;
    justify-content: space-between;
}
.mobCard{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 0px 8px 3px rgba(173, 173, 173, 0.07)
}
.mobCard>*{
    display: flex;
    gap: 1rem;
}
.mobCard>*>:nth-child(1){
    color: rgba(22, 36, 50, 0.50);
}

.mobCard>*>:nth-child(2){
    word-wrap: break-word;
    word-break: break-word;
}

@media(max-height:700px)
{
    .cardContainer{
        height: 53vh;
    }
}
@media(min-height:701px) and (max-height:800px)
{
    .cardContainer{
        height: 57vh;
    }
}
@media(max-width:800px)
{
    .cardContainer
    {
        height: auto;
    }
}
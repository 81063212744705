.center-error-message:where(.ant-form-item) .ant-form-item-explain-error {
  text-align: center;
}

.input-classname {
  /* border-radius: 32px; */
  background-color:#f9f9f9;
  color: black;

}

.wrapper-classname {
  gap: 4px;
  margin-bottom: 24px;
}

@media screen and (width>=1300px) {
  .OtpInputMain{
    justify-content: center;
      gap: 20px;
  }
  .OtpInput{
    background: #f3f3f3;
    border: none;
    height: 40px;
    width: 40px !important;
  } 
} 

@media screen and (width>=700px) {
  .VerifyModal{
    width: 40vw;
  }
  .OtpInputMain{
    justify-content: center;
      gap: 14px;
  }
  .OtpInput{
    background: #f3f3f3;
    border: none;
    height: 36px;
    width: 36px !important;
  }
}
@media screen and (width<700px) {
  .VerifyModal{
    width: 95vw;
  }
  .OtpInputMain{
    justify-content: center;
      gap: 12px;
  }
  .OtpInput{
    background: #f3f3f3;
    border: none;
    height: 30px;
    width: 30px !important;
  }
}

.table_container {
  width: 100%;
  max-height: 100%;
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
}



.header_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  /* border: 1px solid black */
}

.spiner_container {
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid black; */
  /* background: #fff; */
}

.row_parent_container{
    width: 100%;
    max-height: 100%;
    overflow: auto;
    /* border: 2px solid red; */
  /* border: 1px solid black; */
}

.row_container {
  height: inherit;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  /* overflow: scroll; */
  /* border: 1px solid black; */
}

/* .row_parent_container::-webkit-scrollbar {
  display: none;
} */

.pagination_conatiner {
  height: 7vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
}

.eachRow{
  box-shadow: 0 2px 6px rgba(0, 0,0,0.2);
  gap: 0.5rem;
}

.eachRow>*{
  /* border: 2px solid red; */
  display: flex;
  justify-content: left;
  align-items: flex-start;
  text-align: left;
}

/* .eachRow>*:nth-child(1){
  padding-left: 0.5rem;
} */


.eachRow:hover{
  box-shadow: 0 8px 12px rgba(146, 153, 151, 0.2);
  transform: scale(0.99);
  transition: .6s ease-in-out;
  padding: 0rem 0.5rem;
}

.buttonDiv, .message, .header{
    display: flex;
}

.buttonDiv, .header{
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.buttonDiv{
    gap: 1rem;
    margin-top: 1.5rem;
}
.message{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.message>*:nth-child(2){
    font-size: 1rem;
    font-weight: 600;
}

.header{
    gap: 0.5rem;
    padding-bottom: 1rem;
    flex-grow: 1;
}
.header h3{
    color: #39CA6C;
}
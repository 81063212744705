/* .table-row-light {
    background-color: ;
} */
.table-row-dark {
    background-color: red;
}
.table-striped-rows tr:nth-child(2n) td {
    background-color: red;
}
.table-striped-rows thead {
    background-color: red;
}
/* .table-header {
    background-color: red; 
} */
/* .ant-table-thead > tr > th {
    background-color: red; 
} */
.tableRow{
    background-color: #fcfcfc;
    width: 98%;
}
.formRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.editForm{
    width: 80%;
}
.tabContainer{
    display: flex;
    justify-content: flex-start;
    gap: 24px;
    color: #999999;
    width: 100%;
    /* font-size: 17px; */
    font: normal 500 17px/30px Poppins;
}
.tabContainer>*{
    cursor: pointer;
}
.activeTab{
    color: #0086FF;
    border-bottom: 3px solid #0086FF;
    /* font-size: 17px; */
    font: normal 600 17px/30px Poppins;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

}
.buttonDiv{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 2.1rem;
}
.FormDiv::-webkit-scrollbar{
    display: none;
}
.disabled{
    pointer-events: none;
    opacity: 0.4 !important;
}

.tableRowWhite{
    background-color: #fff;
    color: #162432;
    font-weight: 500;
    font-size: 13px;
  }
  
  .tableRow{
    background-color: #F9F9F9;
    color: #162432;
    font-weight: 500;
    font-size: 13px;
  }

  
.input-elevated:focus {
    border: none;
    outline: none;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16);
  }
  input:focus-visible{
      outline: none;
      border: none;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16);
  }

  .disabled{
      pointer-events: none;
      opacity: 0.4 !important;
}

.tableRowWhite{
  background-color: #fff;
  color: #162432;
  font-weight: 500;
  font-size: 13px;
}

.tableRow{
  background-color: #F9F9F9;
  color: #162432;
  font-weight: 500;
  font-size: 13px;
}
.tableRowWhite{
  background-color: #fff;
  color: #162432;
  font-weight: 500;
  font-size: 13px;
}

.tableRow{
  background-color: #F9F9F9;
  color: #162432;
  font-weight: 500;
  font-size: 13px;
}
/* .ListContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0rem 1.4rem;
  margin-left: 0rem;
  /* margin-top: 0.5rem; */
  /* background-color: #F9F9F9; */
/* } */ 
.ListContainer{
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;        
  height: 69vh;
  box-sizing: border-box;
  margin-left: 0.8rem;
  padding-right: 0.8rem;
}
.ListContainer>*{
  width: 12rem;
}
.ListContainer::-webkit-scrollbar {
height: 4px;
}
.ListHeader{
  width: 100%;
  display: flex;
  flex-direction: row;
  width: max-content;
  z-index: 2; 
  position: sticky;
  top: 0;
  padding: 1.6vh 0;
  background-color: #F9F9F9;
}
.ListHeader>*{
  display: flex;
}
.rowContainer{
  display: flex;
  width: max-content;
  justify-content: center;
  background-color: #ffffff;
  margin-bottom: 2vh;
  border-radius: 5px;
  height: 12vh;
  box-shadow: 0 2px 6px rgba(0, 0,0,0.2);
}
.rowContainer>*{
  display: flex;
  align-items: center;
}
.selectedRow {
/* border: 1px solid #0086FF; */
border-radius: 8px;
}
.FixedColumn {
width: max-content;
position: sticky;
left: 0;
background-color:#F9F9F9;
z-index: 1; 
}
.FixedColumns {
width: max-content;
position: sticky;
left: 0;
background-color:#ffffff;
z-index: 1; 
}
.ContainerD{
  /* background-color:#F9F9F9;
  border-radius: 15px;
  margin-left: 1rem;
  height: 100%; */
  /* border: 2px solid red; */
 }
 .mainDiv{
 display: flex;
 flex-direction: column;
 gap: 1rem;
 padding: 24px 30px 24px 30px;
 height: inherit;

 }


.ant-table-thead .ant-table-cell {
  background-color: #fff !important;
  color: rgba(22, 36, 50, 0.50);
  border: 0;
}

.ant-table-wrapper .ant-table-tbody >tr >td{
  border-bottom: 0;
}

.ant-table-wrapper .ant-table-thead >tr>th{
  border: 0;
  color: rgba(22, 36, 50, 0.50);
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  border-radius: 10px;
  display: block;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(253, 252, 252); 
}
::-webkit-scrollbar-thumb {
  background: rgb(160, 160, 160);
  height: 10px;
}
video{
  transform: scale(-1,1);
}




.jdPdf{
    /* border-top: 2px solid rgba(87, 87, 87, 1); */
    position: relative;
    width: 100%;
    /* padding: 4px; */
    padding-right: 4px;
    /* height: 600px; */
    display: flex;
    align-self: center;
    background-color:#fff;
    overflow-y: scroll;
    border: 1px solid #cacaca;
    border-radius: 5px;
}

.jdPdf .react-pdf__Page__canvas{
    width: 100% !important;
    height: 100% !important;
   margin-bottom: 5px;

}
.jdPdf .react-pdf__Page__annotations{
    display: none !important;

}
.tooltiptext{
    height:100%;
    left:4px;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
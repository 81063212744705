.react-pdf__Page__canvas{
    width: 100% !important;
    height: 100vh !important;
  
  }
  
    .react-pdf__Page__textContent.textLayer {
      display: none;
      border: 2px solid blueviolet;
    }
    .Modal {
      position: fixed;
    }
    .Flipbookcontent  .react-pdf__Page__canvas{
      height: 500px !important;
    }
    .ant-carousel .slick-dots {
      margin-left: 0% !important;
      position: absolute;
      left: 50%;
      top: 150%;
      transform: translate(-50%, -50%);
    }
    .react-3d-carousel .slider-container .slider-right div {
      border: none !important;
      color: #000 !important;
     
    }
    .react-3d-carousel .slider-container .slider-left div {
      border: none !important;
      color: #000 !important;
      margin-left: -10px !important;
    }
    .carousel {
      height: 700px;
      perspective: 600px;
      /* transform: translateY(-100px); */
    }
    .carousel .carousel-item {
      cursor: grab;
      width: 400px;
    }
    .carousel .carousel-item:active {
      cursor: grabbing;
    }
    .carousel .carousel-item img {
      width: 100%;
    }
    .carousel .carousel-item h3 {
      background-color: #FFFFFF;
      color: #000000;
      font-size: 2em;
      font-weight: bold;
      margin: -5px 0 0;
      padding: 10px 5px;
      text-align: center;
    }
    .react-3d-carousel .slider-container .slider-right div,
    .react-3d-carousel .slider-container .slider-left div {
      border: none !important;
      color: #000000 !important;
    }
    ::-webkit-scrollbar {
      width: 5px; /* width of the entire scrollbar */
    }
  ::-webkit-scrollbar-thumb {
      background-color: grey; /* color of the scroll thumb */
      border-radius: 20px; /*roundness of the scroll thumb*/
      /* border: 1px solid #B5DBE8;  creates padding around scroll thumb */
    }
    .flipbookConatiner {
      margin-right: 5px;
      height: 400px;
      width: 701px;
      overflow-x: scroll;
      background: linear-gradient(45deg, #0086FF, #fff);
    }
  
 
  /* .flipbookConatinerFullScreenView{
    margin-right: 35px;
    margin-left: -315px;
    height: 550px;
    width: 1150px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 2px solid;
    
  } */
    /* .flipbookConatiner:hover {
      transform: scale(1.05);
      transition: all ease 1s;
    } */
    .emagazineMainContainer {
      margin-top: 5px;
      width: 700px;
      /* border: 1px solid rgb(0, 255, 72); */
    }
    .magazineContainer {
      margin-top: 5px;
      width: 57vw;
      height: 70vh;
      /* display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 2fr));
      row-gap: 10px;
      overflow-y: auto; */
      display: flex;
      justify-content: center;
      /* border: 2px solid red; */
    }
    .emagazineWraper {
      padding:5px 5px;
      height: 55vh;
      width: 770px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 15px;
      overflow-y: scroll;
      overflow-x: hidden;
      /* border: 5px solid ; */
    }
    .magazinecard {
      /* border: 1px solid blue; */
      width: 150px;
      height: 200px;
      /* margin-left: 1rem; */
    margin: 14px;
      border-radius: 3px;
      padding: 1.5rem;
      background: white;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      transition: 0.4s ease-out;
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
    }
    .magazinecard:hover {
      border-radius: 3px;
      padding: 0.5rem;
      transform: scale(1.12);
      z-index: 1;
    }
    .magazinecard:hover:before {
      opacity: 1;
    }
    .magazinecard:hover .info {
      opacity: 1;
      transform: translateY(2px);
    }
    .magazinecard:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.6);
      z-index: 2;
      transition: 0.5s;
      opacity: 0;
    }
    .magazinecard img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      float: left;
      top: 0;
      left: 0;
      border-radius: 3px;
    }
    .magazinecard .info {
      position: relative;
      z-index: 3;
      width: 100%;
      gap: 10px;
      opacity: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transform: translateY(20px);
      /* margin-bottom: 60px; */
      /* transform: translateY(30px); */
      transition: 0.5s;
    }
    .magazinecard .info h5 {
      margin: 0px;
      text-align: center;
    }
    .magazinecard .info p {
      letter-spacing: 1px;
      font-size: 15px;
      margin-top: 8px;
      text-align: center;
    }
    .magazinecard .info button {
      /* outline: none;
          border: none;
          /* background: white; */
      /* padding: 0.6rem; */
      border: none;
      border-radius: 100%;
      width: 25px;
      /* color: black; */
      /* font-weight: bold; */
      cursor: pointer;
      transition: 0.4s ease;
    }
    .magazinecard .info button:hover {
      background: white;
    }
    .magazinecard:hover .info {
      transform: translateY(0px);
      transition: all ease 0.8s;
    }
    /* .react-pdf__Page__canvas {
      width: 350px;
      height:500px !important;
    }
    .pdf__Page__canvasview {
      border: 2px solid red;
      width: 650px;
    } */
  .FlipbookcontentFullview{
    margin-left: 220px;
  }
  .FlipbookcontentFullview.react-pdf__Page__canvas{
    height: 1000px !important;
  }
    .ant-modal-close{
      top:-10px;
      right:-10px;
    }
      /* .ant-modal-content {
        position: relative;
        background-color: #fff;
        border: 2px solid red;
        background-clip: padding-box;
      height: 630px;
        border-radius: 2px;
        box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
        pointer-events: auto;
    } */
    .viewFullScreen {
      color: #000000;
      font-size: 16px !important;
    }
  .viewFullScreen .anticon svg{
    width: 20px !important;
    height: 20px !important;
  }
    .viewFullScreen:hover{
      color: rgb(0, 134, 255);
      cursor: pointer;
    }
    @media screen and (max-width:760px) {
       .flipbookConatiner{
        overflow: hidden;
        width: 100%;
        height: 500px;
      }
      .view-pdf{
        display: none;
      }
      .react-pdf__Document{
        width: 100%;
        /* height: 500px; */
      }
        .stf__item{
        top:1% !important;
        width: 300px !important;
        height: 500px !important;
          }
          .Flipbookcontent{
            height: 100% !important;
          }
    }
    @media screen and (max-width: 700px) {
      .emagazineMainContainer {
        width: 500px;
      }
      .magazineContainer {
        margin-top: 5px;
        width: 320px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        row-gap: 20px;
        overflow-y: auto;
      }
      .magazinecard {
        display: flex;
        justify-content: center;
        align-items: center;
        align-items: center;
        transition: 0.4s ease-out;
        position: relative;
        border-radius: 3px;
        padding: 3rem;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
      }
    }
    @media screen and (max-width: 530px) {
      .emagazineMainContainer {
        width: 320px;
      }
    }
    @media screen and (max-width: 360px) {
      .emagazineMainContainer {
        width: 250px;
        /* border: 2px solid red; */
      }
    }
  
      @media screen and (max-width:430px) {
      .stf__item{
        width: calc(100vw - 65px) !important;
        height: 500px !important;
          }
          .react-pdf__Page__canvas{
            width: calc(100vw - 65px) !important;
            height: 90% !important;
            padding: 10vh 0px;
          }
          .react-3d-carousel .slider-container .slider-left div, .react-3d-carousel .slider-container .slider-right div{
         
            margin-left: 0px !important;
          }
    }
    @media screen and (max-width: 784px) {
      .pdfModal {
        /* border: 2px solid green; */
        width: 250px;
        height: 300px;
      }
      .frontmagazine{
       opacity: 0;
       font-size: 0px !important;
      }
      .viewFullScreen{
        /* opacity: 0; */
        font-size: 3px !important;  
      }
      .backmagazine{
        /* opacity: 0; */
        font-size: 0px !important;
        opacity: 0;
      }
    }
    @media screen and (max-width: 460px) {
      .pdfModal {
        /* border: 2px solid green; */
        width: 150px;
        height: 300px;
      }
      /* .ant-modal-close-x {
        margin: -10px;
      } */
      .page-content {
        /* border: 2px solid orangered; */
        width: fit-content;
        height: 100%;
      }
      /* .ant-modal-close {
        position: absolute;
        top: 11px;
        right: -376px;
        z-index: 10;
        padding: 0;
        color: rgba(0, 0, 0, 0.45);
        font-weight: 700;
        line-height: 1;
        text-decoration: none;
        background: transparent;
        border: 0;
        outline: 0;
        cursor: pointer;
        transition: color 0.3s;
      } */
    }
    .ant-modal-wrap {
        backdrop-filter: blur(4px);
        /* box-shadow: #060606; */
    }
    .emagazineFullScreenpopup {
      position:fixed;
      top:50%;
      left:0px;
      width:0%;
      height:4%;
      transform:translateY(-50%);
      background: linear-gradient(45deg, #0086FF, #fff);
      overflow:hidden;
      z-index:1500;
      overflow-y: hidden;
    }
    .emagazineFullScreenpopup .emagazineFullScreencontent {
      padding:30px 20px;
      width:100%;
      max-width:550px;
      margin:0 auto;
      opacity:0;
      background: linear-gradient(45deg, #0086FF, #fff);
  
    }
    
    .emagazineFullScreenpopup .emagazineFullScreencontent .popup-close-btn {
      position:absolute;
      top:20px;
      right:20px;
      width:40px;
      height:40px;
      text-align:center;
      line-height:35px;
      color:#111;
      font-size:30px;
      cursor:pointer;
      border-radius:50%;
    }
    .emagazineFullScreenpopup .emagazineFullScreencontent .popup-close-btn:hover {
      background:#eee;
    }
    .emagazineFullScreenpopup .emagazineFullScreencontent h1 {
      font-size:40px;
      margin:20px 0px;
    }
    .emagazineFullScreenpopup .emagazineFullScreencontent p {
      margin-bottom:10px;
      color:#555;
    }
    .emagazineFullScreenpopup.active {
      animation: popupAnimation 1500ms ease-in-out forwards;
    }
    .emagazineFullScreenpopup.active .emagazineFullScreencontent {
      opacity:1;
      transition:all 300ms ease-in-out 1500ms;
    }
    @keyframes popupAnimation {
      0% {
        width:0%;
        height:0%;
      }
      50% {
        width:100%;
        height:2px;
      }
      100% {
        width:100%;
        height:100%;
      }
    }
       @media screen and (max-width:760px) {
        .emagazineMainContainer{
          margin-left: "20px";
          width: "300px";
        }
         .magazineContainer {
          margin-top: 5px;
          width: 350px;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(200px, 2fr));
            row-gap: 20px;
         overflow-y: auto;
        }
         .magazinecard{
          display: flex;
          justify-content: center;
          align-items: center;
          align-items: center;
          transition: 0.4s ease-out;
          position: relative;
          border-radius: 3px;
          padding: 3rem;
          box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
        }
        .magazinecard:hover .info {
          transform: translateY(-20px);
          transition: all ease 0.8s;
        }
       }
  @media screen and (max-width:800px) {
    .emagazineWraper{
      width:720px
    }
  }
  @media screen and (max-width:700px) {
    .emagazineWraper{
      width:580px ;
    }
  }
  @media screen and (max-width:600px) {
    .emagazineWraper{
      width:520px
    }
  }
  @media screen and (max-width:540px) {
    .emagazineWraper{
      width:460px
    }
  }
  @media screen and (max-width:500px) {
    .emagazineWraper{
      width:380px
    }
  }
  @media screen and (max-width:400px) {
    .emagazineWraper{
      width:320px;
    }
  }
  @media screen and (max-width:300px) {
    .emagazineWraper{
      width:220px
    }
  }
  
  
  
  
  @media only screen and (max-width:1200px) {
      .emagazineFullScreenpopup{
        height:500px;
      }
    .FlipbookcontentFullView .react-pdf__Page__canvas{
    width: 550px !important;
   
     }
    .FlipbookcontentFullView.react-pdf__Page{
    width: 300px !important;
     }
  
  }
  @media screen and (max-width:1100px) {
      .emagazineFullScreenpopup{
        height:500px;
      }
    .FlipbookcontentFullView .react-pdf__Page__canvas{
    width: 500px !important;
   
     }
    .FlipbookcontentFullView.react-pdf__Page{
    width: 300px !important;
     }
  
  }
  
  
  
  @media only screen and (max-width: 1000px){
    .emagazineFullScreenpopup{
        height:500px;
      }
    .FlipbookcontentFullView .react-pdf__Page__canvas{
    width: 453px !important;
   
     }
    .FlipbookcontentFullView.react-pdf__Page{
    width: 300px !important;
     }
     .viewFullScreen{
      display: none;
     }
  }
  @media only screen and (max-width: 1050px){
    .emagazineFullScreenpopup{
        height:500px;
      }
    .FlipbookcontentFullView .react-pdf__Page__canvas{
    width: 460px !important;
   
     }
    .FlipbookcontentFullView.react-pdf__Page{
    width: 300px !important;
     }
  
  }
  @media only screen and (max-width: 700px){
    
    .FlipbookcontentFullView .react-pdf__Page__canvas{
    width: 95vw !important;
   
     }
    
  }



  
.container{
    width: 98.5%;
    height:100%;
    /* padding: 1rem; */
    /* border:1px solid green; */
    margin-top: 1rem;
    margin-left: 0.5rem;
    position: relative;
}

.titleBlock{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border:1px solid blue */
}

.title{
    font-weight: 700;
    color: #000000;
    font-family: Lato;
    font-size: 1.5rem;
    padding-left: 0.3rem;
    /* border: 1px solid red; */
}

.tab_Container{
    width: 100%;
    height: 100%;
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: flex-end;
    overflow-x: scroll;
    /* border: 1px solid red; */
}
.searchDiv{
    height: 2.1rem;
    width:19rem;
    gap:0.5rem;
    display: flex;
    border-radius: 5px;
    flex-direction: row;
    font-size: 16;
    background-color: white;
    align-items: center;
    border: 1.5px solid #e0e0e0;
    /* margin-bottom: 1rem; */
    /* margin-top: 1rem; */
    }
.searchDiv input{
      width:26rem;
      min-height: 30px;
      background: none;
      border: 0;
      outline: 0;
}
@media screen and (min-height:700px){
    .searchDiv{
        /* height: 2.5rem; */
        /* width:13.4rem; */
        gap:0.5rem;
        display: flex;
        border-radius: 5px;
        flex-direction: row;
        background-color: white;
        align-items: center;
        border: 1.5px solid #e0e0e0;
        }
    .searchDiv input{
          /* width:15rem; */
          /* height: 2.5rem; */
          background: none;
          border: 0;
          outline: 0;
          font-size: 17px;
          margin-left: 0.6rem;
    }
}
.searchDiv input:active,
.searchDiv input:focus{
     outline: none;
     border:none;
     background: none;
     border-radius: 0;
     box-shadow: none;
}
.searchDiv img{
      width:20px;
      height:20px;
      margin-left: 1rem;
      opacity: 0.6;
}
.tab_Container::-webkit-scrollbar{
    display: none;
}
.scrollDesign::-webkit-scrollbar{
    width: 1rem;
}
.action_container{
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    /* border: 1px solid red; */
}
.ListContainer{
    width: 99%;
    overflow-x: auto;
    overflow-y: auto;        
    height: 67vh;
    box-sizing: border-box;
    margin-left: 0.8rem;
    /* padding-right    : 0.8rem; */
    /* border: 1px solid red; */
}
/* .ListContainer>*{
    width: 10rem;
} */
.ListContainer::-webkit-scrollbar {
height: 4px;
}
/* .ListContainer > .bodyScroll {
    overflow-y: auto;
    overflow-x: hidden;
    width: calc(100% - 16px); 
    max-height: calc(69vh - 40px); 
} */
.ListHeader{
    width: 100%;
    display: flex;
    flex-direction: row;
    width: max-content;
    z-index: 2; 
    position: sticky;
    top: 0;
    /* border: 2px solid; */
    padding: 1.6vh 0;
    background-color: #F9F9F9;
}
.ListHeader>*{
    display: flex;
}
.rowContainer{
    width: 100%;
    display: flex;
    width: max-content;
    justify-content: center;
    background-color: #ffffff;
    margin-bottom: 2vh;
    border-radius: 5px;
    height: 12vh;
    box-shadow: 0 2px 6px rgba(0, 0,0,0.2);
}
.rowContainer>*{
    display: flex;
    align-items: center;
}
.selectedRow {
/* border: 1px solid #0086FF; */
border-radius: 8px;
}
.FixedColumn {
width: max-content;
position: sticky;
left: 0;
background-color:#F9F9F9;
z-index: 1; 
}
.FixedColumns {
width: max-content;
position: sticky;
left: 0;
background-color:#ffffff;
z-index: 1; 
}
.FixedColumnsRight {
    width: max-content;
    position: sticky;
    right: 0; 
    left: 0;
    background-color: #ffffff;
    z-index: 1;
}
.FixedColumnRight {
    width: max-content;
    position: sticky;
    right: 0;
    left: 0;
    background-color:#F9F9F9;
    z-index: 1; 
}
.hoveredRow {
background-color: #F9F9F9;
}
.highlightedRow {
background-color: red;
}
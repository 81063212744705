.previewModal{
    display: flex;
   width: 60vw;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
    height: 70vh;
    padding: 1rem 1rem 0 1rem;
    margin-bottom: 1rem;
    background-color: #fff;
}
.header{
    display: flex;
    width: 100%;
    padding-top: 0.3rem;
  font-weight: 600;
  font-size: 1.4rem;
}

.buttonDiv{
    display: flex;
    width: 100%;
    margin-top: auto;
    align-items: center;
    justify-content: center;
}

.documents{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(13rem,1fr));
    gap: 2rem;
    grid-auto-rows: 10rem;
    margin-bottom: 2rem;
   
  
}
.eachItem{
    display: flex;
   flex-direction: column;
   background: rgba(255, 255, 255, 0.80);
   border-radius: 4px;
   padding: 0.5rem;
}

.eachItem>:nth-child(1){
    height: 8rem;
    width: 100%;
    background-color: #ECEEF0;
    cursor: pointer;
    border-radius: 4px;
}
.eachItem>:nth-child(1)>:nth-child(1){
    height: 100%;
    width: 100%;
}

.eachItem>:nth-child(2){
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    bottom: 0;
    color: #000;
    border-radius: 4px;
    align-items: center;
    font-size: 0.8rem;
    padding: 0.5rem ;
}

.eachItem>:nth-child(2) img{
    cursor: pointer;
}

.headerDiv{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 0rem 0 2rem 0;
}
.headerDiv>:nth-child(1){
    font-weight: 600;
}

.headerDiv>*:nth-child(2){
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    border: 1.3px solid #0086FF;
    color: #0086FF;
    outline: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    display: flex;
    font-size: 1rem;
    background-color: #fff;
}

@media(max-width:800px)
{
    .previewModal
    {
        width: 88vw;
        padding: 0;
    }
    .header{
        font-size: 1.2rem;
    }
}

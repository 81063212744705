@media screen and (width>=1200px){
    .BarWidth{
        width: 15px;
    }
    .ColumnGap{
        gap: 15px;
    }
    .BarGap{
        gap: 8px;
    }
}
@media screen and (width>=900px) and (width<1200px){
    .BarWidth{
        width: 13px;
    }
    .ColumnGap{
        gap: 15px;
    }
    .BarGap{
        gap: 7px;
    }
}

@media screen and (width>=700px) and (width<900px) {
    .BarWidth{
        width: 10px;
    }
    .ColumnGap{
        gap: 13px;
    }
    .BarGap{
        gap: 6px;
    }
}
@media screen and (width>=500px) and (width<700px) {
    .BarWidth{
        width: 8px;
    }
    .ColumnGap{
        gap: 11px;
    }
    .BarGap{
        gap: 5px;
    }
}
@media screen and  (width<500px) {
    .BarWidth{
        width: 7px;
    }
    .ColumnGap{
        gap: 9px;
    }
    .BarGap{
        gap: 4px;
    }
}
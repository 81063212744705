.container{
background-color: #f8f8f8;
display: flex;
height: 100%;
flex-direction: column;
/* ;border: 2px solid red */
}



.document{
    display: flex;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid #dfdfdf;
   width: 100%;
   padding: 1rem 2rem 1rem 1.3rem;
   background-color: #fff;
}
.searchDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}
.docButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 0;
}






.tableHeader{
    background: #F8F8F8;
    display: grid ;
    grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr;
    color: #162432;
    font-family: "Lato";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin: 1.5rem 1.5rem 1rem 1.5rem;
    gap: 1rem;
}
.tableHeader2{
    background: #F8F8F8;
    display: grid ;
    grid-template-columns: 1.5fr 1.5fr 1fr;
    color: #162432;
    font-family: "Lato";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin: 1.5rem 1.5rem 1rem 1.5rem;
    gap: 1rem;
}

.tableHeader>*:nth-child(1), .tableHeader2>*:nth-child(1){
    padding-left: 1rem;
}


.card{
    display: grid;
    width: 100%;
    grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr;
    border-radius: 4px;
    align-items: center;
    gap: 1rem;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09); 
}
.card2{
    display: grid;
    width: 100%;
    grid-template-columns: 1.5fr 1.5fr 1fr;
    border-radius: 4px;
    align-items: center;
    gap: 1rem;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09); 
}




.card>*:nth-child(1), .card2>*:nth-child(1){
    padding-left:1rem ;
}


.card span, .card2 span{
    font-size: 14px;
    font-weight: 500;
    color: #162432;
}

/* .card>*:nth-child(1){
    display: flex;
    height: 11rem;
    background-color: #ECEEF0;
    border-radius: 5px;
    background: #F3F3F3;

}
.card>*:nth-child(1)>*{
    width: 100%;
    height: 100%;
    cursor: pointer;
}
*{
    font-family: 'Lato';
}

.card>*:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 600;
} */
.modalHeader{
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.published{
    border-radius: 17px;
    background: rgba(57, 202, 108, 0.15);
    padding: 0.4rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
}
.card .published{
    color: #39CA6C;
}
.unpublished{
    border-radius: 17px;
    background: rgba(255, 52, 52, 0.15);
    padding: 0.4rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
}
.card .unpublished{
    color: #FF3434;
}
.fileName{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}
.deleteDiv{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}
.operation{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}
.select{
    width: 10rem;
}
.mobCard{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 0px 8px 3px rgba(173, 173, 173, 0.07);
    padding: 1rem;
    justify-content: space-between;
}
.mobCard>*:nth-child(1){
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.mobCard>*:nth-child(1)>*{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    /* font-weight: 600; */
}
.mobCard>*:nth-child(1)>*>*:nth-child(1){
    font-size: 1rem;
    color: rgba(22, 36, 50, 0.50);
    
}

@media(max-width:800px)
{
    .document
    {
        /* border: 2px solid red; */
        flex-direction: column;
        padding: 1rem;
    }
    .searchDiv{
        flex-direction: column;
        gap: 1rem;
    }
    .container{
        width: 100vw;
        overflow: none;
        height: auto;
        /* margin: auto; */
        margin-left: 1rem;
        margin: 0;
    }
    .docButton{
        flex-wrap: wrap;
        align-items: baseline;
        justify-content:right;
    }
    .operation{
        justify-content: space-between;
        align-items: flex-start;
    }
    .searchDiv{
        margin:1rem 0 0 0;
    }
    .deleteDiv{
        flex-direction: column;
    }
}

.body_container {
  position: absolute;
  width: 100%;
  height: 99.8vh;
  background: rgb(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  opacity: 0;
  transition: 300ms ease-in;
}

.body_visible {
  transition: 300ms ease-out;
  opacity: 1;
}

.body_container::-webkit-scrollbar {
  display: none;
}

.modal_container {
  width: 30%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(20rem);
  opacity: 0;
  transition: 300ms ease-in;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.show_modal {
  /* right: 0; */
  transition: 300ms linear;
  transform: translateX(0rem);
  opacity: 1;
}

.associate{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.header{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #dfdfdf;
    background-color: #fff;
}
.header>:nth-child(2){
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.tableHeader{
    display: grid;
    grid-template-columns: repeat(7,1fr);
}

.tableHeader{
    padding:  1rem 0rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7,1fr);
    gap: 0.5rem;
  }
 
  .tableHeader>*{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #18181880;
    font: normal 600 16px/22px lato;
  }

  .tableHeader>:nth-child(1){
    padding-left: 0.7rem;
  }


.rowContainer{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 67vh;
    overflow-y: auto;
}

.eachRow{
    display: grid;
    width: 100%;
    grid-template-columns: repeat(7,1fr);
    gap: 0.5rem;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    height: 5rem;
    box-shadow: 0 2px 6px rgba(0, 0,0,0.2);
  }


  .eachRow:hover{
    box-shadow: 0 8px 12px rgba(146, 153, 151, 0.2);
    /* background-color: rgba(150, 244, 213,0.6); */
    transform: scale(0.99);
    transition: .6s ease-in-out;
    padding: 0rem 0.5rem;
  }
  .eachRow>*{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #16243280;
    height: 5rem;
    font: normal 600 16px/22px lato;
    justify-content: flex-start;
  }
 
  .eachRow>:nth-child(1){
    padding-left: 0.5rem;
    flex-direction: column;
    align-items: center;
    flex-direction: row;
    gap: 1vh;
  }
  .eachRow>:nth-child(2){
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }




.content{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    height: 100%;
    background: #F7F7F7 0% 0% no-repeat padding-box;
}
.greyContent{
    color: #18181880;
    font-size: 13px;
}
.recommendations
{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    color: #0086ff;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 500;
}


.mobCard{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 0px 8px 3px rgba(173, 173, 173, 0.07);
}
.mobCard>*{
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    width: 100%;
    /* border: 2px solid red; */
    align-items: center;
}
.mobCard>*>*:nth-child(1){
    color: #18181880;
}
.mobCard>*:nth-child(1)>*:nth-child(2){
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-direction: row;
}

.mobCard>*:nth-child(1)>*:nth-child(2)>:nth-child(2){
    color: #000;
    font-size: 0.95rem;
    font-weight: 500;
}
.mobCard>*:nth-child(2)>*:nth-child(2)>:nth-child(2){
    color: #000;
    font-size: 0.95rem;
    font-weight: 500;
}
.mobCard>*>:nth-child(2)>*{
   word-break: break-word;
}
.mobView{
    display: none;
}

.mobContainer{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media(max-width:700px)
{
    .header{
        flex-direction: column;
    }
    .header>:nth-child(1){
     
      width: 100%;
      font-size: 1.5rem;
    }
    .webView{
        display: none;
    }
    .mobView
    {
       
            display: flex;
            flex-direction: column;
            gap: 1rem;
        
    }
}
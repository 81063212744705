.cardContainer{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 16px 20px;
}
.card{
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid rgba(221, 221, 221, 0.87);
    background: #FFF;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.2);

}
.card>:nth-child(1){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.card>:nth-child(1)>:nth-child(2){
   display: flex;
   align-items: center;
}

@media screen and (width>=700px) {
    .card>:nth-child(2){
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 3rem;
        padding: 10px 16px;
       
    }
    .card>:nth-child(2)>*{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 50%;
    }
    
    .card>:nth-child(2)>*>*{
        display: flex;
        flex-direction: row;
        gap: 2rem;
        font-size: 15px;
    }
    
    .card>:nth-child(2)>*>*>*:nth-child(1){
        min-width: 6rem;
       color: #16243280;
       font: normal 500 16px/20px Lato;
    }  
    .documents>:nth-child(2){
        display: grid;
        grid-template-columns: repeat(4,1fr);
        column-gap: 2rem;
        row-gap: 1.5rem;
       grid-auto-rows: 8.5rem;
    }
}
@media screen and (width<700px) {
    .card>:nth-child(2){
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        padding: 10px 16px;
       
    }
    .card>:nth-child(2)>*{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        width: 100%;
    }
    
    .card>:nth-child(2)>*>*{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 2rem;
        font-size: 15px;
    }
    
    .card>:nth-child(2)>*>*>*:nth-child(1){
        min-width: 6rem;
       color: #16243280;
       font: normal 500 16px/20px Lato;
    } 
    .documents>:nth-child(2){
        display: grid;
        grid-template-columns: repeat(2,1fr);
        column-gap: 2rem;
        row-gap: 1.5rem;
       grid-auto-rows: 8.5rem;
    } 
}
.documents{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* padding-left: 1rem; */
}



.documents>:nth-child(2)>*{
    border-radius: 5px;
    display: flex;
    padding: 0.5rem;
    flex-direction: column;
    background-color: #fff;
    border-radius: 5px;
}

.documents>:nth-child(2)>*>:nth-child(1){
   width: 100%;
   height: 6rem;
   display: flex;
   background-color: #ECEEF0;
}
.documents>:nth-child(2)>*>:nth-child(2){
    width: 100%;  
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 13px;
    padding: 0.3rem;
    font-weight: 600;
}

.container {
  max-width: 100%;
  height: 73vh;
  background: #f8f8f8;
  overflow: auto;
}

.container::-webkit-scrollbar {
  width: 5px;
}

.container::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.container::-webkit-scrollbar-thumb {
  background: rgb(192, 190, 190); 
  border-radius: 10px;
}

/* Handle on hover */
.container::-webkit-scrollbar-thumb:hover {
  background: #747474; 
}

.emptyContainer{
  margin-top: 1rem;
  width: 100%;
  height: 68vh;
  background: #fcfcfc;
  display: flex;
  justify-content: center;
  align-items: center;
}


.panelTable ::-webkit-scrollbar {
  width: 5px;
}

.panelTable ::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.panelTable ::-webkit-scrollbar-thumb {
  background: rgb(192, 190, 190); 
  border-radius: 10px;
}

/* Handle on hover */
.panelTable ::-webkit-scrollbar-thumb:hover {
  background: #747474; 
}

.ListContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0rem 2rem;
}
.ListHeader{
  padding:  1rem 1rem;
  width: 100%;

}
.ListHeader>*{

  display: flex;
  justify-content: center;
  align-items: center;
  color: #16243280;
  font: normal 600 16px/22px lato;
}
.ListBodyContainer{
  width: 100%;
}
.rowContainer{
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: white;
  margin-bottom: 1rem;
  border-radius: 5px;
  height: 5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 2px rgba(0, 0,0,0.05);
  padding: 1rem;
}
.rowContainer:hover{
  border: 1px solid rgba(0, 0, 0, 0.1);

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
  /* background-color: rgba(150, 244, 213,0.6); */
  transform: scale(1.01);
  transition: .6s ease-in-out;
  /* padding: 0rem 0.5rem; */

}
.rowContainer>*{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #162432;
  font: normal 400 16px/22px lato;
}
.ListBodyContainer::-webkit-scrollbar{
  display: none;
}
@media screen and (width>=700px) {
  .projectModal{
    width: 50vw;
  }
  .TeamListModal{
    width: 80%;
  }
  
  .MobViewList{
    display: none;
  }
}
@media screen  and (width<700px) {
  .projectModal{
    width: 95vw;
  }
  .TeamListModal{
    width: 98%;
  }
  .WebViewList{
    display: none;
  }
  .MobViewList{
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
   
  }
  .ListContainerMob{
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }
  .MobListBody{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    border-radius: 10px;
    background-color: #fff;
  }
  .listLine{
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}
.scrollable-table {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .scrollable-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .scrollable-table th, .scrollable-table td {
    border-bottom: 1px solid #ddd;
    padding: 8px;
  }
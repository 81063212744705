.New{
    color: #7BC0FF !important;
    font-weight: 600;


}
.Ongoing , .Completed{
    color:#8AD9A6 !important;
    font-weight: 600;

}
.Overdue,.Over, .Rejected{
    color: #FF9898 !important;
    font-weight: 600;
}
.Draft, .On ,.Suspended{

    color: #878787 !important;
    font-weight: 600;

}
.container {
  /* border: 1px solid black; */
  margin-top: 0.5rem;
  /* padding: 0.7rem; */
  height: 84vh;
  overflow: scroll;
  /* border: 2px solid red; */
}

.container>:nth-child(1){
  margin:0 0 0.5rem 0.8rem ;

}

.container::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.container::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}

/* Handle */
.container::-webkit-scrollbar-thumb {
  background: rgb(192, 190, 190);
  border-radius: 10px;
}

/* Handle on hover */
.container::-webkit-scrollbar-thumb:hover {
  background: #747474;
}

.grid_item2 {
  width: 100%;
  padding: 1rem;
  height:45vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
}


.grid_item {
  width: 100%;
  padding: 1rem;
  height:45vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.grid_item>*:nth-child(2)>*:nth-child(2){
  margin-top: -6vh;
}

/* .grid_item>*:nth-child(2)>*:nth-child(1){
  border: 2px solid red;
} */

/* h3{
  margin: 0;
  padding: 0;
} */
.leaveCircle{
  row-gap: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 25px;
  height: 100%;
  width: 100%;
  padding: 1.5rem;
}

.leaveInfo{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 1rem;
}

.leaveInfo>*{
  display: flex;
  flex-direction: row;
  font-size: 0.80rem;
  font-weight: 600;
}

.leaveInfo>*>:nth-child(2){
  flex: 1 1 10%;
  color: #878787;
}

.leaveInfo>*>:nth-child(1){
  flex: 1 1 75%;
  color: #878787;
}
.leaveInfo>*>:nth-child(3){
  flex: 1 1 15%
}

.leaveType{
  display: flex;
  flex-direction: row;
 gap: 1rem;
  align-items:center;
}

.leaveType>*:nth-child(2){
  color: #878787;
  font-weight: 600;
  font-size: 15px;
}

@media(max-height:700px){
  .leaveInfo>*{
    display: flex;
    flex-direction: row;
    font-size: 0.70rem;
    font-weight: 600;
  }
}
.circleBackground,
.circleProgress {
  fill: none;
}

.circleBackground {
  stroke: #D9D9D9;
}

.circleProgress {
  /* stroke:#0DAC45; */
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circleText {
  font-weight: 550;
  font-size: 12px;
  fill: #000000;
}
.modalHeader{
    font-size: 1.2rem;
}


 @media screen and (height>=800px) and (height<1100px) {
    .ScrollEdit{
        max-height: calc(100vh - 180px);
        overflow-y: scroll;
    } 
}

@media screen and (height>=600px) and (height<800px) {
    .ScrollEdit{
        max-height: calc(100vh - 175px);
        overflow-y: scroll;
    } 
}
@media screen and (height<600px) {
    .ScrollEdit{
        max-height: calc(100vh - 160px);
        overflow-y: scroll;
    } 
}

@media screen and (width>=700px) {
  .SubmitProfileModal{
    width:70vw ;
    padding: 12px;
    top: 25vh;
  }
  .UploadModal{
    width: 30vw;
  }
  .EditField{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 60%;
    padding-left: 10px;
    border-right: 1px solid #dfdfdf;
  }  
  .PreviewField{
    display: flex;
    flex-direction: column;
    width: 40%;
  }
  .WebViewTab{
    display: flex;
  }
  .MobViewTab{
    display: none !important ;

  }
}
@media screen and (width<700px) {
  .SubmitProfileModal{
    width:95vw ;
    padding: 12px;
    top: 15vh;
  }
  .UploadModal{
    width: 95vw;
  }
    .EditField{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
       
      }  
      .PreviewField{
        display: none !important;
      } 
      .WebViewTab{
        display: none;
      }
      .MobViewTab{
        display: inline;
      }
}

@media screen and (height>=1000px) {
  .UploadScroll{
    overflow-y: scroll;
    height: 80vh;
  }
}
@media screen and (height>=750px) and (height<1000px) {
  .UploadScroll{
    overflow-y: scroll;
    height: 75vh;
  }
}
@media screen and  (height>=600px) and (height<750px) {
  .UploadScroll{
    overflow-y: scroll;
    height: 70vh;
  }
}
@media screen and  (height<600px)  {
  .UploadScroll{
    overflow-y: scroll;
    height: 65vh;
  }
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.container{
    display: flex;
    width: 100%;
    height: 90vh;
}
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
.sider{
    width: 18%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 90vh;
    gap: 3rem;
    align-items: center;
    /* justify-content: space-between; */
    padding: 1.5rem 0;
    border-right: 1px solid #DFDFDF;
}

.sider>*:nth-child(1){
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    padding: 0.9rem 1rem;
    background-color: #DBF4FF;
    color: #0086FF;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    border-radius:10px ;
    width: 90%;
    border-right:5px solid #0086FF ;
}

.sider>*:nth-child(2){
  margin-top: auto;
}

.sider>*:nth-child(1) img{
    width: 2rem;
    height: 2rem;
}


.mainContent{
    width: 82%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 90vh;
    overflow-y: auto;
}

.header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom:1px solid #DFDFDF;
    background-color: #fff;
    flex-wrap: wrap;
    gap: 1rem;
}

.header>:nth-child(2){
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
}
/* .header>:nth-child(2)>:nth-child(2){
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
} */

.header>:nth-child(2)>:nth-child(3){
    background-color: #0086FF;
  width: 9rem;
  height: 2.5rem;
    cursor: pointer;
    color: #fff;
    border-radius: 5px;
    outline: 0;
    border: 0;
    font-size: 1rem;
}

.searchDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}
.previewIcon{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.previewIcon>*{
    cursor: pointer;
}

.profile{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}
.profile>:nth-child(1){
    display: flex;
   width: 2.2rem;
   height: 2.2rem;
   /* border-radius: 50%; */
}
.profile>:nth-child(1)>*{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.profile>:nth-child(2){
    display: flex;
    flex-direction: column;
}

.tableRowWhite{
    background-color: #fff;
    color: #162432;
    font-weight: 500;
    font-size: 13px;
  }

  .tableRow{
    background-color: #F9F9F9;
    color: #162432;
    font-weight: 500;
    font-size: 13px;
  }

  .select{
    width: 10rem;
  }

  @media(max-width:800px)
  {
    .mainContent{
        width: 100%;
        height: auto;
    }
    .mainContent::-webkit-scrollbar{
        display: none;
    }
    .sider{
        width: 100%;
        border: 0;
        height: 80vh;
    }
    .sider>*:nth-child(1){
        padding: 0;
    }
    .header>:nth-child(2)>*:nth-child(3){
        align-self: right;
        margin-left: auto;
        
    }
    .header{
        justify-content: center;
    }
    .header>:nth-child(2)>*:nth-child(1){
        width: 100%;
        display: flex;
    }
    .header>:nth-child(2)>*:nth-child(1)>*{
        flex: 1 1 100%;
    }
  }

  @media (max-width:350px)
  {
    .header>:nth-child(2)>*:nth-child(3){
       width: 7rem;
    }
  }
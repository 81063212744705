.header, .request,.requestTable, .searchDiv, .searchDiv>*:nth-child(1), .actions{
    display: flex;
    flex-direction: column;
}
.request{
    width: 100%;
    height: 100%;
    padding: 1rem;
}
.header, .searchDiv, .searchDiv>*:nth-child(1), .actions{
    flex-direction: row;
}
.header, .requestTable{
    border-bottom: 1px solid #F1F1F1;
}
.header{
    padding-bottom: 1rem;
    align-items: center;
    gap: 1rem;
}
.requestTable{
    width: 100%;
    margin-top: 1rem;
    background: #FCFCFC;
    border-radius: 10px;
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    padding:0.5rem 0.5rem 1rem 0.5rem;
    border-radius: 10px;
    border: 1px solid #F1F1F1;
    background: #FCFCFC;
}
.mobileDiv{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.mobCard{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 0px 8px 3px rgba(173, 173, 173, 0.07);
}
.mobCard>*{
    display: flex;
    gap: 0.5rem;
}
.mobCard>*>*:nth-child(1){
    color: rgba(22, 36, 50, 0.50);
}
.searchDiv{
    display: flex;
    margin: 1rem;
    justify-content: space-between;
}
.searchDiv > * {
    flex-grow: 1; /* Ensure all children can grow */
    gap: 2rem;
}

.searchDiv>*:nth-child(1){
    
    gap: 2rem;
    
}
.searchfield{
    width: 80%;
}
 

   
.searchDiv>*:nth-child(2)
{
    gap: 2rem;
    padding-left: 60px;
    max-width: 30rem;
    flex-grow: 2;
}


.actions{
    gap: 1rem;
}
.actions>*{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

@media(max-width:800px)
{
    .searchDiv{
        margin: 0 0 1rem 0;
        padding: 0;
    }
    .searchDiv>*:nth-child(1){
        flex-direction: column;
        width: 100%;
        gap: 1rem;
        padding: 0;
        margin: 0;

    }
    .requestTable{
        border: 0;
        padding: 0;
        background-color: #f8f8f8;
    }
}

.header{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-bottom: 1px solid #dfdfdf;
    background-color: #fff;
}
.header>:nth-child(1){
    font-size: 1.4rem;
    font-weight: 600;
}


.tableHeader{
    display: grid;
    grid-template-columns: repeat(7,1fr);
}

.tableHeader{
    padding:  1rem 0rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7,1fr);
    gap: 0.5rem;
  }
 
  .tableHeader>*{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #18181880;
    font: normal 600 16px/22px lato;
  }

  .tableHeader>:nth-child(1){
    padding-left: 0.7rem;
  }


.rowContainer{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 67vh;
    overflow-y: auto;
}

.eachRow{
    display: grid;
    width: 100%;
    grid-template-columns: repeat(7,1fr);
    gap: 0.5rem;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    height: 5rem;
    box-shadow: 0 2px 6px rgba(0, 0,0,0.2);
  }


  .eachRow:hover{
    box-shadow: 0 8px 12px rgba(146, 153, 151, 0.2);
    /* background-color: rgba(150, 244, 213,0.6); */
    transform: scale(0.99);
    transition: .6s ease-in-out;
    padding: 0rem 0.5rem;
  }
  .eachRow>*{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #16243280;
    height: 5rem;
    font: normal 600 16px/22px lato;
    justify-content: flex-start;
  }
 
  .eachRow>:nth-child(1){
    padding-left: 0.5rem;
    flex-direction: column;
    align-items: center;
    flex-direction: row;
    gap: 1vh;
    cursor: pointer;
  }
  .eachRow>:nth-child(2){
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .greyContent{
    color: #18181880;
    font-size: 13px;
}
  .recommendations
  {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;
      color: #0086ff;
      cursor: pointer;
      font-size: 0.9rem;
      font-weight: 500;
  }

.tab{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}
.tab>*:nth-child(1){
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    color: #A0A0A0;
    font-weight: 550;
    height: 2.2rem;
}
.tab>*:nth-child(1)>*{
    cursor: pointer;
}
.activeTab{
    color: #0086ff;
    border-bottom: 2px solid #0086ff;
}


.tab>*:nth-child(2){
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}
.tab>*:nth-child(2)>:nth-child(2){
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}
.basicInfo{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    margin-top: 1rem;
}
.basicInfo>*{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}
.content{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    height: 60vh;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    /* overflow-y: auto; */
    box-sizing: border-box;
    /* border: 2px solid red; */
    /* border: 2px solid red; */
}

.graphDiv{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(20rem,1fr));
    gap: 1rem;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
}

.eachGraph{
    display: flex;
    flex-direction: column;
    gap: 1rem;
   
}


.deptScroll{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1rem; 
    width: 100%;
    margin-top: 1rem;
}
.deptScroll>:nth-child(1){
    flex: 1 1 2%;
    display: flex;
    justify-content: right;
    cursor: pointer;
}
.deptScroll>:nth-child(2){
    flex: 1 1 96%;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: nowrap;
    padding: 0.5rem;
    align-items: center;
    /* border: 2px solid red; */
}

.deptScroll>:nth-child(2)::-webkit-scrollbar{
    display: none;
}
.deptScroll>:nth-child(3){
    flex: 1 1 2%;
    cursor: pointer;
}
.card{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #18181812;
    border-radius: 10px;
    gap: 1rem;
    width: 16rem;
}
.card>:nth-child(1){
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    color: #000;
    justify-content: space-between;
    font-size: 1.1rem;
    font-weight: 600;
}

.selected{
    border: 1.5px solid #0086ff;
    background-color: #0086FF0F;
   
}
.selected>:nth-child(1)>*{
    color: #0086ff;
}
.carousel-container {
    display: flex;
    overflow: hidden;
    width: 300px; /* Set the width of your carousel */
  }
  
  .carousel-slide {
    flex: 0 0 100%;
    transition: transform 0.3s ease;
  }
  
  .carousel-slide img {
    width: 100%;
    height: auto;
  }
  
  button {
    cursor: pointer;
  }
  .spin{
    display: flex;
    height: 80vh;
    align-items: center;
    justify-content: center;
}
.spin2{
    display: flex;
    height: 40vh;
    align-items: center;
    justify-content: center;
}

.mobContainer{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.cardDeptName{
    width: 14rem;
    font-size: 1.3rem;
    font-family: 'Lato';
}


@media(max-width:700px)
{
    .tab{
        flex-direction: column;
    }
    .tab>:nth-child(1){
        width: 100%;
    }
    .basicInfo{
        flex-direction: column;
        margin-bottom: 1rem;
    }
    .basicInfo>*{
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
    }
    .tab>:nth-child(2){
        display: flex;
        flex-direction: column;
    }
    .card{
        width: 72vw;
    }
   
}
.container{
    display: flex;
    width: 100%;
    height: 90vh;
}
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.sider{
  width: 18%;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #DFDFDF;
}


.sider>*:nth-child(2){
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
} 

.sider>*:nth-child(1){
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
  padding: 0.7rem 1rem;
  background-color: #0FB3FF;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  border-radius: 10px;
  background: #DBF4FF;
  color: #0086FF;
  width: 90%;
  margin: 3rem auto;
  border-right:5px solid #0086FF ;
}
.sider>*:nth-child(1) img{
  width: 2rem;
  height: 2rem;
}


.cardContainer{
    width: 82%;
    padding:0;
    display: flex;
    box-sizing: border-box;
    height: 100%;
    flex-direction: column;
}
.mainContent{
  width: 82%;
  padding:0;
  display: flex;
  box-sizing: border-box;
  height: 100%;
  flex-direction: column;
}

.cardContainer>*:nth-child(1){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
   background-color: #fff;
    padding: 1.5rem;
    height: 10vh;
    align-items: center;
    border-bottom: 1px solid #DFDFDF;  
}
.createButtonDiv{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.createButtonDiv>:nth-child(2){
    width: 8rem;
    height: 2.5rem;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 500;
    background-color: #0086FF;
    color: #fff;
}
.cloneButton{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
}
.cloneButton button{
    width: 8rem;
    height: 2.5rem;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 500;
}
.cloneButton>*:nth-child(1){
    color: #0086FF;
    border: 1px solid #0086FF;
}
.cloneButton>*:nth-child(2){
    color: #FFFFFF;
    background-color:#FF3232;
}
.innerContainer {
    width: 100%;
    height: 77vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0 0 1.5rem 1.5rem;
  }
  .firstPageCard {
    background: #ffffff;
    border-radius: 10px;
    margin: 2rem;
    width: 50rem;
    height: 70vh;
    box-shadow: 0 0.5rem 1rem #00000029;
  }
  .firstPageCard h3 {
    margin-left: 8rem;
    margin-top: 2rem;
    font-weight: 900;
    font-size: 1.3rem;
  }
  .firstPageCard>*:nth-child(2){
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 1.5rem;
  }
  .firstPageCard>*:nth-child(2) img{
    margin-left: 3rem;
    width: 20rem;
    height: 45vh;
  }
  .firstPageCard>*:nth-child(2)>*:nth-child(2){
    margin-right:3rem ;
    width: 20rem;
    height: 45vh;
    border-radius: 10px;
    border: 2px solid #407BFF;    
  }
  .firstPageCard>*:nth-child(2)>*:nth-child(2)>*:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .firstPageCard>*:nth-child(2)>*:nth-child(2)>*:nth-child(1) img{
    width: 10rem;
    margin-top: 1rem;
    height: 21vh;
  }
  .firstPageCard>*:nth-child(2)>*:nth-child(2)>*:nth-child(2){
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .firstPageCard>*:nth-child(2)>*:nth-child(2)>*:nth-child(2) p{
    width: 15rem;
    font-size: 0.7rem;
    text-align: center;
    color: #6F6F6F;
  }
  .firstPageCard>*:nth-child(2)>*:nth-child(2)>*:nth-child(2) button{
    width: 8rem;
    height: 2.1rem;
    margin: 1rem;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .createFeedback .ant-modal-content{
    background-color: #ffff;
    display: flex;
    justify-content: flex-start;
    border-radius: 5px;
  }
  .spinDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    background:#F8F8F8;
    position: absolute;
    opacity: 80%;
    height: 70vh;
  }
  .modalTitle{
    margin: 0.5rem;
    font-weight: 600;
    font-size: 1.4rem;
    width: 100%;
  }
  .itemTitle{
    font-size: 15px;
    font-weight: 500;
    color: #878787;
  }
  .modalContainer{
    /* padding: 1rem; */
    /* border: 2px solid; */
  }

  .radioButton{
    border: 1px solid #dfdfdf;
   height: 2.2rem;
   width: 7rem;
   border-radius: 10px;
   /* display: flex;
   align-items: center; */
   align-items: center;
   padding-left: 0.5rem;
   color: #878787;
   margin-right: 1rem;
  }
  .activeRadio{
    color: #0086FF;
    border: 1px solid #0086FF;
  }


  .buttonDesign{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}
.buttonDesign>*:nth-child(1){
    width: 7rem;
}
.buttonDesign>*:nth-child(2){
    background-color: white;
    width: 7rem;
}
.uploads{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem;
    margin-bottom: 1rem;
}
.questionsCol::-webkit-scrollbar{
    display:none;
}
.extraContentPublishDiv button{
    border: none;
    background: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    font-size: 0.9rem;
}
.extraContentPublishDiv button img{
    height: 1rem;
    width: 0.9rem;
}
/* .container {
  display: flex;
  flex-direction: column;
  padding: 0 1.6rem 0 1.6rem;
  height: 80vh;
} */

.innerContainer {
  width: 100%;
  height: 77vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
 
}

.navBar {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
  width: 100%;
}

.searchDiv {
  width: 18rem;
  height: 2rem;
  justify-self: flex-end;
  margin-right: 1.5rem;
  height: 2.2rem;
}

.firstPageCard {
  background: #ffffff;
  border-radius: 10px;
  margin: 2rem;
  width: 50rem;
  height: 70vh;
  box-shadow: 0 0.5rem 1rem #00000029;
}
.firstPageCard h1 {
  margin-left: 7rem;
  margin-top: 1.5rem;
  font-weight: 900;
  font-size: 2rem;
}

/* .createFeedback .ant-modal-footer{
  background-color: white;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 5px 5px;
}

.createFeedback .ant-modal-header{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px 5px 0 0;
} */
.createFeedback .ant-modal-content{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px;
}

.addQuestion{
  /* background-color: red; */
}

.addQuestion>*{
  background-color: red;
}



/* .searchDiv{
    display: flex;
    flex-direction: row;
    gap:2%;
    margin-bottom: 1.2rem;
}

.searchDiv >*:nth-child(1){
    flex: 1 1 20%;
    height: 2.3rem;
}
.searchDiv >*:nth-child(2){
    flex: 1 1 70%;
    display: flex;
    flex-direction: row;
    gap: 3%;
    justify-content: right;

} */

.searchDiv > *:nth-child(2) button {
  width: 10rem;
  max-height: 2.5rem;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
}

.searchDiv > *:nth-child(2) .deleteBtn {
  color: #ff9797;
  border: 1px solid #ff9797;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  color: #161616;
  margin: 0rem 1.5rem 2rem 1.5rem;
  height: 70vh;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.modalContainer h1 {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.feedback-page-body-scroller {
  max-height: 815px;
  overflow-y: scroll;
}
.feedback-page-body-scroller::-webkit-scrollbar {
  display: none;
}

.header {
  display: flex;
  align-items: center;
}

.header h1 {
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-left: 1.7rem;
}

.buttonDiv {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 2.2rem;
  margin-bottom: 0.4rem;
  justify-content: center;
}

.feedbackScroll {
  height: 700px;
  display: flex;
}

.feedbackScroll::-webkit-scrollbar {
  display: none;
}

.buttonDiv button {
  border-radius: 0.5rem;
  width: 8rem;
  height: 2.5rem;
  border: 1.5px solid #4daaff;
  color: #4daaff;
  font-weight: bold;
  font-size: 1rem;
}

.buttonDiv .createButton {
  background-color: #4daaff;
  border: 1.5px solid #4daaff;
  color: #ffffff;
}

.creatFeedbackCard {
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  /* padding: 1%; */
  gap: 0.4rem;
  border-radius: 5px;
    /* border: 1px solid black; */
}

.creatFeedbackCard > * {
  width: 100%;
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}
/* 
.creatFeedbackCard > *:nth-child(1) {
  flex: 1 1 45%;
}

.creatFeedbackCard > *:nth-child(1) img {
  width: 28%;
  height: 70%;
}
.creatFeedbackCard > *:nth-child(2) {
  flex: 1 1 17%;
  font-weight: bold;
}
.creatFeedbackCard > *:nth-child(3) {
  flex: 1 1 20%;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;
}
.creatFeedbackCard > *:nth-child(4) {
  flex: 1 1 18%;
} */

.creatFeedbackCard > *:nth-child(4) button {
  width: 40%;
  height: 90%;
  font-weight: bold;
}

.feedbackDetailsCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);
}

.feedbackDetailsCard > *:nth-child(1) {
  width: 95%;
  display: flex;
  flex-direction: column;
  height: 30vh;
  margin: 0;
}

.feedbackDetailsCard > *:nth-child(1) > *:nth-child(1) {
  display: flex;
 margin: 0.5rem 0rem 0.5rem 0.5rem;
  width: 100%;
  justify-content: space-between;
  /* border: 2px solid red; */
  padding-right: 0.8rem;
}
.feedbackDetailsCard > *:nth-child(1) > *:nth-child(2) {
  display: flex;
  height: 18vh;
  justify-content: center;
  align-items: center;
  margin:0 0.5rem;
  /* border: 2px solid red; */
}

/* .feedbackDetailsCard > *:nth-child(1) > *:nth-child(2) img {
  width: 25vh;
  height: 20vh;
} */

.feedbackDetailsCard > *:nth-child(2) {
  display: flex;
  width: 100%;
  padding: 0 0 0 0.5rem;
  text-align: left;
  justify-content: left;
}



.cardContainersDiv {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(15rem,0.25fr));
  /* grid-template-rows: repeat(auto-fit,minmax(15.5rem,0.8fr)); */
  grid-auto-rows: 15.5rem;
  height: 70vh;
  overflow-y: auto;
  gap: 1.5rem;
  margin-top: 1rem;
  width: 97%;
  padding-bottom: 0.4rem;
  overflow-x: hidden;
}

.hide-upload-tooltip .ant-upload.ant-upload-drag .ant-upload-hint {
  display: none;
}

/* 
.cardContainersDiv::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}


.cardContainersDiv::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(234, 227, 227, 0);
}

.cardContainersDiv::-webkit-scrollbar-thumb {
  background-color: rgb(234, 227, 227, 0);
  border-radius: 10px;
  height: 0.5rem;
} */
*{
  font-family: 'Lato';
}

.feedbackTab{
  display: flex;
  gap: 1.5rem;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: #999;
  margin-left: 1rem;
}
.feedbackTab>*{
  cursor: pointer;
}
.activeKey{
  color: #0086FF;
  font-size: 1.1rem;
  border-bottom: 2px solid #0086FF;
}

.feedbackTab>:nth-child(3){
  margin-left: auto;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-direction: row;
  color: #162432;
  font-weight: 500;
  font-size: 1.1rem;
  cursor: pointer;
  justify-content: center;
}

.questions{
  display: flex;
 align-items: center;
 justify-content: center;
 font-size: 1rem;
 font-weight: 600;
}

.questionCard{
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.09);
}

.responses{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* width: 100%; */
}
.responses>:nth-child(1){
  margin: 0 auto;
  font-size: 1rem;
  font-weight: 600;

}

.responses>:nth-child(2){
  display: flex;
  flex-direction: column;
  overflow-x: auto; 
}


.responses>*:nth-child(2)>:nth-child(2){
gap: 1rem;
display: flex;
min-width: max-content;
flex-direction: column;
max-height: 43vh;
min-width: 100%;
padding:0.5rem;
}
/* 
.responses>:nth-child(2)>*{
  display: flex;
  min-width: 100% !important;
  flex: 1 0 auto;
  box-sizing: border-box;
  border: 2px solid blue;
} */

.eachRow{
  display: flex;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: nowrap;
  width: max-content;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 14px;
  font-weight: 500;
  min-width: 100%;
  padding:0.3rem
}
.eachRow11{
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(12rem,1fr));
  padding:0 0.3rem;
  align-items: flex-start;
  gap: 2rem;
  font-size: 14px;
  font-weight: 500;
}

.eachRow>*{
  width: 15rem;
}

.eachRow>:nth-child(1){
  font-size: 1rem;
  font-weight: 600;
  padding-left: 2rem;
  margin-bottom: 0.7rem;
}

.eachRow2{
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: max-content;
  flex-wrap: nowrap;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  align-items: center;
  padding: 0.7rem 0;
  font-size: 14px;
}

.eachRow2>*{
  width: 15rem; 
}
.eachRow2>*:nth-child(1)
{
  padding-left: 1.5rem;
}
.eachRow2>*:nth-last-child(1)
{
  padding-right: 1.5rem;
}

.eachRow21{
  display: grid;
  gap: 2rem;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  align-items: center;
  padding: 0.7rem 0;
  font-size: 14px;
  grid-template-columns: repeat(auto-fit,minmax(12rem,1fr));
  
}


.feedbackRound{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  padding: 1rem 2rem 1rem 1.5rem;
  box-sizing: border-box;
  border-bottom: 1px solid #dfdfdf;
}

.questionDiv{
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 0.7rem;
  height: 100%;
  padding: 1rem 2rem 1rem 1rem;
  overflow-y: auto;
}
.addQuestionDiv{
  display: flex;
  flex-direction: column;
  width: 35%;
}
.enterQuestion{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.questionTab{
  height: 100%;
  background-color: #f9f9f9f9;
  padding: 1rem 1rem;
}
.mobResponse{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  height: 100%;
}
.mobResCard{
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 0px 8px 3px rgba(173, 173, 173, 0.07);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobResCard>:nth-child(2){
  color: #0086FF;
  font-weight: 500;
  cursor: pointer;
}
.viewContainer{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
}
.viewContainer>*:nth-child(1){
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
  font-weight: 600
}
.viewWrapper{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.viewItem{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 0px 8px 3px rgba(173, 173, 173, 0.07);
  padding: 1rem;
}
.viewItem>:nth-child(1){
  font-weight: 600;
}

.emojiRating{
  width: 100%;
  overflow-x: scroll;
}


  
.emojiRating::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-radius: 10px;
  display: block;
}
.emojiRating::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(220, 218, 218); 
}
.emojiRating::-webkit-scrollbar-thumb {
  background: rgb(163, 162, 162);
  height: 3px;
}





@media (max-width: 996px) {
  /* .creatFeedbackCard{
          width: 213px;
           height: 213px;
      }
      
      .feedbackDetailsCard
  {
      width: 213px;
      height: 213px;   
  } */

  .creatFeedbackCard h2 {
    font-size: 1.1rem;
  }

  .creatFeedbackCard > *:nth-child(3) {
    font-size: 0.6rem;
    font-weight: 600;
  }
  .creatFeedbackCard > *:nth-child(4) button {
    font-size: 0.7rem;
  }

  .feedbackDetailsCard > *:nth-child(2) h2 {
    font-weight: bold;
    font-size: 1rem;
    margin: 0;
  }

  .feedbackDetailsCard > *:nth-child(2) h5 {
    font-weight: 600;
    font-size: 0.6rem;
    margin: 0;
  }
}

@media (min-width: 997px) and (max-width: 1196px) {
  /* .creatFeedbackCard{
          width: 203.5px;
           height: 203.5px;
      }
      
      .feedbackDetailsCard
  {
      width: 203.5px;
      height: 203.5px;  
  } */

  .creatFeedbackCard h2 {
    font-size: 1.1rem;
  }

  .creatFeedbackCard > *:nth-child(3) {
    font-size: 0.6rem;
    font-weight: 600;
  }
  .creatFeedbackCard > *:nth-child(4) button {
    font-size: 0.7rem;
  }

  .feedbackDetailsCard > *:nth-child(2) h2 {
    font-weight: bold;
    font-size: 1rem;
    margin: 0;
  }

  .feedbackDetailsCard > *:nth-child(2) h5 {
    font-weight: 600;
    font-size: 0.6rem;
    margin: 0;
  }
}

@media (min-width: 1196px) and (max-width: 1493px) {
  /* .creatFeedbackCard{
        width: 242.5px;
         height: 242.5px;

    }
    
    .feedbackDetailsCard
{
    width: 242.5px;
    height: 242.5px;  
} */

  .creatFeedbackCard h2 {
    font-size: 1.2rem;
  }

  .creatFeedbackCard > *:nth-child(3) {
    font-size: 0.8rem;
    font-weight: 600;
  }

  .feedbackDetailsCard > *:nth-child(2) h2 {
    font-weight: bold;
    font-size: 1.1rem;
    margin: 0;
  }

  .feedbackDetailsCard > *:nth-child(2) h5 {
    font-weight: 600;
    font-size: 0.7rem;
    margin: 0;
  }
}

/* @media (min-width:1494px) and (max-width:1659px)
{

    .creatFeedbackCard{
        width: 275px;
         height: 275px;
         

    }
    .feedbackDetailsCard
    {
        width: 275px;
        height: 275px;
    }
} */

@media (min-width: 1660px) and (max-width: 1867px) {
  /* .creatFeedbackCard{
          width: 315px;
           height: 315px;
      }
      
      .feedbackDetailsCard
  {
      width: 315px;
      height: 315px;  
  } */

  .creatFeedbackCard h2 {
    font-size: 1.4rem;
  }

  .creatFeedbackCard > *:nth-child(3) {
    font-size: 1rem;
    font-weight: 600;
  }
  .creatFeedbackCard > *:nth-child(4) button {
    font-size: 0.9rem;
  }

  .feedbackDetailsCard > *:nth-child(2) h2 {
    font-weight: bold;
    font-size: 1.4rem;
    margin: 0;
  }

  .feedbackDetailsCard > *:nth-child(2) h5 {
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
  }
}

@media (min-width: 1867px) {
  /* .creatFeedbackCard{
        width: 364.7px;
         height: 364.7px;
    }
    
    .feedbackDetailsCard
{
    width: 364.7px;
    height: 364.7px;  
} */

  .creatFeedbackCard h2 {
    font-size: 1.4rem;
  }

  .creatFeedbackCard > *:nth-child(3) {
    font-size: 1rem;
    font-weight: 600;
  }
  .creatFeedbackCard > *:nth-child(4) button {
    font-size: 0.9rem;
  }

  .feedbackDetailsCard > *:nth-child(2) h2 {
    font-weight: bold;
    font-size: 1.4rem;
    margin: 0;
  }

  .feedbackDetailsCard > *:nth-child(2) h5 {
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
  }
}

@media(max-width:800px)
{
  .sider
  {
    width: 100%;
    border: 0;
  }
  .cardContainer{
    width: 100%;
  }
  .mymobile{

  }
  .cardContainer>*:nth-child(1){
   flex-direction: column;
   height: auto;
   gap: 1rem;
   padding: 1rem;
}
.createButtonDiv{
  padding: 0;
  margin: 0;
  width: 100%;
}
.cloneButton{
  width: 100%;
}
.innerContainer
{
  height: auto;
  padding: 0 1rem 1rem 1rem;
}
.cardContainersDiv{
  width: 100%;
  height: 100%;
}
.mainContent{
  width: 100%;
}
.feedbackRound{
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
.questionDiv{
  flex-direction: column-reverse;
  padding: 1rem;
}
.addQuestionDiv{
  width: 100%;
}
.enterQuestion{
  flex-direction: column;
  align-items: flex-start;
}
.modalContainer {
 padding: 0;
 margin: 0rem;
}
.questionTab
{
  padding: 1rem 0.5rem;
}
.firstPageCard{
  margin: 0;
  height: auto;
  padding: 1rem;
  align-items: center;
}
.firstPageCard>:nth-child(1){
  margin: auto;
}
.firstPageCard>*:nth-child(2){
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}
.firstPageCard>*:nth-child(2)>*:nth-child(2){
 margin: 0;
  width: 100%;  
}
.cardContainersDiv {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(15rem,1fr));
}
.sider{
  height: 70vh;
}
.sider>*:nth-child(1){
  padding: 0rem 1rem;
}

}

.profile{
    display: flex;
    height: 90vh;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #fff;
    overflow-y: auto;
    box-sizing: border-box;
}

.header{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 1.5rem 0 1rem 0;
    padding-left: 1.5rem;
}
.header h3{
    font-weight: 600;
    font-size: 1.4rem;
}
.header>*:nth-child(1){
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}
.header>:nth-child(2){
    height: 1px;
    background: #dfdfdf;
}

.profile>:nth-child(1) img{
    cursor: pointer;
}

.profile>*:nth-child(2){
    display: flex;
    flex-direction: row;
    gap: 1rem;
    height: 100%;
}

.profile>*:nth-child(2)>*:nth-child(1){
    flex: 1 1 25%;
    height: 100%;
    border-radius: 34px;
    background: #FFF;
    padding: 1rem;
}

.profile>*:nth-child(2)>*:nth-child(2){
    flex: 1 1 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-right: 2rem;
}

.profileImage{
    margin: 1rem auto;
    display: flex;
   width: 15rem;
   height: 15rem;
   border-radius: 50%;
  border: 1px solid #dfdfdf;
}

.profileImage>:nth-child(1){
   animation: profile 0.6s ease-in-out  normal forwards ;
   border-radius: 10px;
}

@keyframes profile {
    0%{
        width: 50%;
        height: 50%;
    }
    100%{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
    
}

.profileImage>:nth-child(2) img{
   position: absolute;
   width: 2rem;
   height: 2rem;
   border-radius: 50%;
   right: -1rem;
   bottom: 0;
}

.nameDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    flex-direction: column;
    font-size: 0.8rem;
    font-weight: 600;
}
.nameDiv>*:nth-child(1){
    color: #0086ff;
    font-weight: 500;
    border-bottom: 1.5px solid #0086ff;
    margin-bottom: 2rem;
    cursor: pointer;
}
.nameDiv>*:nth-child(2){
    font-size: 1rem;
    margin-bottom: 0.8rem;
}
.nameDiv>*:nth-child(3){
    font-size: 14px;
   font-weight: 450;
}
.nameDiv>*:nth-child(4), .nameDiv>*:nth-child(5){
    font-size: 12px;
   font-weight: 500;
   color: rgba(22, 36, 50, 0.50);
}
.email{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 10px;
    background: #F9FAFC;
    margin-top: 1.5rem;
    padding: 0.6rem;
}
.email>*:nth-child(1){
    color: #A0A6AE;
}


.gridItem{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: 6rem;
    gap: 1.5rem;
}

.gridItem>*{
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 8px 3px rgba(89, 89, 89, 0.09);
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.7rem;
}

.gridItem>*>:nth-child(1) img{
    width: 1.3rem;
    height: 1.3rem;
    min-width: 1.3rem;

}
.gridItem>*>:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 134, 255, 0.15);
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    border-radius: 50%;
}
.gridItem>*:nth-child(2)>:nth-child(1){
    background-color: rgba(245, 108, 34, 0.15);
}
.gridItem>*:nth-child(3)>:nth-child(1){
    background-color: rgba(133, 93, 238, 0.15);
}
.gridItem>*:nth-child(4)>:nth-child(1){
    background-color: #fff;
}
.gridItem>*:nth-child(4)>:nth-child(1) img{
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
}

.gridItem>*>:nth-child(2)>*:nth-child(1){
    font-size: 13px;
    font-weight: 400;
}
.gridItem>*>:nth-child(2)>*:nth-child(2){
    font-size: 1rem;
    font-weight: 600;
}


/* 
.gridItem>*>:nth-child(2){
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    width: 100%;
    align-items: center;
    gap: 2rem;
    font-weight: 600;
    font-size: 0.85rem;
}

.gridItem>*>*:nth-child(2)>:nth-child(2){
    width: 2rem;
    height: 2rem;
} */

.personalInfo{
    gap: 1rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: #FFF;
    font-size: 0.80rem;
    font-weight: 600;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 8px 3px rgba(89, 89, 89, 0.09);
}

.personalInfo>:nth-child(1){
    font-size: 15px;
    font-weight: 500;
}
.personalInfo>:nth-child(2){
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(3,1fr);
    font-size: 13px;
    font-weight: 500;
    gap: 0.8rem;
}
.personalInfo>:nth-child(2)>*:nth-child(1){
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
.personalInfo>:nth-child(2)>*:nth-child(1)>*{
    display: flex;
    
}
.personalInfo>:nth-child(2)>*:nth-child(1)>*>*:nth-child(1){
    display: flex;
    width: 50%;
    color: rgba(22, 36, 50, 0.50);
    
}

.personalInfo>:nth-child(2)>*:nth-child(2){
    display: grid;
    grid-template-columns: 1fr 2fr; 
}
.personalInfo>:nth-child(2)>*:nth-child(2)>*{
    display: flex;
}
.personalInfo>:nth-child(2)>*:nth-child(2)>*>*:nth-child(1){
    width: 50%;
    color: rgba(22, 36, 50, 0.50);
}
.personalInfo>:nth-child(2)>*:nth-child(2)>*:nth-child(2)>*:nth-child(1){
    display: flex;
    width: 25%;
    color: rgba(22, 36, 50, 0.50);
}
/* .personalInfo>:nth-child(2)>*{
    border: 2px solid ;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.personalInfo>:nth-child(2)>*>*{
    border: 2px solid red;
    width: 100%;
    display: flex;
}

.personalInfo>:nth-child(2)>*>*>*:nth-child(1){
    border: 2px solid red;
    width: 40%;
} */



.currentPosition{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    font-size: 0.8rem;
    margin-top: 1rem;
    font-weight: 600;
    background-color: #fff;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 8px 3px rgba(89, 89, 89, 0.09);
}

.currentPosition>:nth-child(1){
    font-size: 15px;
    font-weight: 500;
}


.currentPosition>:nth-child(2){
   display: flex;
   flex-direction: row;
   gap: 10vw;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   font-size: 13px;
   font-weight: 500;
}

.currentPosition>:nth-child(2)>*{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    font-size: 13px;
   font-weight: 500;
}

.currentPosition>:nth-child(2)>*>*{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.currentPosition>:nth-child(2)>*>*>*:nth-child(1){
  width: 40%;
  color: rgba(22, 36, 50, 0.50);
}

.teamAsign{
    border-radius: 10px;
    margin-top: 1rem;
    background: #FFF;
    box-shadow: 0px 0px 8px 3px rgba(89, 89, 89, 0.09);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* align-items: center; */
}

.teamAsign>:nth-child(1){
   font-size: 15px;
   font-weight: 500;
}
.teamAsign>:nth-child(2){
   display: flex;
   width: 100%;
   font-size: 13px;
   flex-direction: row;
   gap: 10vw;
   justify-content: space-between;
   align-items: center;
}
.teamAsign>:nth-child(2)>*{
    width: 50%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.teamAsign>:nth-child(2)>*>*:nth-child(1){
    width: 40%;
    color: rgba(22, 36, 50, 0.50);
}
.teamAsign>:nth-child(2)>*>*:nth-child(2){
   font-weight: 500;
}


@media(max-width:800px)
{
    .profile>*:nth-child(2){
        flex-direction: column;
        height: auto;
    }
    .profile>*:nth-child(2)>*:nth-child(1){
        flex: auto;
        height: auto;
        border-radius: 0;
        padding: 0;
    }
    .profile>*:nth-child(2)>*:nth-child(2){
        flex: auto;
        gap: 1rem;
        margin:1rem;
    }
    .gridItem{
        grid-template-columns: repeat(auto-fit,minmax(8rem,1fr));
        padding: 0.5rem;
        width: 100%;
    }
    .personalInfo>:nth-child(2)>*:nth-child(1){
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .personalInfo>:nth-child(2)>*:nth-child(2){
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .personalInfo>:nth-child(2)>*:nth-child(2)>*:nth-child(2)>*:nth-child(1)
    {
        width: 50%;
    }
    .currentPosition>:nth-child(2){
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
     }
     .currentPosition>:nth-child(2)>*{
        width: 100%;
     }
     .teamAsign>:nth-child(2){
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
     }
     .teamAsign>:nth-child(2)>*{
         width: 100%;
     }
     .profile::-webkit-scrollbar{
        display: none;
     }

     .header{
        gap: 1rem;
        margin: 1.5rem 0 0rem 0;
       padding-left: 1rem;
    }
    .header>:nth-child(2){
       display: none;
    }
    
}


.container{
    display: flex;
    flex-direction: column;
    margin: 1.5rem 1.5rem 0 1.5rem;
    align-items: center;
    width: 55vw;
}

.header{
    display: flex;
    width: 100%;
    color:#0086ff;
}

.buttonDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap:1rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
}
.actionType{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(10rem,1fr));
    gap: 4rem;
    width: 100%;
}

.actionType>*{
    display: flex;
    align-items: center;
    padding: 0.6rem 0 0.6rem 3rem;
}

@media(max-width:800px)
{
    .container{
        margin: 1rem 1rem 0 1rem;
        width: 80vw;
    }
    .actionType{
        gap: 2rem;
    }
}
.preview{
    display: flex;
    flex-direction: column;
    padding: 0 2rem 1rem 2rem;
    width: 70vw;
    height: 80vh;
    gap: 0.5rem;
    overflow-y: auto;
    color: #596172;
    font-family: "Lato";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}
.preview>:nth-child(1), .preview>:nth-child(3){
color: #000;
font-weight: 500;
font-size: 2vmin;
}

.preview>:nth-child(2), .preview>:nth-child(4){
    margin-bottom: 1rem;
    border-radius: 10px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    padding: 1vmin;
    line-height: 4.5vmin;
    font-size: 1.8vmin;
}

/* .preview>:nth-last-child(2){
    height: 1px;
    background-color: #DFDFDF;
    margin-top: auto;
} */
.preview>:nth-last-child(1){
    margin-top: auto;
}

@media(max-width:800px)
{
    .preview{
        width: 88vw;
        padding: 0;
    }
    .preview>:nth-child(1), .preview>:nth-child(3){
        font-size: 3.5vmin;
        }
        
        .preview>:nth-child(2), .preview>:nth-child(4){
            padding: 2vmin;
            line-height: 8vmin;
            font-size: 3.5vmin;
        }
}
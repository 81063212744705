.media{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 1.5rem;
    overflow: hidden;
  }
  .media>*{
   object-fit:cover;
   box-sizing: border-box;
   width: 100%;
     height: 20vh; 
  }

  @media(max-width:700px)
  {
    .media{
        grid-template-columns: repeat(1,1fr);
      }
      .media>*{
         height: 20vh; 
      }

  }
  
.calender{
    display: flex;
    flex-direction: column;
    width: 96.8%;
    height: 95%;
    padding: 1rem;
    
}

* h3,h2,h1,h4,span{
    font-family: "poppins";
    margin: 0;
    padding: 0;
}

.calender header{
    display: flex;
    flex-direction: row;
    padding-bottom: 1rem;
    border-bottom: 1px solid #F1F1F1;
}

.calender header button{
background: #0FB3FF;
border-radius: 10px;
color: #ffffff;
font-size: 15px;
border: 0;
outline: none;
cursor: pointer;
padding: 0.3rem 1.5rem;
   
}
.overflowColors{
    /* display: grid; */
    /* grid-template-columns: repeat(2, 1fr); */
    display: flex;
    flex-direction: row;
    width: 100%;
}
.overflowColors::-webkit-scrollbar {
    width: 5px;
    height: 2px;
    border-radius: 5px;
    /* display: block; */
    display: none;
}
.overflowColors::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(253, 252, 252); 
}
.overflowColors::-webkit-scrollbar-thumb {
    background: rgb(160, 160, 160);
    height: 1px;
}
.calender header div{
    display: flex;
    flex: 1;
    justify-content: center;
}

.nameDiv{
    width:40rem;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    /* margin: 1.5rem 0; */
  
}

.nameDiv h4{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;
color: #162432;
}

.nameDiv>*:nth-child(3){
    flex: 1;
    max-width: 13rem;
}
.nameDiv>*:nth-child(3)>*{
    width: 100%;
}

.nameDiv>*:nth-child(4){
    flex: 1;
    max-width: 14rem;
}
.nameDiv>*:nth-child(4)>*{
    width: 100%;
}

.hours{
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.20); 
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* gap: 1.1rem; */
    padding: 0.6rem 0.7rem;
    overflow: auto;
    width: 100%;
    overflow-x: auto;
}
.hours>*:nth-child(1){
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
}
.hours>*:nth-child(2){
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
}
.hours>*:nth-child(3){
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
}
.hours>*:nth-child(4){
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
}
.hours>*:nth-child(5){
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
}
.hours>*:nth-child(6){
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
}
.hours>*:nth-child(7){
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
}
.hours>*:nth-child(1)>*{
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}
.hours>*:nth-child(2)>*{
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}.hours>*:nth-child(3)>*{
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.hours>*{
/* background: #FFFFFF; */
/* border-radius: 5px;
padding-left: 1.2rem;
padding-right: 1.2rem;
/* padding-top: 1rem; */
/* display: flex;
flex-direction: row;
gap: 1.2rem;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
width: 17rem; */ 
/* display: flex;
flex-direction: column;
padding: 0.5rem;
gap: 0.5rem;
border: 2px solid red;
}

.hours>*>*{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    gap: 0.6rem;
    font-weight: 550;
    /* border: 2px solid red; */
} 

@media screen and (max-width:700px) {
    .hours2{
        width: 100%;
        overflow-x: auto;
        background-color: #F9F9F9;
        display: flex;
        flex-direction: row;
        gap: 1.4rem;
        font-size: 18px;
        padding: 0.2rem;
    }
    .hours2::-webkit-scrollbar {
        display: none;
    }
    .hours2>*{
    background: #FFFFFF;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 57rem;
    }  
    .hours2>*>*{
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;
        gap: 0.6rem;
        font-weight: 550;
    }
    .hours2>*:nth-child(1){
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        justify-content: flex-start;
        padding: 0 0.2rem;
    }
    .hours2>*:nth-child(2){
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        justify-content: flex-start;
        padding: 0 0.2rem;
    }
    .hours2>*:nth-child(3){
        display: flex;
        flex-direction: row;
        gap: 0.1rem;
        justify-content: flex-start;
        padding: 0 0.2rem;
    }
    .hours2>*:nth-child(1)>*{
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        padding: 0.6rem;
        font-size: 16px;
    }
    .hours2>*:nth-child(1)>*:nth-child(1){
        width: 12rem;        
    }
    .hours2>*:nth-child(2)>*{
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        padding: 0.6rem;
        font-size: 16px;
    }
    .hours2>*:nth-child(2)>*:nth-child(1){
        width: 12rem;        
    }
    .hours2>*:nth-child(3)>*{
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        padding: 0.6rem;
        font-size: 16px;
    }
    .hours2>*:nth-child(3)>*:nth-child(1){
        width: 12rem;        
    }
    
}

.calenderPart{

    width: 50rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #FCFCFC;
    border-radius: 5px;
    margin: 1rem 2rem 0 0rem;
  
    height: 20rem;
    justify-content: flex-start;
}

.calenderPart>*:nth-child(1){
    margin: 0.5rem auto 0 auto;
    color: #0086FF;
    font-size: 15px;
}
.descriptionDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #EAEAEA;
    border-bottom: 1px solid #EAEAEA;
    padding: 0.5rem 0;
    margin-top: 0.5rem;
}

.desciption{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    font-size: 13px;
}

.weekDays{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: #0086FF;
    margin-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #EAEAEA;
    font-size: 13px;
}

.daySlot{
    display: grid;
    grid-template-columns: repeat(7,1fr);
    gap: 1rem;
    row-gap: 0.5rem;
    overflow-y: auto;
    max-height: 14rem;
    margin-top: 1rem;
}

.daySlot>*{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
}

.daySlot>*>*:nth-child(1){
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #B9C0CC;
    font-size: 13px;
    color: #ffffff;
    font-weight: 550;
}

.daySlot>*>*:nth-child(2){
   font-size: 12px;
   font-weight: 600;
}


.daySlot::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.daySlot::-webkit-scrollbar {
  height: 1em;
}
 
.daySlot::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
 
.daySlot::-webkit-scrollbar-thumb {
  background-color: rgb(92, 88, 88);
  outline: 1px solid rgb(13, 14, 15);
  border-radius: 10px;
  height:3rem;
} 




/* calender part css */


.present{
    color: #3DB065;
}
.absent{
    color: white;
    background-color: #F64A3F;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.leave{
    color: #FD8600;
}
.holiday{
    color: #C166FF;
}
.today{
    background-color: #0086FF;
    color: white;
}
.weekend{
    color: #D1D1D1;
}
.S{
    color: #ABABAB;
}

.M,
.T,
.W,
.F{
    color: #052846;
}
.half{
    color: rgb(118, 183, 237);
}
.manual{
    color: #0086FF;
}
.searchDiv{
    height: 2.1rem;
    width:19rem;
    gap:0.5rem;
    display: flex;
    border-radius: 5px;
    flex-direction: row;
    font-size: 16;
    background-color: white;
    align-items: center;
    border: 1.5px solid #e0e0e0;
    /* margin-bottom: 1rem; */
    /* margin-top: 1rem; */
    }
.searchDiv input{
      width:26rem;
      min-height: 30px;
      background: none;
      border: 0;
      outline: 0;
}
@media screen and (min-height:700px){
    .searchDiv{
        /* height: 2.5rem; */
        /* width:13.4rem; */
        gap:0.5rem;
        display: flex;
        border-radius: 5px;
        flex-direction: row;
        background-color: white;
        align-items: center;
        border: 1.5px solid #e0e0e0;
        }
    .searchDiv input{
          /* width:15rem; */
          /* height: 2.5rem; */
          background: none;
          border: 0;
          outline: 0;
          font-size: 17px;
          margin-left: 0.6rem;
    }
}
.searchDiv input:active,
.searchDiv input:focus{
     outline: none;
     border:none;
     background: none;
     border-radius: 0;
     box-shadow: none;
}
.searchDiv img{
      width:20px;
      height:20px;
      margin-left: 1rem;
      opacity: 0.6;
}
.ListContainer{
        width: 100%;
        overflow-x: auto;
        overflow-y: auto;        
        height: 69vh;
        box-sizing: border-box;
        margin-left: 0.8rem;
        padding-right: 0.8rem;
}
.ListContainer>*{
        width: 12rem;
}
.ListContainer::-webkit-scrollbar {
    height: 4px;
}
.ListHeader{
        width: 100%;
        display: flex;
        flex-direction: row;
        width: max-content;
        z-index: 2; 
        position: sticky;
        top: 0;
        padding: 1.6vh 0;
        background-color: #F9F9F9;
}
.ListHeader>*{
        display: flex;
}
.rowContainer{
        display: flex;
        width: max-content;
        justify-content: center;
        background-color: #ffffff;
        margin-bottom: 2vh;
        border-radius: 5px;
        height: 12vh;
        box-shadow: 0 2px 6px rgba(0, 0,0,0.2);
}
.rowContainer>*{
        display: flex;
        align-items: center;
}
.selectedRow {
    /* border: 1px solid #0086FF; */
    border-radius: 8px;
  }
.FixedColumn {
    width: max-content;
    position: sticky;
    left: 0;
    background-color:#F9F9F9;
    z-index: 1; 
}
.FixedColumns {
    width: max-content;
    position: sticky;
    left: 0;
    background-color:#ffffff;
    z-index: 1; 
}
.hoveredRow {
    background-color: #F9F9F9;
}
.highlightedRow {
    background-color: red;
}
.compOff{
    background: red;
    color: black;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
.container{
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    padding: 1rem;
}
.headerDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.headerDiv>:nth-child(1){
    display: flex;
    flex-direction: row;
    align-items: center;
   gap: 1rem;
   font-weight: 600;
/*     font-size: 1rem; */
}

h3,h4,h2,span{
    margin: 0;
    padding: 0;
}

.container h4{
    font-size: 15px;
    font-weight: 600;
}

.container span{
    font-size: 13px;
    font-weight: 500;
}



.headerDiv>:nth-child(1)>*:nth-child(1){
    color: black;

}

.headerDiv>:nth-child(2){
    display: flex;
    flex-direction: row;
    align-items: center;
   gap: 1rem;
   
}
*{
    font-family: "Poppins";
}
.headerDiv>:nth-child(2)>*{
    color:#0086FF;
    font-size: 14px;
    font-weight: 400;
    outline: none;
  
    border-radius: 10px;
    padding: 0.48rem 1.5rem;
    cursor: pointer;
    border:1.4px solid #0086FF;
    /* border: 2px solid red; */
}

.headerDiv>:nth-child(2)>:nth-child(1)
{
    background: transparent
}

.headerDiv>:nth-child(2)>:nth-child(1):hover{
    background: transparent
}

.headerDiv>:nth-child(2)>:nth-child(2)
{
    background: #0086FF;
    color: white;
    display: flex;
    align-items: center;
}

.headerDiv>:nth-child(2)>:nth-child(2):hover
{
    background: #0196ff;
}

.headerDiv>:nth-child(2)>:nth-child(2) img{
    margin-right: 0.3rem;
    width:0.8rem;
    height: 0.8rem;
}

.dateRange{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    font-weight: 500;
}

.dateRange>:nth-child(1)
{
    color: #ABABAB;
}

.teamDiv{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    border-radius: 10px;
    max-height: 83vh;
    overflow-y: auto;
}

.teamHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
}

.teamHeader>*{
    display: flex;
    flex-direction: row;
    gap:1rem;
    align-items: center;
}

.teamHeader>:nth-child(1)>:nth-child(1){
    color: #ABABAB;
}

.teamHeader>:nth-child(1)>:nth-child(3){
    align-items: center;
    display: flex;
}

.teamHeader>:nth-child(1)>:nth-child(3) img{
    width: 1rem;
    height: 1rem;
    cursor: pointer;
}

.teamHeader>:nth-child(2)>*
{
 
border-radius: 10px;
border: 0;
outline: none;
cursor: pointer;
padding: 0.5rem 1rem;
color: black;
display: flex;
align-items: center;
}

.teamHeader>:nth-child(2)>* img{
    margin-right: 0.4rem;
    width: 1rem;
    height: 1rem;
}

/* .teamHeader>:nth-child(2)>*:has(img):hover
{
    background: #3dc24b;
} */

.teamHeader>:nth-child(2)>:nth-last-child(1)
{
    color: #ffffff;
   
}

.teamHeader>:nth-child(2)>:nth-last-child(1):hover
{
   
}

.editOutlined{
    border: 0;
    background: none;
}

.editOutlined>*{
    border: 0;
    font-size: 1.2rem;
}

.editOutlined>*>*{
    border: 0;
}

.addTeamModal{
    display: flex;
    justify-content: center;
    flex: 1;
    flex-direction: column; 
    padding: 2rem 3rem;
}

.buttonDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    margin-top: 8rem;
}

.modalHeader{
    font-size: 1.2rem;
}

.release{
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    margin-top: 1rem;
}



.actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

 .actions>*{
    width: 1.8rem;
    height: 1.8rem;
    display: flex;
    justify-content: left;
    align-items: center;
    cursor: pointer;
}

.calenderNav{
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
}

.calenderNav, .actions>*>*{
    width: 100%;
    height: 100%;
}

.downloadHeader{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}
.downloadHeader h2{
    font-size: 1rem;
    margin: 0 auto;
    color: #21BA45;
}



.disabled{
    pointer-events: none;
    opacity: 0.4 !important;
}

.teamDiv>*{
    border:none;
}

.teamDiv>*>*>*>*:nth-child(2){
    padding:0.3rem 1rem ;
}

.teamDiv>*>*>*>*:nth-child(2)>*{
    color: #162432;
}

.teamDiv>*>*>*{
    border-bottom: 1px solid #F3F3F3;
}

.teamDiv>*>*>*>*:nth-child(1){
    margin-top: 0.5%;
}

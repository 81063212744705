.notification>*{
    height: 100vh;
    border-radius: 0;
    overflow-y: hidden;
    box-sizing: border-box;
}
.content{
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    gap: 2vmin;
    overflow-y: auto;
    height: 85vh;
    padding-right: 1vmin;
}
.eachNotification{
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 2vmin;
}
.eachNotification>:nth-child(1){
    width: 10%;
}
.eachNotification>:nth-child(2){
    width: 90%;
}
.eachNotification>:nth-child(1){
    display: flex;
}
.eachNotification>:nth-child(1) img{
width: 2rem;
height: 2rem;
}
.eachNotification>:nth-child(2)
{
    display: flex;
    flex-direction: column;
    gap: 1vmin;
}
.eachNotification>:nth-child(2)>:nth-child(1){
    font-size: 1rem;
    font-weight: 600;
}
.eachNotification>:nth-child(2)>:nth-child(2)
{
    font-size: 0.85rem;
    font-weight: 500;
    color: #3D3D3D;
}
.eachNotification>:nth-child(2)>:nth-child(3)
{
    font-size: 0.85rem;
    font-weight: 500;
    color: #808080;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.eachNotification>:nth-child(2)>:nth-child(3)>:nth-child(2){
    color: #1089FF;
    font-weight: 600;
    cursor: pointer;
}

.holiday{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 1rem 1rem 1rem;
}

*{
    box-sizing: border-box;
}

.header{
    display: flex;
    flex-direction: row;
    gap: 0rem;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.header h3{
    color:#0FB3FF;
}

.holiday>:nth-child(1)
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}
.holiday>:nth-child(1)>:nth-child(1){
color: #0086FF;
}
.holiday>:nth-child(1)>:nth-child(2)
{
    background-color: #0086FF;
    padding: 0.2rem 1rem;
    color: #fff;
}

.eachItem{
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    margin-bottom: 1rem;
}

.eachItem>:nth-child(1){
    display: flex;
    padding-top: 0.4rem;
    align-items: flex-start;

}

.eachItem>:nth-child(2)
{
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.eachItem>:nth-child(2)>:nth-child(1){
    font-weight: 700;
}

.eachItem>:nth-child(2)>:nth-child(2)
{
    font-size: 0.8rem;
}

.buttonDiv{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}
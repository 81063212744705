.navContainer{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    height:75.5vh;
    overflow-y: auto;
    gap: 1rem;
    width: 125vh;
   
    /* border:2px solid  */
}

*{
    box-sizing: border-box;
}
.hoverDiv{
    height: 18vh;
    padding-bottom: 1rem;
}
.hoverDiv:hover{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.container{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
}
.cardContainer{
    background-color: #f3f3f3;
    width: 75%;
}
.shareDiv{
    background-color: #fff;
    width: 25%;
}

@media(max-width:700px)
{
    .container{
        flex-direction: column;
    }
    .cardContainer
    {
        width: 100%;
    }
    .shareDiv{
        width: 100%;
    }
}


.ql-editor {
    /* border-top: -50px; */
    border: 0px;
    /* width: 100%; */
    /* margin-right: -30px; */
    /* box-sizing: border-box; */
    box-shadow: none;
    /* margin-top: -10px; */
    border: 0.1px solid;
    min-height: 10px;
    /* margin-top: 70px; */


}


.ql-toolbar.ql-snow {
    border: 0px solid #ccc;
    /* margin-top: -30px; */
    margin-left: -13px;
    margin-bottom: 15px;



}
/* Base Checkbox styling */
.ant-checkbox-inner {
    width: 16px;
    height: 16px;
    border-radius: 50%; /* Make it a circle */
    border: 2px solid #000; /* Dot border */
  }
  
  /* Checked state */
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff; /* Change background to blue when checked */
    border-color: #1890ff; /* Match the border to the background color */
  }
  
  .ant-checkbox-checked .ant-checkbox-inner::after {
    content: ''; /* Clear the default tick */
    display: block;
    width: 10px;
    height: 10px;
    background-color: #fff; /* White dot when checked */
    border-radius: 50%; /* Make it a dot */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  /* Custom dot for unchecked state */
  .ant-checkbox-inner::after {
    content: ''; /* Add a small dot */
    display: block;
    width: 4px;
    height: 4px;
    background-color: #000; /* Black dot when unchecked */
    border-radius: 50%; /* Make it a dot */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  
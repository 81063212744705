.profileDetails{
    display: flex;
    flex-direction: column;
    height: 100%;
    /* padding-right: 1rem; */
    /* padding-left: 1rem; */
}
.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #b4c0cd80;
    /* height: 3rem; */
   padding: 24px 20px;
}
.header h3{
    font-size: 1.2rem;
}
.header>*:nth-child(2){
 display: flex;
 flex-direction: row;
 align-items: center;
 gap: 1rem;
}
.header>*:nth-child(3){
    border-radius: 10px;
    border: 0;
    outline: 0;
    color: #ffff;
    padding: 0.5rem 1.5rem;
    font-size: 0.8rem;
}
.header>*:nth-child(3):hover{
    background: #0078FF;
    border: 2px solid #0078FF;
}

.content{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    height: 78vh;
}

.content>*:nth-child(2)>*:nth-child(3){
    margin-top: 2rem;
    max-height: 50vh;
    overflow-y: auto;
}

/* .content>:nth-child(1)
{
    width: 20%;
    border-left: 1px solid #F1F1F1;
    border-right: 1px solid #F1F1F1;
} */

/* .content>:nth-child(2)
{
    width: 80%;
} */

.tab{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.tab>*{
    padding: 0.5rem 1rem;
    cursor: pointer;
    color: #ABABAB;
    font-weight: 600;
    font-size: 0.95rem;
}



 

.profileCommon>:nth-child(1)>:nth-child(1){
    display: flex;
   width: 10rem;
   height: 10rem;
   border-radius: 10px;
   /* position: relative; */
}

.profileCommon>:nth-child(1)>:nth-child(1)>:nth-child(1){
   animation: profile 0.6s ease-in-out  normal forwards ;
   border-radius: 10px;
} 



.profileCommon>:nth-child(1)>:nth-child(2){
    display: flex;
    flex-direction: column;
   border-radius: 10px;
   gap: 0.8rem;
}

.profileCommon>:nth-child(1)>:nth-child(2)>:nth-child(1){
    font-size: 1.1rem;
    
  }



.profileCommon>:nth-child(1)>:nth-child(2)>:nth-child(2){
    font-weight: 550;
    color:#878787;
  }


  .profileCommon>:nth-child(1)>:nth-child(2)>:nth-last-child(2)>:nth-child(3){
    width: 1.2rem;
    height: 1.2rem;
  }

  .profileCommon>:nth-child(1)>:nth-child(2)>:nth-last-child(2)>:nth-child(2){
    font-weight: 550;
  }


.profileCommon>:nth-child(2)>:nth-child(1){
    font-weight: 600;
    font-size: 0.9rem;
}
.profileCommon>:nth-child(3)>:nth-child(1){
    font-weight: 600;
    font-size: 0.9rem;
}

.profileCommon>:nth-child(2)>:nth-child(2){
    margin: 0 0.5rem;
}

.profileCommon>:nth-child(2)>:nth-child(3){
    font-weight: 500;
    font-size: 0.7rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 0.5rem;
}
.profileCommon>:nth-child(3)>:nth-child(3){
    font-weight: 500;
    font-size: 0.7rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 0.5rem;
}

@media screen and (width>=700px) {
    .LastDateModal{
        width: 40vw;
        /* top: 25vh; */
    }
    .profileCommon{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 20px;
        
    }
    .profileCommon>:nth-child(1)>:nth-child(1){
        display: flex;
       width: 10rem;
       height: 10rem;
       border-radius: 10px;
       /* position: relative; */
    }
    .WebProgress{
        display:flex;
        justify-content :center ;
    } 
    .profileCommon>:nth-child(2){
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .profileCommon>:nth-child(3){
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .profileCommon>:nth-child(1)>:nth-child(2)>:nth-child(5){
        display: none;
    }
    .profileCommon>:nth-child(1)>:nth-child(2)>:nth-child(6){
        display: none;
    }
    .profileCommon>:nth-child(1){
        display: flex;
        flex-direction: row;
        gap: 2.5rem;
        align-items: center;
    }
}
@media screen and (width<700px) {
    .LastDateModal{
        width: 98vw;
        top: 15vh;
        left: 0;
    }
    .profileCommon{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 0px;
        
    }
    .profileCommon>:nth-child(1)>:nth-child(1){
        display: flex;
       width: 8.5rem;
       height: 8.5rem;
       border-radius: 10px;
       /* position: relative; */
    }
    .WebProgress{
        display: none;
    }
    .profileCommon>:nth-child(2){
        display: none;
    }
    .profileCommon>:nth-child(3){
        display: none;
       
    }
    .profileCommon>:nth-child(1)>:nth-child(2)>:nth-last-child(1){
        color: #162432;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        display: flex;
        gap: 8px;
      }
      .profileCommon>:nth-child(1)>:nth-child(2)>:nth-last-child(2){
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }
      .profileCommon>:nth-child(1){
        display: flex;
        flex-direction: row;
        width: 90%;
        gap: 10%;
        align-items: center;
    }
}
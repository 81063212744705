.container{
    padding:1rem;
    height: 100%;
    /* border: 2px solid red; */
}
.container>*:nth-child(1){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.container>*:nth-child(1) button{
    height: 2.3rem;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border-radius: 10px;
}
.cardsDiv{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.cardList {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; /* Adjust as needed */
    gap: 2vw;
    flex-wrap: nowrap;
    max-width: 90vw;
    overflow-x: scroll; /* Use "scroll" to enable scrolling */
    scrollbar-width: none; /* Hide the scrollbar on Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar on IE and Edge */
    margin: 1rem 0;
    /* padding: 0.3rem; */
}
.cardList::-webkit-scrollbar {
    width: 5px; /* Set the width of the scrollbar */
}
.cardList::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Change to the desired disabled color */
    display: none; /* Rounded corners for the thumb */
}

.cardList::-webkit-scrollbar-track {
    background-color: transparent; /* Transparent track */
}
::-webkit-scrollbar {
    width: 5px;
    height: 3px;
    display: none;
  }
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white; 
    border-radius: 10px;
  }
::-webkit-scrollbar-thumb {
    background:#FFFFFF;
    border-radius: 10px;
    height: 3px;
  }
.buttonsDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    cursor: pointer;
}
.buttonsDiv button{
    border: none;
    background: none;
}
.cardList button{
    border: 0;
}
.cards{
    display: flex;
    /* height: 10;
    width: 26vw; */
    border-radius: 10px;
    border: 1px solid #DFDFDF;

    /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */

}
.cards>*:nth-child(1){
    width: 14vw;
    border-radius: 10px;
    display: flex;
    flex-direction: row;

}
.cards>*:nth-child(1)>*:nth-child(1){
    width: 4.5vw;
    border-radius: 10px 5px 5px 10px;
    display: flex;
    align-items: center;
    justify-content:center;
    padding: 0.3rem;

}

.cards>*:nth-child(1)>*:nth-child(1) img{
   width: 2rem;
   height: 2rem;

}
.cards>*:nth-child(1)>*:nth-child(2){
    border-radius: 3px 10px 10px 5px;
 
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    
    padding: 1rem 0;
}
/* .cards>*:nth-child(1)>*:nth-child(2)>*:nth-child(1){
    margin-top: 1.3rem;
} */
.cards>*:nth-child(1)>*:nth-child(2)>*:nth-child(1)>*:nth-child(1){
    font-size: 1rem;
    font-weight: 590;
    color: #000000;
}
.cards>*:nth-child(1)>*:nth-child(2)>*:nth-child(1)>*:nth-child(2){
    font-size: 0.8rem;
    font-weight: 550;
}
.cards>*:nth-child(1)>*:nth-child(2)>*:nth-child(1)>*:nth-child(3){
    font-weight: 600;
}
.table{
    margin-top: 0.5rem;
}
.table h3{
    margin-bottom: 0.5rem;
}
.modalHeader{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 0.6rem;
    display: flex;
    justify-content: left;

}
.formValues{
    width: 70vw;
    padding:0 1rem 1rem 1rem;
    font-weight: 600;
}
.formValues input{
    height: 5rem;
}
.dateInput{
    width: 100%;
}
.buttonDesign{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    margin-top: 1rem;
}
.buttonDesign>*:nth-child(1){
    width: 7rem;
}
.buttonDesign>*:nth-child(2){
    background-color: white;
    width: 7rem;
}
.deleteDiv{
    display: flex;
    align-items: center;
}
.deleteDiv>*:nth-child(1){
    background-color: white;
    border: none;
}
.DeleteHeader{
    width: 100%;
    display: flex;
    flex-direction: row;
    color: #F96363;
    gap: 0.5rem;
}
.DeleteContainer{
 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.DeleteButtons{
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.DeleteButtons>*:nth-child(1){
    width: 6rem;
    font-size: 0.9rem;
}
.DeleteButtons>*:nth-child(2){
    background-color: white;
    width: 6rem;
    font-size: 0.9rem;
}

.tableRowWhite{
    background-color: #fff;
    color: #162432;
    font-weight: 700;
  }

  .tableRow{
    background-color: #F9F9F9;
    color: #162432;
    font-weight: 700;
  }

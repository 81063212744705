.release{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    height: 100%;
    width: 81vw;
    margin: auto;
}
*{
    margin: 0;
    padding: 0;
}
.release>:nth-child(1){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.release>:nth-child(1)>:nth-child(2){
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;
    width: 35%;
}

.release>:nth-child(1)>:nth-child(2)>:nth-child(1){
   font-size: 0.9rem;
   font-weight: 600;
   }

.release>:nth-child(1)>:nth-child(2)>:nth-child(2){
 flex-grow: 1;
}
/* .release>:nth-child(1)>:nth-child(2)>:nth-child(2)>*:nth-child(1){
    height: 2.4rem;
   }
   .release>:nth-child(1)>:nth-child(2)>:nth-child(2)>*:nth-child(2){
    height: 2.4rem;
    border: 2px solid red;
   } */

   .release>:nth-child(1)>:nth-child(2)>:nth-child(2)>*:nth-child(1) span{
  color: #545454;
  align-items: center;
  display: flex;
   }

   /* .release>:nth-child(2){
    display: flex;
    flex-direction: column;
    background-color: #F3F3F3;
    border-radius:10px;
    box-sizing: border-box;
    border: 2px solid red;
   } */

 /* .release>:nth-child(2)>:nth-child(1){
    margin: 1rem;
    color: grey;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.3px;

 } */

.div{
    width: 100%;
    flex-direction:column;
    display:flex;
    align-items:center;
    height:150px;
    margin-top: 1rem;
}
.div>:nth-child(1){
    font-size:16px;
    top:10
}
.div>:nth-child(2){
    margin: 3rem auto 5rem auto;
}

.associate{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}
.associate>:nth-child(1)
{
    /* border: 2px solid red; */
    border-radius: 50%;
}
.associate>*:nth-child(2){
    display: flex;
    flex-direction: column;
}

.tableRowWhite{
    background-color: #fff;
    color: #162432;
    font-weight: 500;
    font-size: 13px;
  }

  .tableRow{
    background-color: #F9F9F9;
    color: #162432;
    font-weight: 500;
    font-size: 13px;
  }


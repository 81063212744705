.custom-modal {
    background-color: #2D2D2D; /* Set your desired background color */
  }
  .tooltip-container .tooltip-text {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    bottom: 125%; /* Position above the div */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    
    /* Tooltip arrow */
    position: absolute;
    top: 100%; /* Below the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

  
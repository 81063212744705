.ceoMessage{
    display: flex;
    flex-direction: column;
    margin: 3vmin;
    overflow-y: auto;
    height: 75vh;
    padding-right: 6px;
    
}
.ceoMessage>:nth-child(1){
    font-size: 1rem;
    font-weight: 600;
    margin: 0 auto 2vmin auto;
}

.ceoMessage>:nth-child(2){
    font-size: 0.8rem;
    font-weight: 500;
}

.ceoProfile{
    display: flex;
    align-items: center;
    gap: 0.5vw;
    margin-top: 5vmin;
}
.ceoProfile>:nth-child(1){
    display: flex;
    width: 60px;
    height: 60px;
    
}

.ceoProfile>:nth-child(1) img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}
.ceoProfile>:nth-child(2){
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    font-weight: 600;
}
.ceoProfile>:nth-child(2)>:nth-child(2){
    font-size: 0.7rem;
    color: #808080;
}

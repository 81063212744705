@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,500;0,700;1,400&display=swap');

.titley{
    border: 1px solid rgb(32, 31, 31);
    transform: rotate(370deg);
    -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -o-transform: rotate(270deg);
    height: 200;
}

.rate-details-wrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.rate-details{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 0px 8px 3px rgba(173, 173, 173, 0.07);
}
.rate-details>*{
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    width: 100%;
}
.rate-details>*>*:nth-child(1){
    color: #18181880;
}
.rate-details>*>*:nth-child(2){
    word-break: break-word;
}

.searchDiv{
    height: 2.2rem;
    width:18rem;
    gap:0.3rem;
    display: flex;
    flex-direction: row;
    background-color: white;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
    }
    
    .searchDiv input{
      width:30rem;
      min-height: 30px;
      background: none;
      border: 0;
      outline: 0;
    }
    
    .searchDiv input:active,
    .searchDiv input:focus{
     outline: none;
     border:none;
     background: none;
     border-radius: 0;
     box-shadow: none;
    }
    
    .searchDiv img{
      width:20px;
      height:20px;
      margin-left: 1rem;
      opacity: 0.6;
    }
    .ListContainer{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      padding: 0rem 2rem;
    }
  
    .ListHeader{
      padding:  1rem 1rem;
      width: 100%;
  
    }
    .ListHeader>*{
  
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #16243280;
      font: normal 600 16px/22px lato;
    }
    .ListBodyContainer{
      width: 100%;
      height: 80%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .ListScroll{
     height: 63vh;
     overflow-y: scroll;
     width: 100%; 
    
    }
    .rowContainer{
      display: flex;
      width: 100%;
      justify-content: center;
      background-color: white;
      border-radius: 5px;
      padding: 1.5rem 1rem;
      height: 5rem;
      box-shadow: 0 2px 6px rgba(0, 0,0,0.2);
    }
    .rowContainer:hover{
      box-shadow: 0 8px 12px rgba(146, 153, 151, 0.2);
      transform: scale(0.99);
      transition: .6s ease-in-out;
    }
    .rowContainer>*{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #162432;
      font: normal 600 16px/22px lato;
    }

    /* .rowContainer>*:nth-child(1){
    padding-left: 0.5rem;
    } */
    
    .ListBodyContainer::-webkit-scrollbar{
      /* display: none; */
    }

    @media screen and (width>=700px) {
      .HeaderViewlist{
        display: flex;
        align-items: center;
        padding: 1rem 2rem;
        justify-content: space-between;
      }
      .projectModal{
        width: 60vw;
      }
      .MobViewList{
        display: none;
      }
    }
    @media screen  and (width<700px) {
      .HeaderViewlist{
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 1rem 1rem;
      }
      .projectModal{
        width: 95vw;
      }
      .WebViewList{
        display: none;
      }
      .MobViewList{
        background-color: #f8f8f8;
        display: flex;
        flex-direction: column;
       
      }
      .ListContainerMob{
        padding: 1rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        height: 100%;
      }
      .MobListBody{
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 0.5rem;
        padding: 1rem;
        border-radius: 10px;
        background-color: #fff;
      }
      .listLine{
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }
    }
    @media screen and (height>900px) {
      .ListScroll{
        max-height: 75vh;
        overflow-y: scroll;
        width: 100%; 
       }
       .ListScrollMob{
        max-height: 85vh;
        overflow-y: scroll;
        width: 100%; 
       }
    }
    @media screen and (height<900px) and (height>=750px) {
      .ListScroll{
        max-height: 70vh;
        overflow-y: scroll;
        width: 100%; 
       }
       .ListScrollMob{
        max-height: 80vh;
        overflow-y: scroll;
        width: 100%; 
       }
    }
    @media screen and (height<750px) and (height>=600px) {
      .ListScroll{
        max-height: 65vh;
        overflow-y: scroll;
        width: 100%; 
       }
       .ListScrollMob{
        max-height: 75vh;
        overflow-y: scroll;
        width: 100%; 
       }
    }
    @media screen and (height<600px) {
      .ListScroll{
        max-height: 60vh;
        overflow-y: scroll;
        width: 100%; 
       }
       .ListScrollMob{
        max-height: 70vh;
        overflow-y: scroll;
        width: 100%; 
       }
    }
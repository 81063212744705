.container{
    display: flex;
   align-items: center;
   gap: 0.5vw;
   border: 1px solid #CACACA;
   border-radius: 0.8vh;
   padding-left: 0.5vw;
   height: 2.3rem;
}
.container:active, .container:focus{
    border: 1px solid #0086ff;
}
.code{
    display: flex;
    align-items: center;
    gap: 0.3vw;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.9rem;
}

.dropdown>:nth-child(1){
    padding-left: 0.2vw;
}
.dropdown>:nth-child(1)>*{
  margin:1vh 0;
  width: 95%;
  height: 2.5rem;

}
.disabled{
    opacity: 1;
    background-color: #0000000a;
    pointer-events: none;
}

@media screen and (width>=700px) {
    .dropdown{
        display: flex;
        flex-direction: column;
        gap: 0.5vh;
        height: 15vw;
        overflow-y: auto;
        width: 20vw;  
    } 
}
@media screen and (width<700px) {
    .dropdown{
        display: flex;
        flex-direction: column;
        gap: 0.5vh;
        height: 20vh;
        overflow-y: auto;
        width: 55vw;  
    }
}

.header1{

    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: #162432;
    padding: 10px;
}

@media screen and (width>=700px) {
    .emptyDiv{
        width:150px;
    }
    .bodyConatiner{
        box-sizing: border-box;
        padding: 40px;
        background-color: #f8f8f8;
        width: 80vw;
    }
    .AD{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px;
    } 
    .AD >*{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5vw;    
    }
}
@media screen and (width<700px) {
    .emptyDiv{
        width: 40px;
    }
    .bodyConatiner{
        box-sizing: border-box;
        padding: 12px;
        background-color: #f8f8f8;
        width: 80vw;
    }
    .AD{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        gap: 16px;
    } 
    .AD >*{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5vw;  
        width: 100%;  
    }
}

.firstHeading{
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px #16243280;
  background-color: #fbfbfb;
}
.firstHeading:hover{
    transform: scale(0.99);
    transition:.7s ease all ;
  box-shadow: 0 4px 14px #73776d80;

}
.title{
    color: #16243280;
}
.detail{
    
}


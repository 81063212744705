
.cardContainer{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    /* position: relative; */
    /* height: 55vh; */
    padding: 16px 20px;
}
.headergrayH{
    color: #16243280;
    font-weight: 500;
    font-size: 16px;
    font-family: Lato;

}
.headerBlack{
    color: #162432;
    font-weight: 500;
    font-size: 16px;
    font-family: Lato;
}
.card{
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid rgba(221, 221, 221, 0.87);
    background: #FFF;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.2);
}
.card>:nth-child(1){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.card>:nth-child(1)>:nth-child(2){
    cursor: pointer;
    display: flex;
    align-items: center;
}

@media screen and (width>=700px) {
    .card>:nth-child(2){
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 3rem;
        padding: 10px 16px; 
    }
    .card>:nth-child(2)>*{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 50%;
    }
    .card>:nth-child(2)>*>*{
        display: flex;
        flex-direction: row;
        gap: 2rem;
        font-size: 15px;
    }
    
    .card>:nth-child(2)>*>*>*:nth-child(1){
        min-width: 6rem;
       color: #16243280;
    }  
}
@media screen and (width<700px) {
    .card>:nth-child(2){
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        padding: 10px 16px; 
    }
    .card>:nth-child(2)>*{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }
    .card>:nth-child(2)>*>*{
        display: flex;
        flex-direction: row;
        gap: 2rem;
        font-size: 15px;
    }
    
    .card>:nth-child(2)>*>*>*:nth-child(1){
        min-width: 6rem;
       color: #16243280;
    } 
}
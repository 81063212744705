.container{
    height: 100vh;
    /* width: 100%; */
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    background-color: #0086FF;
    flex-direction: column;
    /* border: 2px solid white; */
}
.container>*:nth-child(1){
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
}
.imageB{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 1rem;
}
.container>*:nth-child(2){
    width: 100%;
    margin-top: -5.5rem;
    margin-left: 5rem;
}
.contents{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    position: relative;
}
.contents>*:nth-child(1){
    width: 75vw;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    border: 2px solid white;
    height: 70vh;
    justify-content: space-between;
    border-radius: 10px;  
}
.contents>*:nth-child(1)>*:nth-child(1){
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
}
.contents2{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    height: 61vh;
    align-items: center;
    position: absolute;
    transform: rotate(6deg); 
}
.contents2>*:nth-child(1){
    width: 76vw;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    height: 65vh;
    margin-left: 2rem;
    justify-content: space-between;
    border-radius: 10px;  
    margin-top: 18rem; 
    background: rgba(255, 255, 255, 0.30);
    backdrop-filter: blur(100px);
}
@media screen and (max-width: 700px){
    .container>*:nth-child(2){
        width: 100%;
        margin-top: -4.5rem;
        margin-left: 2rem;
    }
    .contents{
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: row;
        position: relative;
    }
    .contents>*:nth-child(1){
        width: 85vw;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        border: 2px solid white;
        height: 78vh;
        justify-content: space-between;
        border-radius: 10px;  
    }
    .contents>*:nth-child(1)>*:nth-child(1){
        display: flex;
        flex-direction: column;
        justify-content: flex-start; 
    }
    .contents2{
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: row;
        height: 80vh;
        align-items: center;
        position: absolute;
        transform: rotate(6deg); 
    }
    .contents2>*:nth-child(1){
        width: 79vw;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: row;
        height: 76vh;
        margin-left: 2rem;
        justify-content: space-between;
        border-radius: 10px;  
        margin-top: 15rem; 
        background: rgba(255, 255, 255, 0.30);
        backdrop-filter: blur(100px);
    }
}
.overlapContent {
    top: 0;
    left: 0;
    z-index: 1; 
  }
.halfCircle{
    width: 50vh;
    margin-left: -5rem;
    margin-top: -13rem;
    height: 50vh;
    border: 1px solid #e3e3e3;
    border-radius: 50%;
    border-bottom-color: transparent;
    border-left-color: transparent;
    transform: rotate(90deg);
}
.halfCircleTwo{
    width: 50vh;
    margin-right: -3rem;
    margin-bottom: 40rem;
    margin-top: -10rem;
    height: 50vh;
    border: 1px solid #e3e3e3;
    border-radius: 50%;
    border-bottom-color: transparent;
    border-left-color: transparent;
    transform: rotate(270deg);
    
}

.image{
    /* height: 60vh; */
    /* width: 350/px; */
}
.cards{
    background-color:#E6F3FF;
    width: 480px;
    height: 15rem;
    border-radius: 25px;
    margin-right: 55px;
    margin-top: 1.5rem;
    margin-left: -1rem;
}
.cardsDesign{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 5rem;
}
.cardsDesign>*:nth-child(1) img{
    height: 75px;
    width: 55px;
}
.cardsDesign>*:nth-child(2){
    display: flex;
    flex-direction: column;
    margin-left: 1.2rem;
    height:9rem;
    gap: 0.1rem;
}
.cardsDesign>*:nth-child(2)>*:nth-child(1){
    font-size: 39px;
    color: #0086FF;
    font-weight: bold;
}
.cardsDesign>*:nth-child(2)>*:nth-child(2){
    font-size: 30px;
    color: #545454;
    font-weight: 650;
    margin-top: -0.7rem;
}
.formCard{
    width:"100%";
    margin-left: 1rem;
    border: 2px solid;
    /* height: 85%; */
    /* background-color: #F9F9F9; */
}
.formDiv{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: left; */
    text-align: left;
    height: 100%;
    margin-left: 2rem;
    margin-right: 2rem;
    /* margin: 2rem; */
    border: 2px solid red;

}
.topDesign{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}
.imagesTop{
    display: flex;
    justify-content: right;
    /* align-items: right; */
    margin-top: rem;
    margin-right: 4rem;
    width: 93%;
    /* border: 2px solid; */
    height: 20vh;
}
.imagesBottom{
    margin-left:7rem;
    /* border: 2px solid; */
    height: 20vh;
}
.RecImage1{
    /* height: 11rem;
    width: 11rem; */
    height: 100%;
}
.image1{
    height: 100%;
    /* width: 10rem; */
}
/* .formDiv{
    /* border: 2px solid  */
/* }  */
/* .formDiv h2{
    font-weight: 600; 
    font-size: 1.8rem; 
    margin-bottom: 2vh;
}
in-bottom: 1.7vh; */
/* } */
/* .formDiv span{
    font-weight: 600; 
    margin-bottom: 1vh; 
    cursor: pointer;
} */
.inputDesign{
    display: flex;
    flex-direction: row;
    height: 2.5rem;
    background-color: #ffffff;
    align-items: center;
    /* border-radius: 4px; */
    gap:0.3rem;
    border: 1px solid #D3D3D3;
}  
.inputDesign input{
    min-height: 2.1rem;
    border:0;
    font-weight: 600;
}
.inputDesign input:active,.inputDesign input:focus{
   outline: none;
   border:0;
   background: 0;
}
.inputDesign>*:nth-child(1){
    background-color: #EEEEEE;
    border-radius: 50px;
    display: flex;
    height: 2rem;
    width: 2rem;
    align-items: center;
    margin-left: 0.5rem;
    flex-direction: row;
    justify-content: center;
}
.inputDesign img{
    width:15px;
    height:15px;
}
.inputDesign>*:nth-child(3){
    width: 2rem;
    color:#ABABAB;
    font-size: 17px;
    border: 2px solid;
}
.inputDesign2{
    display: flex;
    flex-direction: row;
    border: 2px solid;
    min-height: 2.3rem;
    background-color: #ffffff;
    align-items: center;
    /* border-radius: 4px; */
    gap:0.3rem;
    margin-bottom: 0rem;
}  
.inputDesign2 input{
    height: 2.1rem;
    /* border:0; */
    font-weight: 600;
}
.inputDesign2 input:active,.inputDesign2 input:focus{
   outline: none;
   /* border:0/; */
}
.inputPassword{
    height: 2.1rem;
    /* border:0; */
    font-weight: 600;
}
.inputDesign2>*:nth-child(1){
    background-color: #EEEEEE;
    border-radius: 50px;
    display: flex;
    height: 2rem;
    width: 2rem;
    align-items: center;
    margin-left: 0.5rem;
    flex-direction: row;
    justify-content: center;
}
.inputDesign2>*:nth-child(3){
    width: 2rem;
    color:#ABABAB;
    font-size: 17px;
}
.loginButton{
    width: 8rem;
    height: 2.3rem;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
}
.resetPassword{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 0.2rem;
    margin-bottom: 0;
}
.resetPassword>*:nth-child(1){
    width: 12rem;
    height: 2.3rem;
    font-weight: 550;
    text-align: center; 
    border-radius: 10px;   
}
.resetPassword>*:nth-child(2){
    width: 10rem;
    height: 2.3rem;
    font-weight: 700;
    text-align: center;    
}
.formsDiv{
    margin-left: 0.5rem;
    margin-right: 2rem;
}
.formsDiv h2{
    font-weight: 640; 
    font-size: 1.3rem; 
    margin-bottom: 2vh;
}
.formsDiv p{
    font-weight: 500; 
    margin-bottom: 1.5rem; 
    font-size: 0.8rem;
}
.spanDesign{
    font-size: 23px;
    /* color: black; */
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    padding-right: 5rem;
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.formCardsForgot{
    /* border: 2px solid blue; */
    display: flex;
    justify-content: right;
    margin-top: 1.5rem;
    width: 90%;
}
.formCardsForgot>*:nth-child(1){
    height: 58px;
    width: 45px;
}
.checkEmail{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2.5rem;
}
.checkEmail>*:nth-child(1){
    width: 9rem;
    height: 2.3rem;
    font-weight: 550;
    border-radius: 8px;
}
.CheckModuleImage{
    display: flex;
    justify-content: right;
    align-items: right;
    margin-top: 3.5rem;
    margin-right: -3.7rem;
}
.passReset{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.5rem;
    /* border: 2px solid red; */
}
.passReset>*:nth-child(1){
    height: 2.2rem;
    font-weight: 550;
    width: 10rem;
    border-radius: 8px;
}
.setButton>*:nth-child(1){
    font-weight: 550;
}
.setPass{
    width: 11rem;
    height: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin: 0.5rem;
}

.scrollLoginPage::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    border-radius: 10px;
    display: block;
  }
.scrollLoginPage::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(253, 252, 252); 
  }
.scrollLoginPage::-webkit-scrollbar-thumb {
    background: rgb(160, 160, 160);
    height: 10px;
  }






  .login{
    display: flex;
    background: url('./../../assets/login_backgroud.png');
    background-size: cover;
    height: 100vh;
    box-sizing: border-box;
}
.login>*{
    flex: 1 1 50%;
}
.imageSection{
    display: flex;
    width: 50%;
    height: 100%;
    background-color: #fff;
    /* border-radius: 0px 0px 20px 0px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3vh;
}

/* .imageSection>:nth-child(1){
    width: 30vw;
    height: 12vh;
} */

.imageSection>:nth-child(1){
    width: 28rem;
    height: 28rem;
}
.integrityText
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5vh;
}

.integrityText>:nth-child(1){
    font-size: 1.1rem;
    font-weight: 600;
}


.loginWrapper{
    display: flex;
    align-items: center;
    justify-content: center;  
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.loginContainer{
    background-color: #fff;
    border-radius: 0.4rem;
    width: 27rem;
    height: 30rem;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
}



.page2{
    display: flex;
    flex-direction: column;
    margin: 2vw;
    width: 100%;
    
 }
 .page2>:nth-child(1){
    font-weight: 600;
    font-size: 1.1rem;
 }

 .page2>:nth-child(2){
   
   margin: 0.5vmin 0 2vmin 0;
   color: #191919;
   font-size: 0.85rem;
 }

 .page2button{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
 }

 .page2button button{
    background-color: #1089FF;
    color: #fff;
    height: 2.4rem;
    width: 17vw;
    align-self: center;
    outline: 0;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
 }

 .page2button button:disabled{
    opacity: 0.4;
    cursor:not-allowed;
 }

 .page2Form{
    display: flex;
    flex-direction: column;
    height: 100%;
 }

 .page2Form>:nth-last-child(1){
    display: flex;
    align-items:flex-end;
    margin-top: 0;
 }

 .page2Form>:nth-last-child(1)>*{
    margin-top: auto;
 }
 .page2Form>:nth-last-child(2){
    margin-bottom: 0;
 }



 .page2Form>*>*:nth-child(1)>:nth-child(1){
    padding: 0;
    margin-bottom: 0.6vmin;
 }

 .page2Form>*>*:nth-child(1)>:nth-child(1) span{
   font-size: 0.9rem;
 }

 .page2Form>*>*:nth-child(1)>:nth-child(2)>*>*>*{
    font-size: 0.9rem;
  }

  .passUpdated{
    margin: 2vmin;
    display: flex;
    height: 90%;
    flex-direction: column;
    align-items: center;
  }

  .passUpdated img{
    width: 20vw;
    height: 20vh;
    margin-top: 10vh;
  }

  .passUpdated>:nth-child(2){
    font-weight: 600;
    margin-bottom: 1vh;
    font-size: 2vmin;
  }

  .passUpdated>:nth-child(3){
    color: #191919;
   font-size: 1.7vmin;
  }

  .passUpdated button{
    background-color: #1089FF;
    color: #fff;
    height: 4.4vmin;
    width: 17vw;
    align-self: center;
    outline: 0;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    margin-top: auto;
    font-size: 1.8vmin;
  }


  
  .page3Form{
    display: flex;
    flex-direction: column;
    height: 44vmin;
 }

 .page3Form>:nth-last-child(1){
    display: flex;
   gap: 1rem;
    margin-top: 0;
 }

 .page3Form>:nth-last-child(1)>*:nth-child(1){
    margin-top: auto;
 }
 .page3Form>:nth-last-child(2){
    margin-bottom: 0;
 }



 .page3Form>*>*:nth-child(1)>:nth-child(1){
    padding: 0;
    margin-bottom: 0.6vmin;
 }

 .page3Form>*>*:nth-child(1)>:nth-child(1) span{
   font-size: 1.8vmin;
 }

 .page3Form>*>*:nth-child(1)>:nth-child(2)>*>*>*{
    font-size: 1.8vmin;
  }


  .page3button{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
 }

 .page3button button{
    background-color: #1089FF;
    color: #fff;
    height: 4.4vmin;
    width: 17vw;
    align-self: center;
    outline: 0;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
 }

 .page3button button:disabled{
    opacity: 0.4;
    cursor:not-allowed;
 }

 .page3button>:nth-child(1){
    font-size: 1.8vmin;
    display: flex;
    gap: 0.4vw;
    color: #808080;
    /* border: 2px solid red; */
 }

 .page3button>:nth-child(1)>:nth-child(2){
    color:#1089FF ;
    cursor: pointer;
 }

 .passReq{
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    gap: 0.3rem;
    font-size: 0.8rem;
    color: #808080;
    font-weight: 400;
  }
  .passReq>:nth-child(n+1){
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
  .passReq>:nth-child(n+1)>:nth-child(1){
    width: 1rem;
    height: 1rem;
  }
  .passReq>:nth-child(n+1)>:nth-child(2)>:nth-child(1){
    font-weight: 500;
    color: #000;
  }

 .backDiv{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 55%;
    margin-bottom: 2vmin;
    color: #fff;
 }

 .backDiv>:nth-child(1){
    /* border: 1.3px solid #fff; */
    padding: 0.3vmin;
    width: 3.6vmin;
    /* height: 3.6vmin;
    border-radius: 50%; */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
 }
 .backDiv>:nth-child(2){
    cursor: pointer;
 }

 .checkMail
 {
    display: flex;
    width: 100%;
    /* border: 2px solid red; */
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    justify-content: center;
 }

 @media (max-width:800px)
 {
    .login{
      flex-direction: column;
      background: none;
    }
    .login>*{
        flex: 1 1 100%;
    }
    .imageSection{
        width: 100%;
    }
    .imageSection>:nth-child(1){
        width: 15rem;
        height: 15rem;
    }
    .page2button button{
        width: 10rem;
     }
     .page2button{
      margin-bottom: 2rem;
     }
     /* .page2{
        border: 2px solid red;
        width: 80%;
        margin-left: 1rem;
     } */
     .loginContainer{
        background-color: none;
        width: 100%;
     }

 }

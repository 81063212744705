.associate{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: #f8f8f8;
}
.header{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
    margin-bottom: 1rem;
    color:#18181880;
    font-weight: 600;
    padding:0 1rem;
}

.mainContent{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 55vh;
    overflow-y: auto;
}

.eachRow{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #18181812;
    border-radius: 10px;
    padding: 1rem;
}
.eachRow>*
{
    font-weight: 500;
    font-size: 0.9rem;
}

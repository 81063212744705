.newsLetter{
    display: flex;
    width: 100%;
    height: 90vh;
}
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Lato';
}
.sider{
    width: 18%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 90vh;
    gap: 3rem;
    align-items: center;
    /* justify-content: space-between; */
    padding: 1.5rem 0;
    border-right: 1px solid #DFDFDF;
}

.sider>*:nth-child(1){
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    padding: 0.9rem 1rem;
    background-color: #DBF4FF;
    color: #0086FF;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    border-radius:10px ;
    width: 90%;
    border-right:5px solid #0086FF ;
}

.sider>*:nth-child(2){
  margin-top: auto;
}

.sider>*:nth-child(1) img{
    width: 2rem;
    height: 2rem;
}

.newsLetter>:nth-child(2){
    width: 82%;
    display: flex;
    flex-direction: column;
}

.newsLetter>:nth-child(2)>*:nth-child(2){
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 90vh;
    overflow-y: auto;
    padding: 1rem 2rem 1rem 1rem;
}

.eachNewsCard{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: #FFFFFF;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    position: relative;
    
}

/* .eachNewsCard:hover{
    animation: news-card-animate 0.5s linear  1 forwards ;
}

@keyframes news-card-animate {
    0%{
        transform: scale(1);
    }
    100%{
        transform: scale(1.02);
    }
} */



.eachNewsCard>*:nth-child(1){
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* .eachNewsCard>*:nth-child(1){
    display: flex;
  justify-content: right;
  border: 2px solid red;
}
.eachNewsCard>*:nth-child(1) img{
    width: 1.8rem;
    height: 1.8rem;
    cursor: pointer;
} */

.image{
    max-width: 15rem;
    min-width: 15rem;
    height: 13.35rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 0 0 10px;
    position: relative;
}


.image>:nth-child(1){
    position: absolute;
    top: 1rem;
    left: 1rem;
}
.image img{
    width: 100%;
    height: 100%;
    border-radius: 10px 0 0 10px;
}


.eachNewsCard>*:nth-child(1)>*:nth-child(2)
{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    width: 100%;
}


.eachNewsCard>*:nth-child(1)>*:nth-child(2)>*:nth-child(1){
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   color: #162432;
   font-size: 1.1rem;
   font-weight: 600;
   width: 100%;
}
.eachNewsCard>*:nth-child(1)>*:nth-child(2) span{
    font-size: 15px;
    font-family:'Lato';
}

.eachNewsCard>*:nth-child(1)>*:nth-child(2)>*:nth-child(3){
    font-size: 15px;
    font-family:'Lato';
    margin-bottom: 0;
}

.eachNewsCard>*:nth-child(1)>*:nth-child(2)>*:nth-child(2)>:nth-child(2){
    color: rgba(22, 36, 50, 0.50);
    margin-left: 0.3rem;
}
.eachNewsCard>*:nth-child(1)>*:nth-child(2)>*:nth-child(2){
    display: flex;
    flex-direction: row;
    align-items: center;
}

.continueButton span{
   color: #0086FF;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:0.4rem;
}

.header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem 1rem 1rem;
    border-bottom:1px solid #DFDFDF ;
    background-color: #fff;
}

.header h3{
font-size: 1.5rem;
font-weight: 600;
}

.header>*:nth-child(2){
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}
.search{
    display: flex;
    flex-direction: row;
    gap: 0rem;
    justify-content: center;
    align-items: center;
   height: 2.45rem;
   padding: 0 1rem;
    border-radius: 5px;
    border: 1px solid #BEBEBE;
    background: #FFF;
    max-width: 15rem;
}
.filter{
    width: 10rem;
}
.buttonDiv{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.buttonDiv>*, .mobPublish, .mobAdd{
    font-size: 1rem;
    width: 8rem;
    gap: 1rem;
    height: 2.45rem;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: 1.3px solid #0086FF;
    cursor: pointer;
    border-radius: 5px;
    color: #fff;
}
.mobAdd{
    font-size: 0.9rem;
    width: 8rem;
    gap: 1rem;
    height: 2.45rem;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: 1.3px solid #0086FF;
    cursor: pointer;
    border-radius: 5px;
    color: #fff;
}
.publish{
    background-color: #0086FF;
}
.cancel{
    color: #0086ff;
    background-color: #fff;
}
.delete{
    background: #FF6363;
    border: 1.3px solid #FF6363;
}
.addButton{
    color: #0086FF;
    background-color: #fff;
    font-weight: 600;
    gap: 0.3rem;
    width: 10rem;
}

.noData{
    display: flex;
    height: 30vh;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 0.9rem;
    color: #939393;
}

::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
    height: 8px;
    display: block;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(253, 252, 252); 
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(181, 180, 180);
    height: 10px;
    border-radius: 0;
  }

  .published{
  padding:0.5rem 1rem;
  border-radius: 4px;
    background: rgba(57, 202, 108, 0.15);
    color: #39CA6C;
    margin-left: 1rem;
  }
  .unPublished{
    padding:0.5rem 1rem;
    border-radius: 4px;
    background: rgba(255, 52, 52, 0.15);
    color: #FF3434;
    margin-left: 1rem;
    }

    @media(max-width:800px)
    {
        .header{
            flex-direction: column;
            padding:1rem 0;
            margin-bottom: 1rem;
        }
        .header>:nth-child(2){
            flex-direction: column;
            width: 100%;
            padding:0 1rem;
        }
        .header>:nth-child(2)>:nth-child(1){
            width: 100%;
            max-width: none;
            padding: 1rem;
            flex-grow: 1;
        }
        .header>:nth-child(2)>:nth-child(2){
            width: 100%;
            justify-content: space-between;
        }
        .eachNewsCard>*:nth-child(1){
            flex-direction: column;
        }

        .eachNewsCard{
            box-shadow: none;
            border-radius: 0;
            margin-bottom: 2rem;
            border-bottom: 1px solid #dfdfdf;
        }

        .newsLetter{
            background-color: #fff;
            height: 100%;
        }
        .newsLetter>*{
            height: 100%;
        }

        .sider{
            width: 100%;
            height: 80vh;
            border-right: 0;
        }

        .sider>*:nth-child(1){
            padding: 0rem 1rem;
            border-right:5px solid #0086FF ;
        }
        .header h3{
           margin-bottom: 1rem;
            }
          

            .image{
                min-width: 90%;
                width: 100%;
                height: 15rem;
                border-radius: 5px;
            }
            
            
            
            .image img{
                border-radius: 5px;
            }
            .filterDiv{
                display: flex;
                flex-direction: row;
                gap: 1rem;
                align-items: center;
            }
            .filterDiv>*{
                width: 45vw;
            }
            .filterDiv>*:nth-child(1){
                width: 40vw;
            }
            .mobButtonDiv{
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
            /* .search{
              max-width: 10rem;
              border: 2px solid red;
            } */
          

    }


.allevents{
    display: flex;
    flex-direction: column;
    height: 90vh;
    align-items: center;
    overflow-y: auto;
}

.container{
    width: 75%;
    height: min-content;
    margin: 4vmin 0 2vmin 0;
    display: flex;
    flex-direction: column;
}
.container>:nth-child(1){
    display: flex;
    align-items: center;
    gap: 1vw;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 2vmin;
    cursor: pointer;
}
.container>:nth-child(1) img{
    width: 1rem;
    height: 1rem;
}
.container >:nth-child(2){
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 0vmin;
}
.container >:nth-child(3){
    margin-top: 0.5rem;
    font-size: 0.85rem;
    font-weight: 500;
    margin-bottom: 2vmin;
    color: #3D3D3D;
}


.events{
    display: grid;
   grid-template-columns: repeat(3,1fr);
    gap: 2vmin;
}

.eachEvents{
    display: flex;
    flex-direction: column;
    gap: 1vmin;
    padding: 2vmin;
    background-color: #fff;
    border-radius: 0.5rem;
    cursor: pointer;
}

.eachEvents>:nth-child(1){
    display: flex;
    gap: 1vmin;
}
.eachEvents>:nth-child(1)>*{
    flex: 1 1 50%;
    height: 25vmin;
    display: flex;
}
.eachEvents>:nth-child(1)>:nth-child(1){
    display: flex;
}
.eachEvents>:nth-child(1)>:nth-child(1) img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.imageGroup{
    display: flex;
    flex-direction: column;
    gap: 1vmin;
    height: 25vmin;
}
.imageGroup>*{
    height: 50%;
}
.imageGroup>:nth-child(1){
    display: flex;
}
.imageGroup>:nth-child(1) img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.imageGroup>:nth-child(2){
    display: flex;
    gap: 1vw;
}

.imageGroup>:nth-child(2)>*{
    display: flex;
}
.imageGroup>:nth-child(2)>* img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.eachEvents>:nth-child(2){
  font-weight: 600;
  font-size: 0.9rem;
}
.eachEvents>:nth-child(3){
    font-weight: 500;
    font-size: 0.8rem;
    color: #3D3D3D;
  }

  @media(max-width:700px)
  {
    .container{
        width: 100%;
        margin: 2rem 0;
        padding: 1rem;
    }
    .events{
       grid-template-columns: repeat(1,1fr);
        gap: 1rem;
    }
    .imageGroup{
        gap: 1vmin;
        height: 30vh;
    }
    .eachEvents>:nth-child(1)>*{
        height: 30vh;
    }
  }
.div{
    display:flex;
    flex-direction:row;
    border-top:1px solid #F1F1F1;
    margin-top: 10px;
    height:150px;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
}

.select>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*:nth-child(1)>*:nth-child(1){
    border: 1.3px solid#ECECEC;
}

.select>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*:nth-child(1)>*:nth-child(1)>*>*{
    border: 0;
    box-shadow: none;
}

/* .tableStyle>*>*>*:nth-child(1){
    border: 2px solid red;
    height: 68vh;
    overflow-y: auto;
}

.ant-table-tbody{
    border: 2px solid red !important;
    height: 30rem !important;
  } */

  .tableStyle>*>*>*:nth-child(2){
    padding-right: 2rem;
  }

  .tableRowWhite{
    background-color: #fff;
    color: #162432;
    font-weight: 700;
  }

  .tableRow{
    background-color: #F9F9F9;
    color: #162432;
    font-weight: 700;
  }




.container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 2rem 2rem 2rem;
    
}

.wrapper{
    width: 70vw;
}

.container h3{
    font-size: 18px;
    font-weight: 400;
}
.modalHeader{
    display: flex;
    flex-direction: row;
    gap: 0rem;
    align-items: center;
    width: 100%;
    justify-content: center;
}
.modalHeader img{
    width: 1.9rem;
    height: 1.9rem;
}

.modalHeader>*:nth-child(2)
{
    color:black;
}

.buttonDiv{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 2rem;
    align-items: center;
    justify-content: center;
}

.form{
    margin-top: 1rem;
    width: 100%;
}

.datePicker{
    width: 100%;
    background: #FFFFFF;
border: 1.2px solid #E6E6E6;
border-radius: 10px;
}


.container{
    display: flex;
    width: 70%;
    margin: 3vmin auto;
    flex-direction: column;
}
.container>:nth-child(1){
    display: flex;
    align-items: center;
    gap: 1vw;
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 2vmin;
    cursor: pointer;
}
.container>:nth-child(1) img{
    width: 1rem;
    height: 1rem;
}

.container>:nth-child(2){
    font-size: 1.3rem;
    font-weight: 600;
    margin: 1vmin 0;
}
.videos{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2vmin;
    overflow-y: auto;
    height: 65vh;
    padding-right: 0.3vw;
    margin-bottom: 1rem;
}
.videos>*{
    height: 16rem;
    border-radius: 0.5rem;
    padding: 2vmin;
    background-color: #fff;
    cursor: pointer;
}
.videos>*>:nth-child(1){
    display: flex;
    height: 10rem;
    margin-bottom: 1vmin;
}
.videos>*>:nth-child(1) img{
    width: 100%;
    height: 100%;
    border-radius: 0.4rem;
}
.videos>*>:nth-child(2)
{
    font-weight: 600;
    font-size: 1rem;
    margin-top: 2vmin;
}
.videos>*>:nth-child(3)
{
    display: flex;
    align-items: center;
  gap: 1vw;
     margin-top: 1vmin; 
    font-size: 0.8rem;
    font-weight: 500;
    color: #808080;

}

@media(max-width:700px)
{
    .container{
        width: 100%;
        margin: 2rem 0;
        padding:0 1rem;
    }
    .videos{
        grid-template-columns: repeat(1,1fr);
        height: auto;
        gap: 1rem;
    }
}


